import * as React from 'react';

function NoAnimationSlide7(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <filter id="shadow-slide-7" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="6" floodOpacity="0.4" />
        </filter>
        <clipPath id="biodiversity_slide_7__clip-path">
          <rect
            className="biodiversity_slide_7__cls-1"
            x={858.42}
            y={488.38}
            width={10.82}
            height={16.98}
            rx={5.41}
            transform="rotate(-60.27 863.772 496.868)"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_7__clip-path-2">
          <rect
            className="biodiversity_slide_7__cls-1"
            x={839.49}
            y={480.65}
            width={10.82}
            height={10.82}
            rx={5.41}
            transform="rotate(-60.27 844.86 486.062)"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_7__clip-path-3">
          <circle className="biodiversity_slide_7__cls-1" cx={842.61} cy={487.1} r={1.12} />
        </clipPath>
        <clipPath id="biodiversity_slide_7__clip-path-4">
          <rect
            className="biodiversity_slide_7__cls-1"
            x={731.95}
            y={480.62}
            width={10.82}
            height={16.98}
            rx={5.41}
            transform="rotate(-60.27 737.317 489.112)"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_7__clip-path-5">
          <rect
            className="biodiversity_slide_7__cls-1"
            x={713.03}
            y={472.89}
            width={10.82}
            height={10.82}
            rx={5.41}
            transform="rotate(-60.27 718.396 478.301)"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_7__clip-path-6">
          <circle className="biodiversity_slide_7__cls-1" cx={715.19} cy={478.7} r={1.12} />
        </clipPath>
        <clipPath id="biodiversity_slide_7__clip-path-7">
          <rect
            className="biodiversity_slide_7__cls-1"
            x={1077.39}
            y={377.3}
            width={44.17}
            height={20.15}
            rx={10.08}
            transform="rotate(-8.55 1099.957 387.526)"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_7__clip-path-8">
          <path
            className="biodiversity_slide_7__cls-1"
            d="M1107.68 385.07a5.32 5.32 0 116.57 3.77 5.35 5.35 0 01-6.57-3.77"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_7__clip-path-9">
          <rect
            className="biodiversity_slide_7__cls-1"
            x={421.77}
            y={505.79}
            width={16.89}
            height={16.89}
            rx={8.45}
            transform="rotate(-24.5 439.459 516.86)"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_7__clip-path-10">
          <circle className="biodiversity_slide_7__cls-1" cx={429.9} cy={512.36} r={4.4} />
        </clipPath>
        <style>
          {
            '.biodiversity_slide_7__cls-1,.biodiversity_slide_7__cls-11,.biodiversity_slide_7__cls-5{fill:none}.biodiversity_slide_7__cls-4{fill:#e0d4c6}.biodiversity_slide_7__cls-11,.biodiversity_slide_7__cls-5{stroke:#3b2c00;stroke-linecap:round}.biodiversity_slide_7__cls-5{stroke-miterlimit:10;stroke-width:.94px}.biodiversity_slide_7__cls-6{fill:#3b2c00}.biodiversity_slide_7__cls-7{fill:#684d32}.biodiversity_slide_7__cls-9{fill:#f99c43}.biodiversity_slide_7__cls-11{stroke-linejoin:round;stroke-width:.35px}.biodiversity_slide_7__cls-12{fill:#f7f1ea}.biodiversity_slide_7__cls-14{fill:#fff}.biodiversity_slide_7__cls-15{fill:#333}.biodiversity_slide_7__cls-19{fill:#f7dd41}.biodiversity_slide_7__cls-20{fill:#eb826a}.biodiversity_slide_7__cls-23{fill:#414042}.biodiversity_slide_7__cls-27{fill:#e5a25e}.biodiversity_slide_7__cls-28{fill:#f1e1b0}.biodiversity_slide_7__cls-29{fill:#f7cb6b}.biodiversity_slide_7__cls-30{fill:#fbf292}.biodiversity_slide_7__cls-31{fill:#f4cb5e}.biodiversity_slide_7__cls-32{fill:#cf6d5a}.biodiversity_slide_7__cls-33{fill:#f4d851}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="biodiversity_slide_7__Layer_1" data-name="Layer 1">
          <g
            id="biodiversity_slide_7__FLOATS"
            filter="url(#shadow-slide-7)"
          >
            <g
              id="biodiversity_slide_7__bee_2-2"
              data-name="bee_2"
            >
              <g>
                <path
                  className="biodiversity_slide_7__cls-4 wing"
                  d="M853.54 487.23a14.21 14.21 0 0116.07-12.05.51.51 0 01.42.57 14.21 14.21 0 01-16.06 12 .5.5 0 01-.43-.52z"
                />
              </g>
              <path
                id="biodiversity_slide_7__leg"
                className="biodiversity_slide_7__cls-5"
                d="M851.16 490.29a17.23 17.23 0 00-4.32 1.7 12.9 12.9 0 00-3.17 3.42"
              />
              <path
                id="biodiversity_slide_7__leg-2"
                data-name="leg"
                className="biodiversity_slide_7__cls-5"
                d="M852.72 492a17.43 17.43 0 00-2.94 3.61 13.09 13.09 0 00-1.09 4.53"
              />
              <path
                id="biodiversity_slide_7__leg-3"
                data-name="leg"
                className="biodiversity_slide_7__cls-5"
                d="M855.25 500.7a17.54 17.54 0 00-.31-4.63 13 13 0 00-2.3-4.06"
              />
              <path
                className="biodiversity_slide_7__cls-6"
                d="M844.21 473.87l-.39 1a4.64 4.64 0 012.59 6.25l1 .45a5.73 5.73 0 00-3.2-7.7z"
              />
              <path
                className="biodiversity_slide_7__cls-6"
                d="M840.58 474.7l-.05 1.1a4.64 4.64 0 014.41 5.13l1.1.11a5.74 5.74 0 00-5.46-6.34z"
              />
              <rect
                className="biodiversity_slide_7__cls-7"
                x={858.42}
                y={488.38}
                width={10.82}
                height={16.98}
                rx={5.41}
                transform="rotate(-60.27 863.772 496.868)"
              />
              <g clipPath="url(#biodiversity_slide_7__clip-path)">
                <path
                  className="biodiversity_slide_7__cls-9"
                  d="M863.81 490.05a15.36 15.36 0 00-6.54 9.75l6 3.89a15.23 15.23 0 016.25-9.91z"
                />
              </g>
              <rect
                className="biodiversity_slide_7__cls-7"
                x={848.72}
                y={486.44}
                width={8.42}
                height={8.42}
                rx={4.21}
                transform="rotate(-60.27 852.874 490.645)"
              />
              <rect
                className="biodiversity_slide_7__cls-7"
                x={839.49}
                y={480.65}
                width={10.82}
                height={10.82}
                rx={5.41}
                transform="rotate(-60.27 844.86 486.062)"
              />
              <g clipPath="url(#biodiversity_slide_7__clip-path-2)">
                <path
                  className="biodiversity_slide_7__cls-11"
                  d="M844.5 489.78a1.32 1.32 0 01.37.74 1.35 1.35 0 01-.21.93"
                />
              </g>
              <g>
                <path
                  className="biodiversity_slide_7__cls-12 wing"
                  d="M854.81 487.36a15.93 15.93 0 0121.69-5.93.57.57 0 01.21.77 15.92 15.92 0 01-21.71 5.92.55.55 0 01-.19-.76z"
                />
              </g>
              <g
                clipPath="url(#biodiversity_slide_7__clip-path-3)"
                id="biodiversity_slide_7__Eye-9"
                data-name="Eye"
              >
                <circle
                  className="biodiversity_slide_7__cls-14"
                  cx={842.61}
                  cy={487.1}
                  r={1.12}
                />
                <circle
                  className="biodiversity_slide_7__cls-15"
                  cx={841.53}
                  cy={487.34}
                  r={1.12}
                />
              </g>
            </g>
            <g id="biodiversity_slide_7__micro_bird_2">
              <path
                className="biodiversity_slide_7__cls-9"
                d="M380.57 224l-3.72-12.9a3.6 3.6 0 00-4.5-2.51 3.74 3.74 0 00-2.46 4.62l3.72 12.9a3.62 3.62 0 004.51 2.51 3.73 3.73 0 002.45-4.62z"
              />
              <path
                className="biodiversity_slide_7__cls-19"
                d="M380.31 227.07l6.56-11.65a3.76 3.76 0 00-1.32-5.08 3.58 3.58 0 00-5 1.36L374 223.35a3.78 3.78 0 001.33 5.08 3.6 3.6 0 004.98-1.36z"
              />
              <path
                className="biodiversity_slide_7__cls-19"
                d="M371.39 229.43l9 .86a2.52 2.52 0 002.72-2.32 2.55 2.55 0 00-2.26-2.78l-9.05-.85a2.51 2.51 0 00-2.71 2.31 2.53 2.53 0 002.3 2.78z"
              />
            </g>
            <g id="biodiversity_slide_7__micro_bird_1">
              <path
                className="biodiversity_slide_7__cls-9"
                d="M328.86 193.43l-3.21-11.14a3.12 3.12 0 00-3.89-2.17 3.23 3.23 0 00-2.12 4l3.21 11.14a3.13 3.13 0 003.9 2.17 3.22 3.22 0 002.11-4z"
              />
              <path
                className="biodiversity_slide_7__cls-19"
                d="M328.64 196.08l5.67-10.08a3.25 3.25 0 00-1.15-4.38 3.09 3.09 0 00-4.28 1.17l-5.67 10.06a3.25 3.25 0 001.15 4.39 3.1 3.1 0 004.28-1.16z"
              />
              <path
                className="biodiversity_slide_7__cls-19"
                d="M320.93 198.12l7.81.74a2.18 2.18 0 002.35-2 2.19 2.19 0 00-1.95-2.4l-7.82-.74a2.17 2.17 0 00-2.34 2 2.19 2.19 0 001.95 2.4z"
              />
            </g>
            <g id="biodiversity_slide_7__bee-3" data-name="bee">
              <path
                className="biodiversity_slide_7__cls-20"
                d="M1106.6 401.66a1 1 0 00.77-1l.25-4.37c0-.75-.49-1.41-1.09-1.33a1 1 0 00-.77 1l-.24 4.37c-.05.75.48 1.4 1.08 1.33zM1103 402.12a1 1 0 00.77-1l.24-4.37c0-.75-.49-1.4-1.08-1.33a1 1 0 00-.77 1l-.25 4.37c-.02.75.51 1.4 1.09 1.33zM1094.72 403.19a1 1 0 00.77-1l.25-4.37c0-.75-.49-1.4-1.09-1.33a1 1 0 00-.76 1l-.25 4.37c-.05.75.49 1.4 1.08 1.33zM1091.14 403.65a1 1 0 00.77-1l.25-4.37c0-.75-.49-1.4-1.09-1.33a1 1 0 00-.77 1l-.25 4.37c-.05.75.49 1.41 1.09 1.33z"
              />
              <rect
                x={1077.39}
                y={377.3}
                width={44.17}
                height={20.15}
                rx={10.08}
                transform="rotate(-8.55 1099.957 387.526)"
                fill="#fbec6d"
              />
              <g clipPath="url(#biodiversity_slide_7__clip-path-7)">
                <path
                  className="biodiversity_slide_7__cls-23"
                  d="M1128.46 391.78c-4.42 5-11.84 4-14.81-.74a.78.78 0 00-.88-.39c-.28.12-.35.55-.11.93 3.34 5.43 11.64 6.54 16.57 1 .34-.38.4-.9.17-1.11-.23-.19-.65-.04-.94.31z"
                />
              </g>
              <g clipPath="url(#biodiversity_slide_7__clip-path-8)">
                <path
                  d="M1107.68 385.07a5.32 5.32 0 116.57 3.77 5.35 5.35 0 01-6.57-3.77"
                  fill="#f1f2f2"
                />
                <path
                  className="biodiversity_slide_7__cls-23"
                  d="M1112.8 383.64a5.32 5.32 0 116.57 3.76 5.36 5.36 0 01-6.57-3.76"
                />
              </g>
              <path
                className="biodiversity_slide_7__cls-9"
                transform="rotate(-8.55 1097.848 387.839)"
                d="M1090.03 377.62h14.65v20.15h-14.65z"
              />
              <path
                className="biodiversity_slide_7__cls-20"
                d="M1069.43 360.71l1.81-.27a20.76 20.76 0 0123.62 17.44l-1.81.27a20.75 20.75 0 01-23.62-17.44z"
              />
              <path
                d="M1109.41 352.84l.27 1.82a20.75 20.75 0 01-17.44 23.61l-.27-1.81a20.76 20.76 0 0117.44-23.62z"
                fill="#f5b1a6"
              />
            </g>
            <g
              id="biodiversity_slide_7__fly_2"
            >
              <g>
                <path
                  className="biodiversity_slide_7__cls-27"
                  d="M304.29 508.54l2.43 14.16 10.09-3.9-2.46-14.31a4.61 4.61 0 00-2.14-3.27 4.4 4.4 0 00-3.91-.18 7.08 7.08 0 00-4.01 7.5z"
                />
              </g>
              <g>
                <path
                  className="biodiversity_slide_7__cls-28"
                  d="M297.52 511.16l8.87 11.67 10.08-3.9-9-11.79a7.63 7.63 0 00-3.88-2.6 7.11 7.11 0 00-4.63.09c-2.72 1.11-3.32 4.06-1.44 6.53z"
                />
              </g>
              <path
                className="biodiversity_slide_7__cls-29"
                d="M304.87 526.4a3.69 3.69 0 004.83 2l7.84-3a3.92 3.92 0 002.13-1.94 3.42 3.42 0 00.13-2.75 3.69 3.69 0 00-4.83-2l-7.84 3a3.92 3.92 0 00-2.13 1.94 3.42 3.42 0 00-.13 2.75z"
              />
            </g>
            <g
              id="biodiversity_slide_7__fly_1"
            >
              <g>
                <path
                  className="biodiversity_slide_7__cls-27"
                  d="M286.09 545.24l2.44 14.15 10.08-3.9-2.46-14.3a4.56 4.56 0 00-2.14-3.27 4.39 4.39 0 00-3.91-.19 7.1 7.1 0 00-4.01 7.51z"
                />
              </g>
              <g>
                <path
                  className="biodiversity_slide_7__cls-28"
                  d="M279.33 547.86l8.86 11.66 10.09-3.9-9-11.79a7.64 7.64 0 00-3.89-2.59 7 7 0 00-4.63.09c-2.71 1.11-3.31 4.06-1.43 6.53z"
                />
              </g>
              <path
                className="biodiversity_slide_7__cls-29"
                d="M286.67 563.09a3.68 3.68 0 004.83 1.91l7.85-3a4 4 0 002.13-1.94 3.46 3.46 0 00.13-2.75 3.68 3.68 0 00-4.83-2l-7.84 3a4 4 0 00-2.14 2 3.43 3.43 0 00-.13 2.78z"
              />
            </g>
            <g
              id="biodiversity_slide_7__Bee-4"
              data-name="Bee"
            >
              <g>
                <path
                  className="biodiversity_slide_7__cls-4 wing"
                  d="M414.85 516.05A22.19 22.19 0 00423.7 486a.77.77 0 00-1.05-.31 22.17 22.17 0 00-8.85 30.07.77.77 0 001.05.29z"
                />
              </g>
              <rect
                className="biodiversity_slide_7__cls-32"
                x={415.67}
                y={526.77}
                width={10.09}
                height={2.11}
                rx={1.06}
                transform="rotate(-84.54 420.735 527.827)"
              />
              <rect
                className="biodiversity_slide_7__cls-32"
                x={412.4}
                y={526.46}
                width={10.09}
                height={2.11}
                rx={1.06}
                transform="rotate(-84.54 417.463 527.514)"
              />
              <rect
                className="biodiversity_slide_7__cls-32"
                x={409.36}
                y={526.17}
                width={10.09}
                height={2.11}
                rx={1.06}
                transform="rotate(-84.54 414.423 527.223)"
              />
              <path
                className="biodiversity_slide_7__cls-32"
                d="M438.06 497.88a.91.91 0 01-.7.94 7.76 7.76 0 00-6.24 7.72.9.9 0 01-.77.88.87.87 0 01-.94-.9 9.61 9.61 0 017.67-9.5.86.86 0 01.98.86z"
              />
              <path
                className="biodiversity_slide_7__cls-32"
                d="M442.76 500.91a.85.85 0 01-.93.67 7.45 7.45 0 00-8.18 5.35.85.85 0 01-1 .59.9.9 0 01-.63-1.17 9.25 9.25 0 0110.06-6.58.89.89 0 01.68 1.14z"
              />
              <rect
                className="biodiversity_slide_7__cls-33"
                x={386.16}
                y={520.23}
                width={26.51}
                height={16.89}
                rx={8.45}
                transform="rotate(-25.1 399.487 528.723)"
              />
              <rect
                className="biodiversity_slide_7__cls-33"
                x={410.59}
                y={513.79}
                width={13.14}
                height={13.14}
                rx={6.57}
                transform="rotate(-25.1 417.225 520.413)"
              />
              <g
                clipPath="url(#biodiversity_slide_7__clip-path-9)"
                id="biodiversity_slide_7__head-2"
                data-name="head"
              >
                <rect
                  className="biodiversity_slide_7__cls-33"
                  x={421.77}
                  y={505.79}
                  width={16.89}
                  height={16.89}
                  rx={8.45}
                  transform="rotate(-24.5 439.459 516.86)"
                />
                <path
                  d="M440.77 517.65a4.48 4.48 0 01-3.63 3.3c-2.9.33-4.52-3.12-4.52-3.12"
                  stroke="#cf6d5a"
                  strokeWidth={1.13}
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  fill="none"
                />
              </g>
              <g>
                <path
                  className="biodiversity_slide_7__cls-12 wing"
                  d="M414.65 515a24.84 24.84 0 00-33-11.94.88.88 0 00-.42 1.16 24.85 24.85 0 0033 11.94.88.88 0 00.42-1.16z"
                />
              </g>
              <path
                className="biodiversity_slide_7__cls-9"
                d="M389.88 523.08q5.07-2.15 10.52-4.2a30.33 30.33 0 005.78 15.38q-4.06 2.15-7.75 4.2a34.89 34.89 0 01-8.55-15.38z"
              />
              <g
                clipPath="url(#biodiversity_slide_7__clip-path-10)"
                id="biodiversity_slide_7__Eye-14"
                data-name="Eye"
              >
                <circle
                  className="biodiversity_slide_7__cls-14"
                  cx={429.9}
                  cy={512.36}
                  r={4.4}
                />
                <circle
                  className="biodiversity_slide_7__cls-15"
                  cx={434.02}
                  cy={513.73}
                  r={4.4}
                />
              </g>
            </g>
            <path
              className="biodiversity_slide_7__cls-14"
              d="M65.15 77.44A19.51 19.51 0 0182.91 58a19.52 19.52 0 0118.86-14.56h2.57a19.51 19.51 0 0118.84 14.49h5.59a19.53 19.53 0 0119.5 19.5v2a19.11 19.11 0 01-1 6 3.06 3.06 0 01-2.91 2.08H69a3 3 0 01-2.89-2.08 18.84 18.84 0 01-1-6v-1c0-.32.04-.65.04-.99z"
              id="biodiversity_slide_7__cloud_1"
            />
            <path
              className="biodiversity_slide_7__cls-14"
              d="M548.17 57a14 14 0 0112.7-13.9 14 14 0 0113.5-10.41h1.83A14 14 0 01589.68 43h4a14 14 0 0114 14v1.46a13.63 13.63 0 01-.69 4.33 2.19 2.19 0 01-2.08 1.49h-53.98a2.18 2.18 0 01-2.07-1.49 13.63 13.63 0 01-.69-4.33v-.73V57z"
              id="biodiversity_slide_7__cloud_2"
            />
            <path
              className="biodiversity_slide_7__cls-14"
              d="M1249.35 55.2a14 14 0 0112.71-13.9 14 14 0 0113.49-10.42h1.84a13.94 13.94 0 0113.47 10.37h4a14 14 0 0114 14v1.45a13.59 13.59 0 01-.69 4.33 2.16 2.16 0 01-2.07 1.49h-53.94A2.17 2.17 0 011250 61a13.59 13.59 0 01-.69-4.33v-.72c0-.24.04-.51.04-.75z"
              id="biodiversity_slide_7__cloud_3"
            />
            <g
              id="biodiversity_slide_7__butterfly_1"
            >
              <rect
                className="biodiversity_slide_7__cls-30"
                x={614.05}
                y={541.87}
                width={5.36}
                height={11.55}
                rx={2.68}
                transform="rotate(-.29 607.16 538.934)"
              />
              <g
                id="biodiversity_slide_7__left-wing"
              >
                <path
                  className="biodiversity_slide_7__cls-31"
                  d="M615.71 549.3H610a23.17 23.17 0 01-23.28-23.05v-7.77h4.88a24 24 0 0124.11 23.87zM597.23 566.69v-3.5A13.82 13.82 0 01611 549.3h4.74v3.5A13.82 13.82 0 01602 566.67z"
                />
              </g>
              <g
                id="biodiversity_slide_7__left-wing"
              >
                <path
                  className="biodiversity_slide_7__cls-31"
                  d="M617.47 549.24h5.71a23.18 23.18 0 0023.05-23.28v-7.77h-4.88a24 24 0 00-23.87 24.11zM636.11 566.48V563a13.81 13.81 0 00-13.89-13.74h-4.74v3.5a13.82 13.82 0 0013.89 13.75z"
                />
              </g>
            </g>
            <g
              id="biodiversity_slide_7__butterfly_2"
            >
              <rect
                className="biodiversity_slide_7__cls-30"
                x={614.05}
                y={541.87}
                width={5.36}
                height={11.55}
                rx={2.68}
                transform="rotate(-.29 607.16 538.934)"
              />
              <g
                id="biodiversity_slide_7__left-wing"
              >
                <path
                  className="biodiversity_slide_7__cls-31"
                  d="M615.71 549.3H610a23.17 23.17 0 01-23.28-23.05v-7.77h4.88a24 24 0 0124.11 23.87zM597.23 566.69v-3.5A13.82 13.82 0 01611 549.3h4.74v3.5A13.82 13.82 0 01602 566.67z"
                />
              </g>
              <g
                id="biodiversity_slide_7__left-wing"
              >
                <path
                  className="biodiversity_slide_7__cls-31"
                  d="M617.47 549.24h5.71a23.18 23.18 0 0023.05-23.28v-7.77h-4.88a24 24 0 00-23.87 24.11zM636.11 566.48V563a13.81 13.81 0 00-13.89-13.74h-4.74v3.5a13.82 13.82 0 0013.89 13.75z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      {/* <path
        style={{
          fill: 'none',
          stroke: '#f4cb5e',
        }}
        className="mpath"
        d="M1589.52,350.81c-68.77,91-134,118.3-181.64,125.94-83.53,
        13.4-130.66-30.61-230.53-26.16-88.16,3.92-138.91,42.6-197,
        70.8-167.61,81.3-314.61,44.46-477.49,46.67-151.65,2.05-369.1,
        38.66-643.29,198.68"
      /> */}
    </svg>
  );
}

export default NoAnimationSlide7;

import * as React from 'react';

function NoAnimationSlide2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <filter id="shadow-slide-2" colorInterpolationFilters="sRGB">
          <feDropShadow dx="2" dy="2" stdDeviation="3" floodOpacity="0.5" />
        </filter>
        <clipPath id="biodiversity_slide_2_clip-path">
          <path
            className="biodiversity_slide_2_cls-1"
            d="M358.53 617.37a5.72 5.72 0 014.24-6.73 5.47 5.47 0 016.25 4.7 5.7 5.7 0 01-4.23 6.73 5.48 5.48 0 01-6.26-4.7"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_2_clip-path-2">
          <path
            className="biodiversity_slide_2_cls-1"
            d="M370.57 631a5.71 5.71 0 014.24-6.73 5.47 5.47 0 016.25 4.7 5.69 5.69 0 01-4.23 6.72 5.48 5.48 0 01-6.26-4.69"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_2_clip-path-3">
          <rect
            className="biodiversity_slide_2_cls-1"
            x={400.6}
            y={238.86}
            width={27.85}
            height={27.85}
            rx={13.92}
            transform="rotate(-17.04 414.493 252.786)"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_2_clip-path-4">
          <circle className="biodiversity_slide_2_cls-2" cx={416.13} cy={249.5} r={7.78} />
        </clipPath>
        <style>
          {
            '.biodiversity_slide_2_cls-1{fill:none}.biodiversity_slide_2_cls-2{fill:#fff}.biodiversity_slide_2_cls-4{opacity:.5;mix-blend-mode:multiply}.biodiversity_slide_2_cls-6{fill:#4480b5}.biodiversity_slide_2_cls-8{fill:#98f4f4}.biodiversity_slide_2_cls-12{fill:#f1f2f2}.biodiversity_slide_2_cls-13{fill:#414042}.biodiversity_slide_2_cls-15{fill:#e9fcff}.biodiversity_slide_2_cls-16{fill:#c6baff}.biodiversity_slide_2_cls-17{fill:#eed7ff}.biodiversity_slide_2_cls-18{fill:#ff825c}.biodiversity_slide_2_cls-19{fill:#fff861}.biodiversity_slide_2_cls-20{fill:#c6edf5}.biodiversity_slide_2_cls-22{fill:#c43f82}.biodiversity_slide_2_cls-23{fill:#f4b5f4}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g
          filter="url(#shadow-slide-2)"
          id="biodiversity_slide_2_Layer_1"
          data-name="Layer 1"
        >
          <g
            id="biodiversity_slide_2_Bug-2"
            data-name="Bug"
          >
            <g className="bug-legs">
              <path
                className="biodiversity_slide_2_cls-6 front-left"
                d="M347 619.47l-.7-9.19a1.22 1.22 0 011.08-1.32 1.19 1.19 0 011.26 1.13l.69 9.19a1.2 1.2 0 01-1.07 1.32 1.18 1.18 0 01-1.26-1.13z"
              />
              <path
                className="biodiversity_slide_2_cls-6 front-right"
                d="M370.64 649.12l8.6 1.91a1.17 1.17 0 001.39-.95 1.22 1.22 0 00-.9-1.45l-8.61-1.92a1.18 1.18 0 00-1.39.95 1.23 1.23 0 00.91 1.46z"
              />
              <path
                className="biodiversity_slide_2_cls-6 middle-left"
                d="M341.5 625.89l-5.8-6.89a1.28 1.28 0 01.1-1.74 1.14 1.14 0 011.66.11l5.8 6.93a1.27 1.27 0 01-.11 1.74 1.14 1.14 0 01-1.65-.15z"
              />
              <path
                className="biodiversity_slide_2_cls-6 middle-right"
                d="M363.86 654l5.8 6.93a1.13 1.13 0 001.65.11 1.26 1.26 0 00.11-1.73l-5.8-6.93a1.14 1.14 0 00-1.66-.11 1.27 1.27 0 00-.1 1.73z"
              />
              <path
                className="biodiversity_slide_2_cls-6 back-left"
                d="M335.35 635.39l-8.61-1.87a1.21 1.21 0 01-.91-1.45 1.17 1.17 0 011.38-1l8.61 1.86a1.22 1.22 0 01.91 1.46 1.17 1.17 0 01-1.38 1z"
              />
              <path
                className="biodiversity_slide_2_cls-6 back-right"
                d="M354.52 658.74l.65 9.19a1.19 1.19 0 001.25 1.14 1.22 1.22 0 001.09-1.32l-.66-9.19a1.19 1.19 0 00-1.25-1.14 1.21 1.21 0 00-1.08 1.32z"
              />
            </g>
            <path
              d="M351 613l-14.3 13.22a21.54 21.54 0 00-6.83 14.46c0 .56-.05 1.12 0 1.68a21.69 21.69 0 004.83 13.55l.19.23c.17.21.36.42.54.63l.05.05a19.8 19.8 0 0012.52 6.42l1.67.1a19.52 19.52 0 0013.71-5.25l14.32-13.18z"
              fill="#58e0ed"
            />
            <path
              className="biodiversity_slide_2_cls-8"
              d="M355.66 618.58l-25.83 23.78v-1.68l25-23zM364.95 629.67l-29.51 27.15-.05-.05-.73-.86 29.51-27.17.78.93zM374.23 640.75l-24.55 22.6-1.67-.11 25.45-23.41.77.92z"
            />
            <path
              d="M378.91 615.59a19.59 19.59 0 00-28.55-1.94l26.69 31.87a21.93 21.93 0 001.86-29.93z"
              fill="#6ec2e8"
            />
            <path
              d="M373.56 616.4c2.84-1.76 5.89 1.89 3.87 4.61"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={0.6}
              fill="none"
            />
            <path
              className="biodiversity_slide_2_cls-8"
              d="M350.58 613.45l26.69 31.86-.88.82-26.69-31.87.88-.81z"
            />
            <g id="biodiversity_slide_2_eye-9" data-name="eye">
              <g clipPath="url(#biodiversity_slide_2_clip-path)">
                <path
                  className="biodiversity_slide_2_cls-12"
                  d="M358.53 617.37a5.72 5.72 0 014.24-6.73 5.49 5.49 0 016.26 4.7 5.71 5.71 0 01-4.24 6.73 5.48 5.48 0 01-6.26-4.7"
                />
                <path
                  className="biodiversity_slide_2_cls-13"
                  d="M363.66 617.36a5.69 5.69 0 014.24-6.73 5.48 5.48 0 016.25 4.7 5.71 5.71 0 01-4.24 6.73 5.47 5.47 0 01-6.25-4.7"
                />
              </g>
            </g>
            <g id="biodiversity_slide_2_eye-10" data-name="eye">
              <g clipPath="url(#biodiversity_slide_2_clip-path-2)">
                <path
                  className="biodiversity_slide_2_cls-12"
                  d="M370.57 631a5.71 5.71 0 014.24-6.73 5.47 5.47 0 016.25 4.7 5.69 5.69 0 01-4.23 6.72 5.48 5.48 0 01-6.26-4.69"
                />
                <path
                  className="biodiversity_slide_2_cls-13"
                  d="M375.7 631a5.7 5.7 0 014.24-6.73 5.48 5.48 0 016.25 4.7 5.7 5.7 0 01-4.24 6.73 5.47 5.47 0 01-6.25-4.7"
                />
              </g>
            </g>
          </g>
          <g id="biodiversity_slide_2_FLOAT">
            <g
              id="biodiversity_slide_2_cloud"
            >
              <path
                className="biodiversity_slide_2_cls-15"
                d="M910.83,217.2a20.73,20.73,0,0,1,18.89-20.65,20.74,20.74,0,0,1,20-15.49h2.73a20.73,20.73,0,0,1,20,15.41h5.94A20.76,20.76,0,0,1,999.2,217.2c0,.36,0,.72,0,1.07s0,.72,0,1.09a20.39,20.39,0,0,1-1,6.44,3.24,3.24,0,0,1-3.08,2.21H914.94a3.24,3.24,0,0,1-3.08-2.21,20.39,20.39,0,0,1-1-6.44c0-.36,0-.72,0-1.07S910.83,217.57,910.83,217.2Z"
              />
            </g>
            <g
              id="biodiversity_slide_2_cloud-2"
              data-name="cloud"
            >
              <path
                className="biodiversity_slide_2_cls-15"
                d="M276.85,193.36a20.75,20.75,0,0,1,18.88-20.66,20.75,20.75,0,0,1,20.06-15.48h2.72a20.74,20.74,0,0,1,20,15.41h5.94a20.76,20.76,0,0,1,20.74,20.73c0,.36,0,.71,0,1.06s0,.73,0,1.1a20.39,20.39,0,0,1-1,6.44,3.24,3.24,0,0,1-3.08,2.21H281a3.24,3.24,0,0,1-3.07-2.21,20.11,20.11,0,0,1-1-6.44q0-.54,0-1.08C276.86,194.09,276.85,193.72,276.85,193.36Z"
              />
            </g>
            <g
              id="biodiversity_slide_2_cloud-2"
              data-name="cloud"
            >
              <path
                className="biodiversity_slide_2_cls-15"
                d="M792.71,514.14a20.73,20.73,0,0,1,18.88-20.65,20.73,20.73,0,0,1,20-15.48h2.73a20.73,20.73,0,0,1,20,15.4h5.94a20.75,20.75,0,0,1,20.73,20.73c0,.36,0,.72,0,1.07s0,.73,0,1.1a20.32,20.32,0,0,1-1,6.43A3.26,3.26,0,0,1,877,525H796.81a3.24,3.24,0,0,1-3.08-2.22,20.32,20.32,0,0,1-1-6.43c0-.37,0-.72,0-1.08S792.71,514.51,792.71,514.14Z"
              />
            </g>
            <g className="insect">
              <g>
                <rect
                  className="biodiversity_slide_2_cls-16"
                  x={93.24}
                  y={239.79}
                  width={8.06}
                  height={22.64}
                  rx={2.52}
                  transform="rotate(-16.45 97.262 251.083)"
                />
              </g>
              <g>
                <rect
                  className="biodiversity_slide_2_cls-17"
                  x={91.74}
                  y={247.92}
                  width={22.64}
                  height={8.06}
                  rx={2.52}
                  transform="matrix(.5 -.87 .87 .5 -166.66 215.23)"
                />
              </g>
              <rect
                className="biodiversity_slide_2_cls-16"
                x={95.51}
                y={252.73}
                width={5.54}
                height={15.63}
                rx={1.73}
                transform="rotate(-84.74 98.287 260.553)"
              />
            </g>
            <g className="insect">
              {/* <animateMotion
                dur="3s"
                repeatCount="indefinite"
                path="M60 60a56.61 56.61 0 11-16.58-40 56.47 56.47 0 0116.58 40z"
              /> */}
              <g>
                <rect
                  className="biodiversity_slide_2_cls-16"
                  x={143.97}
                  y={206.65}
                  width={8.06}
                  height={22.64}
                  rx={2.52}
                  transform="rotate(-16.45 147.994 217.947)"
                />
              </g>
              <g>
                <rect
                  className="biodiversity_slide_2_cls-17"
                  x={142.48}
                  y={214.78}
                  width={22.64}
                  height={8.06}
                  rx={2.52}
                  transform="rotate(-60 153.798 218.814)"
                />
              </g>
              <rect
                className="biodiversity_slide_2_cls-16"
                x={146.24}
                y={219.59}
                width={5.54}
                height={15.63}
                rx={1.73}
                transform="rotate(-84.74 149.014 227.411)"
              />
            </g>
            <g
              id="biodiversity_slide_2_cloud-3"
              data-name="cloud"
            >
              <path
                className="biodiversity_slide_2_cls-15"
                d="M290.94,505.47a20.73,20.73,0,0,1,18.88-20.65,20.74,20.74,0,0,1,20.06-15.48h2.72a20.73,20.73,0,0,1,20,15.4h5.94a20.76,20.76,0,0,1,20.74,20.73c0,.36,0,.72,0,1.07s0,.73,0,1.1a20.33,20.33,0,0,1-1,6.43,3.24,3.24,0,0,1-3.09,2.22H295a3.26,3.26,0,0,1-3.08-2.22,20.32,20.32,0,0,1-1-6.43c0-.37,0-.72,0-1.08S290.94,505.84,290.94,505.47Z"
              />
            </g>
            <g className="insect">
              {/* <animateMotion
                dur="5s"
                repeatCount="indefinite"
                path="M60 60a56.61 56.61 0 11-16.58-40 56.47 56.47 0 0116.58 40z"
              /> */}
              <g>
                <rect
                  className="biodiversity_slide_2_cls-18"
                  x={1093.24}
                  y={441.45}
                  width={10.15}
                  height={28.51}
                  rx={5.07}
                  transform="rotate(-16.45 1098.138 455.648)"
                />
              </g>
              <g>
                <rect
                  className="biodiversity_slide_2_cls-19"
                  x={1091.35}
                  y={451.7}
                  width={28.51}
                  height={10.15}
                  rx={5.07}
                  transform="rotate(-60 1105.609 456.77)"
                />
              </g>
              <rect
                className="biodiversity_slide_2_cls-18"
                x={1096.09}
                y={457.75}
                width={6.98}
                height={19.68}
                rx={3.49}
                transform="rotate(-84.74 1099.612 467.584)"
              />
            </g>
            <g className="insect">
              {/* <animateMotion
                dur="3s"
                repeatCount="indefinite"
                path="M60 60a56.61 56.61 0 11-16.58-40 56.47 56.47 0 0116.58 40z"
              /> */}
              <g>
                <rect
                  className="biodiversity_slide_2_cls-18"
                  x={1126.6}
                  y={378.29}
                  width={10.15}
                  height={28.51}
                  rx={5.07}
                  transform="rotate(-16.45 1131.522 392.53)"
                />
              </g>
              <g>
                <rect
                  className="biodiversity_slide_2_cls-19"
                  x={1124.72}
                  y={388.53}
                  width={28.51}
                  height={10.15}
                  rx={5.07}
                  transform="rotate(-60 1138.969 393.608)"
                />
              </g>
              <rect
                className="biodiversity_slide_2_cls-18"
                x={1129.45}
                y={394.59}
                width={6.98}
                height={19.68}
                rx={3.49}
                transform="rotate(-84.74 1132.98 404.416)"
              />
            </g>
            <g className="insect">
              <g>
                <rect
                  className="biodiversity_slide_2_cls-18"
                  x={496.94}
                  y={193.21}
                  width={10.15}
                  height={28.51}
                  rx={5.07}
                  transform="rotate(-16.45 501.94 207.451)"
                />
              </g>
              <g>
                <rect
                  className="biodiversity_slide_2_cls-19"
                  x={495.06}
                  y={203.45}
                  width={28.51}
                  height={10.15}
                  rx={5.07}
                  transform="rotate(-60 509.313 208.523)"
                />
              </g>
              <rect
                className="biodiversity_slide_2_cls-18"
                x={499.79}
                y={209.51}
                width={6.98}
                height={19.68}
                rx={3.49}
                transform="rotate(-84.74 503.297 219.34)"
              />
            </g>
          </g>
          <g>
            <path
              className="biodiversity_slide_2_cls-20 windmill"
              d="M847.12,280.71h0a3.57,3.57,0,0,1,.76-4.82l30-23.3a3.16,3.16,0,0,1,4.59.67h0a3.58,3.58,0,0,1-.75,4.83l-30,23.3A3.17,3.17,0,0,1,847.12,280.71Z"
            />
            <path
              className="biodiversity_slide_2_cls-20 windmill"
              d="M884.07,251.89h0a3.59,3.59,0,0,1-3.44-3.47l-1-38a3.18,3.18,0,0,1,3.27-3.3h0a3.59,3.59,0,0,1,3.44,3.47l1,38A3.17,3.17,0,0,1,884.07,251.89Z"
            />
            <path
              className="biodiversity_slide_2_cls-20 windmill"
              d="M921.08,280.71h0a3.58,3.58,0,0,0-.75-4.82l-30-23.3a3.16,3.16,0,0,0-4.59.67h0a3.58,3.58,0,0,0,.75,4.83l30,23.3A3.17,3.17,0,0,0,921.08,280.71Z"
            />
            <ellipse
              className="biodiversity_slide_2_cls-20"
              cx={883.51}
              cy={253.67}
              rx={3.79}
              ry={3.98}
            />
          </g>
          <g
            id="biodiversity_slide_2_Bee-2"
            data-name="Bee"
          >
            <path
              className="wing"
              d="M389 252.18a36.57 36.57 0 0021.4-47 1.28 1.28 0 00-1.65-.75 36.58 36.58 0 00-21.41 47 1.28 1.28 0 001.66.75z"
              fill="#d4e8e1"
            />
            <rect
              className="biodiversity_slide_2_cls-22"
              x={387.55}
              y={271.02}
              width={16.63}
              height={3.48}
              rx={1.74}
              transform="rotate(-76.48 395.845 272.762)"
            />
            <rect
              className="biodiversity_slide_2_cls-22"
              x={382.28}
              y={269.75}
              width={16.63}
              height={3.48}
              rx={1.74}
              transform="matrix(.23 -.97 .97 .23 35.3 587.78)"
            />
            <rect
              className="biodiversity_slide_2_cls-22"
              x={377.39}
              y={268.58}
              width={16.63}
              height={3.48}
              rx={1.74}
              transform="rotate(-76.48 385.697 270.318)"
            />
            <path
              className="biodiversity_slide_2_cls-22"
              d="M423.36 224a1.4 1.4 0 01-1 1.55 11.93 11.93 0 00-9 12.77 1.38 1.38 0 01-1.12 1.48 1.41 1.41 0 01-1.69-1.24 14.78 14.78 0 0111.11-15.77 1.42 1.42 0 011.7 1.21z"
            />
            <path
              className="biodiversity_slide_2_cls-22"
              d="M431.54 228.15a1.38 1.38 0 01-1.45 1.15 11.94 11.94 0 00-12.54 9.32 1.38 1.38 0 01-1.52 1.06 1.41 1.41 0 01-1.22-1.71 14.79 14.79 0 0115.49-11.51 1.41 1.41 0 011.24 1.69z"
            />
            <rect
              className="biodiversity_slide_2_cls-23"
              x={339.05}
              y={255.29}
              width={43.7}
              height={27.85}
              rx={13.92}
              transform="rotate(-17.04 360.883 269.188)"
            />
            <rect
              className="biodiversity_slide_2_cls-23"
              x={380.95}
              y={248.92}
              width={21.66}
              height={21.66}
              rx={10.83}
              transform="rotate(-17.04 391.746 259.752)"
            />
            <g
              clipPath="url(#biodiversity_slide_2_clip-path-3)"
              id="biodiversity_slide_2_head-5"
              data-name="head"
            >
              <rect
                className="biodiversity_slide_2_cls-23"
                x={400.6}
                y={238.86}
                width={27.85}
                height={27.85}
                rx={13.92}
                transform="rotate(-17.04 414.493 252.786)"
              />
              <path
                className="biodiversity_slide_2_cls-22"
                d="M424.66 266.27h-.41c-5.43-.15-7.44-6.53-7.52-6.8a.93.93 0 01.61-1.16.94.94 0 011.17.62c0 .05 1.7 5.37 5.79 5.48s5.73-3.81 5.8-4a.93.93 0 011.73.69c-.09.24-2.07 5.06-7.17 5.17z"
              />
            </g>
            <path
              className="wing"
              d="M388.93 250.43a41 41 0 00-51.12-27.14 1.44 1.44 0 00-.95 1.8A41 41 0 00388 252.23a1.45 1.45 0 00.93-1.8z"
              fill="#eefff7"
            />
            <path
              d="M347.17 258.22q8.64-2.42 17.6-4.75c-.95 8.49 1.2 18 6.46 26.74L357 285c-6.36-8.53-9.65-18-9.83-26.78z"
              fill="#fdd7ff"
            />
            <circle className="biodiversity_slide_2_cls-2" cx={416.13} cy={249.5} r={7.78} />
            <g clipPath="url(#biodiversity_slide_2_clip-path-4)">
              <circle cx={421} cy={250.94} r={7.78} id="biodiversity_slide_2_eyes" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default NoAnimationSlide2;

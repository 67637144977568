import * as React from 'react';
import { motion } from 'framer-motion';

function SvgComponent(props) {
  return (
    <svg
      className="eboti"
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <filter id="shadow-eboti-12" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="6" floodOpacity="0.4" />
        </filter>
        <clipPath id="prefix__clip-path">
          <path
            className="prefix__cls-1"
            d="M117.91 135.59v14.09c0 11.13-7.91 20.16-17.72 20.17-9.8 0-17.76-9-17.78-20.12v-14.09c0-9.72 6.92-17.61 15.48-17.62h4.49c8.62-.02 15.52 7.85 15.53 17.57z"
          />
        </clipPath>
        <clipPath id="prefix__clip-path-2">
          <path
            d="M158.86 99.31l-.89-1a80.45 80.45 0 00-26.52-20.63 71.38 71.38 0 00-60.79-.53 80.57 80.57 0 00-26.91 20.13l-.91 1a2.26 2.26 0 000 2.86l.89 1a80.45 80.45 0 0026.52 20.63 71.38 71.38 0 0060.79.53 80.43 80.43 0 0026.91-20.17l.91-1a2.24 2.24 0 00.53-1.42 2.27 2.27 0 00-.53-1.4z"
            fill="none"
          />
        </clipPath>
        <style>
          {
            '.prefix__cls-1{fill:#2bb673}.prefix__cls-8{fill:none}.prefix__cls-4{fill:#199b69}.prefix__cls-8{stroke:#006838;stroke-linecap:round;stroke-linejoin:round;stroke-width:4.97px}.prefix__cls-9{fill:#fff}'
          }
        </style>
      </defs>
      <g
        filter="url(#shadow-eboti-12)"
      >
        <path
          className="prefix__cls-1"
          d="M117.91 135.59v14.09c0 11.13-7.91 20.16-17.72 20.17-9.8 0-17.76-9-17.78-20.12v-14.09c0-9.72 6.92-17.61 15.48-17.62h4.49c8.62-.02 15.52 7.85 15.53 17.57z"
        />
        <g clipPath="url(#prefix__clip-path)">
          <path
            className="prefix__cls-4"
            d="M117.61 130.58l1.3 10.94a75.48 75.48 0 01-38 4.33l-1.33-10.75c-1.24-10.41 5.19-19.74 14.37-20.83l4.8-.57c9.18-1.1 17.62 6.46 18.86 16.88z"
          />
        </g>
        <motion.path
          animate={{
            rotate: [7, -7, 7, -7, 7, -7, 7],
          }}
          transition={{
            delay: 1, duration: 2, repeat: Infinity, repeatDelay: 6,
          }}
          style={{
            transformBox: 'fill-box',
            originY: 'bottom',
            originX: 'center',
          }}
          className="prefix__cls-4 hair"
          d="M120.41 48.47A21.41 21.41 0 0199 69.88a21.41 21.41 0 0121.41-21.41z"
          transform="rotate(9.39 109.667 59.15)"
        />
        <motion.path
          animate={{
            rotate: [-7, 7, -7, 7, -7, 7, -7],
          }}
          transition={{
            delay: 1, duration: 2, repeat: Infinity, repeatDelay: 6,
          }}
          style={{
            transformBox: 'fill-box',
            originY: 'bottom',
            originX: 'center',
          }}
          className="prefix__cls-1 hair"
          d="M71.34 32.66a33.57 33.57 0 0133.57 33.57 33.57 33.57 0 01-33.57-33.57z"
          transform="rotate(-170.61 88.13 49.447)"
        />
        <path
          id="prefix__hrad"
          className="prefix__cls-1"
          d="M170 97.05l-7-7.2a88.62 88.62 0 00-125.73-1.16l-7.18 7.07a5.79 5.79 0 00-.07 8.16l7 7.21a88.11 88.11 0 0062.6 26.7 89.06 89.06 0 0017.38-1.52 88.08 88.08 0 0045.73-24l7.18-7.07a5.81 5.81 0 00.09-8.19z"
        />
        <g clipPath="url(#prefix__clip-path-2)">
          <path
            className="prefix__cls-4"
            d="M158.86 99.31l-.89-1a80.45 80.45 0 00-26.52-20.63 71.38 71.38 0 00-60.79-.53 80.57 80.57 0 00-26.91 20.13l-.91 1a2.26 2.26 0 000 2.86l.89 1a80.45 80.45 0 0026.52 20.63 71.38 71.38 0 0060.79.53 80.43 80.43 0 0026.91-20.17l.91-1a2.24 2.24 0 00.53-1.42 2.27 2.27 0 00-.53-1.4z"
          />
        </g>
        <path
          d="M157.62 100.78l-.87-1a78.62 78.62 0 00-26-19.9 70.81 70.81 0 00-59.51-.55 78.67 78.67 0 00-26.32 19.41l-.89 1a2.14 2.14 0 000 2.75l.87 1a78.62 78.62 0 0026 19.9 70.81 70.81 0 0059.51.55 78.67 78.67 0 0026.34-19.41l.89-1a2.07 2.07 0 00.51-1.37 2 2 0 00-.53-1.38z"
          fill="#beeda2"
        />
        <path
          d="M108.22 116.43a7.66 7.66 0 11-15.3 0c0-4.42 3.32-2.37 7.55-2.37s7.75-2.06 7.75 2.37z"
          fill="#006838"
        />
        <motion.g
          animate={{
            scale: [1, 0, 1],
          }}
          transition={{
            duration: 0.4, repeat: Infinity, repeatDelay: 5,
          }}
        >
          <path className="prefix__cls-8" d="M75.89 93.52l.19 12.55" />
          <circle className="prefix__cls-9" cx={75.18} cy={93.56} r={1.31} />
        </motion.g>
        <motion.g
          animate={{
            scale: [1, 0, 1],
          }}
          transition={{
            duration: 0.4, repeat: Infinity, repeatDelay: 5,
          }}
        >
          <path className="prefix__cls-8" d="M119.13 93.12l.19 12.55" />
          <circle className="prefix__cls-9" cx={118.43} cy={93.16} r={1.31} />
        </motion.g>
      </g>
      <motion.g
        filter="url(#shadow-eboti-12)"
        className="hand"
        animate={{
          x: [0, -10, -10, 0],
          y: [0, 10, 10, 0],
          rotate: [0, 170, 170, 0],
        }}
        transition={{
          duration: 4, times: [0, 0.2, 0.8, 1], repeat: Infinity, repeatDelay: 4,
        }}
        style={{
          transformBox: 'fill-box',
          originX: 'center',
          originY: 'top',
        }}
      >
        <path
          className="prefix__cls-1"
          d="M125.94 139.4a24.12 24.12 0 0124.12 24.12 24.12 24.12 0 01-24.12-24.12z"
          transform="rotate(10.98 137.99 151.438)"
        />
        <path
          className="prefix__cls-1"
          d="M119.17 140.8a16.93 16.93 0 0116.93 16.93 16.93 16.93 0 01-16.93-16.93z"
          transform="rotate(48.94 127.637 149.268)"
        />
      </motion.g>
      <motion.g
        filter="url(#shadow-eboti-12)"
        className="hand"
        animate={{
          x: [0, 10, 10, 0],
          y: [0, 10, 10, 0],
          rotate: [0, -170, -170, 0],
        }}
        transition={{
          duration: 4, times: [0, 0.2, 0.8, 1], repeat: Infinity, repeatDelay: 4,
        }}
        style={{
          transformBox: 'fill-box',
          originX: 'center',
          originY: 'top',
        }}
      >
        <path
          className="prefix__cls-1"
          d="M74.45 139.4a24.12 24.12 0 01-24.12 24.12 24.12 24.12 0 0124.12-24.12z"
          transform="rotate(169.02 62.392 151.463)"
        />
        <path
          className="prefix__cls-1"
          d="M81.22 140.8a16.93 16.93 0 01-16.93 16.93 16.93 16.93 0 0116.93-16.93z"
          transform="rotate(131.06 72.756 149.271)"
        />
      </motion.g>
    </svg>
  );
}

export default SvgComponent;

import * as React from 'react';
import { motion } from 'framer-motion';

function SvgComponent(props) {
  return (
    <svg
      className="eboti"
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 200"
      {...props}
    >
      <defs>
        <clipPath id="prefix__clip-path">
          <path
            className="prefix__cls-1"
            d="M214.92 135.47v14.16c0 11.19-8 20.27-17.82 20.29-9.85 0-17.86-9-17.87-20.24v-14.16c0-9.78 7-17.71 15.56-17.72h4.51c8.61-.02 15.6 7.89 15.62 17.67z"
          />
        </clipPath>
        <clipPath id="prefix__clip-path-2">
          <path
            d="M253.53 112l-.71-1.22a83.07 83.07 0 00-23.16-25.83A73.68 73.68 0 00168 73.24a83.22 83.22 0 00-31 15.54l-1.11.88a2.31 2.31 0 00-.56 2.89l.72 1.22a82.7 82.7 0 0023.15 25.83 73.64 73.64 0 0061.65 11.71 83 83 0 0031-15.55l1.12-.87a2.27 2.27 0 00.79-1.35 2.23 2.23 0 00-.23-1.54z"
            fill="none"
          />
        </clipPath>
        <style>
          {
            '.prefix__cls-1{fill:#2bb673}.prefix__cls-11{fill:none}.prefix__cls-4{fill:#199b69}.prefix__cls-8{fill:#3994bc}.prefix__cls-11{stroke:#006838;stroke-linecap:round;stroke-linejoin:round;stroke-width:4.97px}'
          }
        </style>
      </defs>
      <path
        className="prefix__cls-1"
        d="M214.92 135.47v14.16c0 11.19-8 20.27-17.82 20.29-9.85 0-17.86-9-17.87-20.24v-14.16c0-9.78 7-17.71 15.56-17.72h4.51c8.61-.02 15.6 7.89 15.62 17.67z"
      />
      <g clipPath="url(#prefix__clip-path)">
        <path
          className="prefix__cls-4"
          d="M214.61 130.42l1.31 11a75.83 75.83 0 01-38.25 4.36L176.38 135c-1.25-10.47 5.22-19.85 14.44-20.94l4.83-.58c9.23-1.13 17.72 6.52 18.96 16.94z"
        />
      </g>
      <motion.g
        className="head"
        animate={{
          rotate: [0, 15, 0],
        }}
        transition={{
          duration: 4, repeat: Infinity, repeatDelay: 5,
        }}
        style={{
          originX: 0.8,
          originY: 0.2,

        }}
      >
        <path
          className="prefix__cls-4"
          d="M219.88 49h.58v.58a21.53 21.53 0 01-21.53 21.53h-.58v-.58A21.53 21.53 0 01219.88 49z"
          transform="rotate(9.39 209.4 60.03)"
        />
        <path
          className="prefix__cls-1"
          d="M169.81 32.68h.9a33.76 33.76 0 0133.76 33.76v.9h-.9a33.76 33.76 0 01-33.76-33.76v-.9z"
          transform="rotate(-170.61 187.138 50.009)"
        />
        <path
          id="prefix__hrad"
          className="prefix__cls-1"
          d="M265.24 111.74l-5.83-8.61A91.49 91.49 0 00132 78.87l-8.59 5.86A6 6 0 00121.8 93l5.83 8.61a91 91 0 0058.69 38.63 92 92 0 0017.9 1.64 91 91 0 0050.86-16l8.59-5.87a6 6 0 001.57-8.27z"
        />
        <g clipPath="url(#prefix__clip-path-2)">
          <path
            className="prefix__cls-4"
            d="M253.53 112l-.71-1.22a83.07 83.07 0 00-23.16-25.83A73.68 73.68 0 00168 73.24a83.22 83.22 0 00-31 15.54l-1.11.88a2.31 2.31 0 00-.56 2.89l.72 1.22a82.7 82.7 0 0023.15 25.83 73.64 73.64 0 0061.65 11.71 83 83 0 0031-15.55l1.12-.87a2.27 2.27 0 00.79-1.35 2.23 2.23 0 00-.23-1.54z"
          />
        </g>
        <path
          d="M252 113.26l-.7-1.18a81.26 81.26 0 00-22.74-25 73.13 73.13 0 00-60.35-11.48 81.18 81.18 0 00-30.32 14.89l-1.08.84a2.19 2.19 0 00-.53 2.79l.7 1.18a81.09 81.09 0 0022.74 25 73.13 73.13 0 0060.35 11.49 81.23 81.23 0 0030.32-14.88l1.08-.84a2.17 2.17 0 00.78-1.3 2.13 2.13 0 00-.25-1.51z"
          fill="#beeda2"
        />
        <path
          d="M200 122.27a69.13 69.13 0 00-11.55-1.78 38.09 38.09 0 00-10.92 1.62 1.17 1.17 0 01-1.08-1c-.61-3.31-1.7-13.52 8.35-13.38 9.28.12 14.32 8.78 16.12 12.69a1.25 1.25 0 01-.92 1.85z"
          fill="#006838"
        />
        <g>
          <path
            className="prefix__cls-11"
            d="M168.38 90.62l-2.13 12.37"
          />
          <motion.path
            animate={{
              scale: [1, 0.5, 1],
            }}
            style={{
              originX: 1,
              originY: 1,
            }}
            transition={{ duration: 4, repeat: Infinity, repeatDelay: 5 }}
            className="prefix__cls-11"
            d="M214.84 95.74l-2.13 12.37"
          />
        </g>
      </motion.g>
      <path
        className="prefix__cls-1"
        d="M219.89 144.92h.65A24.12 24.12 0 01244.65 169v.65H244a24.12 24.12 0 01-24.12-24.12v-.65l.01.04z"
        transform="rotate(180 232.265 157.3)"
      />
      <motion.g
        animate={{
          x: [0, 7, 0],
          y: [0, -20, 0],
          rotate: [0, 30, 0],
        }}
        transition={{
          duration: 4, repeat: Infinity, repeatDelay: 5,
        }}
        style={{
          transformBox: 'fill-box',
          originX: 'right',
          originY: 'center',
        }}
      >
        <path
          id="prefix__HAND_L"
          data-name="HAND L"
          className="prefix__cls-1"
          d="M118.72 114.51A28.55 28.55 0 0095.79 79a28.59 28.59 0 0022.91 35.51z"
        />
        <g id="prefix__LOOKING_GLASS_GRP" data-name="LOOKING GLASS GRP">
          <g id="prefix__kikkert">
            <path
              className="prefix__cls-8"
              d="M111.56 83.37l-63.94-1.61v26.31l63.94-1.61V83.37z"
            />
            <path
              className="prefix__cls-8"
              d="M144.71 87.58l-57.67-.99v16.65l57.67-1V87.58zM60.8 94.91A13.54 13.54 0 1047.61 108 13.34 13.34 0 0060.8 94.91z"
            />
            <path
              className="prefix__cls-8"
              d="M121.75 94.91c0-6.22-4.51-11.37-10.19-11.51-5.83-.15-10.66 5-10.66 11.51s4.83 11.67 10.66 11.52c5.68-.14 10.19-5.3 10.19-11.52zM150.78 94.91c0-4-2.7-7.27-6.07-7.33s-6.24 3.23-6.24 7.33 2.82 7.39 6.24 7.33 6.07-3.34 6.07-7.33z"
            />
          </g>
          <path
            d="M58.93 94.91A11.47 11.47 0 1047.72 106a11.3 11.3 0 0011.21-11.09z"
            fill="#a5dff1"
          />
          <path
            d="M47.72 86.19c4.18.06 8.41 2.94 8.41 8.32a1.1 1.1 0 11-2.19 0c0-4.16-3.14-6.08-6.25-6.12a1.1 1.1 0 01-1.08-1.12 1.08 1.08 0 011.11-1.08z"
            fill="#fafdfa"
          />
        </g>
        <path
          id="prefix__HAND_L-2"
          data-name="HAND L"
          className="prefix__cls-1"
          d="M118.72 114.52a16.43 16.43 0 003.47-24.07 16.45 16.45 0 00-3.48 24.07z"
        />
      </motion.g>
    </svg>
  );
}

export default SvgComponent;

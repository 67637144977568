import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import {
  Container, Row,
} from 'react-bootstrap';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import './wpBiodiversityPost.scss';
import { GlobalDispatchContext } from '../../context/GlobalContextProvider';
import { ThemeDispatchContext } from '../../context/ThemeContextProvider';

import SummaryContent from '../../components/Chapters/SummaryContent/SummaryContent';
import ChapterCarouselContent from '../../components/Chapters/ChapterCarouselContent/ChapterCarouselContent';

import Eboti from './components/Eboti/Eboti';

import LastSlideImage from './components/ImgComponents/LastSlideImage';

import BgSlide1 from './components/ImgComponents/BgSlide1';
import BgSlide2 from './components/ImgComponents/BgSlide2';
import BgSlide3 from './components/ImgComponents/BgSlide3';
import BgSlide4 from './components/ImgComponents/BgSlide4';
import BgSlide5 from './components/ImgComponents/BgSlide5';
import BgSlide6 from './components/ImgComponents/BgSlide6';
import BgSlide7 from './components/ImgComponents/BgSlide7';
import BgSlide8 from './components/ImgComponents/BgSlide8';

import AnimationSlide2 from './components/AnimationSlide2/AnimationSlide2';
import AnimationSlide3 from './components/AnimationSlide3/AnimationSlide3';
import AnimationSlide1 from './components/AnimationSlide1/AnimationSlide1';
import AnimationSlide4 from './components/AnimationSlide4/AnimationSlide4';
import AnimationSlide5 from './components/AnimationSlide5/AnimationSlide5';
import AnimationSlide6 from './components/AnimationSlide6/AnimationSlide6';
import AnimationSlide7 from './components/AnimationSlide7/AnimationSlide7';
import AnimationSlide8 from './components/AnimationSlide8/AnimationSlide8';

import NoAnimationSlide2 from './components/AnimationSlide2/NoAnimationSlide2';
import NoAnimationSlide3 from './components/AnimationSlide3/NoAnimationSlide3';
import NoAnimationSlide1 from './components/AnimationSlide1/NoAnimationSlide1';
import NoAnimationSlide4 from './components/AnimationSlide4/NoAnimationSlide4';
import NoAnimationSlide5 from './components/AnimationSlide5/NoAnimationSlide5';
import NoAnimationSlide6 from './components/AnimationSlide6/NoAnimationSlide6';
import NoAnimationSlide7 from './components/AnimationSlide7/NoAnimationSlide7';
import NoAnimationSlide8 from './components/AnimationSlide8/NoAnimationSlide8';

const wpBiodiversityPost = ({
  data: {
    wpBiodiversityPost: {
      title, acfChaptersFields, seo, language, translations,
    },
  },
}) => {
  useEffect(() => {
    dispatch({ type: 'SET_TRANSLATION_LINKS', payload: { translationLinks: translations } });
    dispatch({ type: 'SET_LANG', payload: { lang: language } });
    dispatchTheme({ type: 'SET_THEME', payload: { themeName: 'biodiversity' } });
  }, []);

  const dispatch = useContext(GlobalDispatchContext);
  const dispatchTheme = useContext(ThemeDispatchContext);

  const [activeIndex, setActiveIndex] = useState(0);
  const [carouselOrSummary, setCarouselOrSummary] = useState('carousel');

  const slideAnimations = [<AnimationSlide1 />,
    <AnimationSlide2 />, <AnimationSlide3 />, <AnimationSlide4 />,
    <AnimationSlide5 />, <AnimationSlide6 />, <AnimationSlide7 />, <AnimationSlide8 />];

  const slideNoAnimations = [<NoAnimationSlide1 />,
    <NoAnimationSlide2 />, <NoAnimationSlide3 />, <NoAnimationSlide4 />,
    <NoAnimationSlide5 />, <NoAnimationSlide6 />, <NoAnimationSlide7 />, <NoAnimationSlide8 />];

  const slideBg = [<BgSlide1 />, <BgSlide2 />, <BgSlide3 />,
    <BgSlide4 />, <BgSlide5 />, <BgSlide6 />, <BgSlide7 />, <BgSlide8 />];

  const handleCarouselOrSummary = () => {
    if (carouselOrSummary === 'carousel') {
      setCarouselOrSummary('summary');
    } else {
      setCarouselOrSummary('carousel');
    }
  };

  return (
    <Layout>
      <SEO
        title={title}
        meta={
        [
          { opengraphSiteName: seo.opengraphSiteName },
          { opengraphType: seo.opengraphType },
          { opengraphTitle: seo.opengraphTitle },
          { opengraphImage: seo.opengraphImage?.link },
          { imageHeight: seo.opengraphImage?.mediaDetails.height },
          { imageWidth: seo.opengraphImage?.mediaDetails.width },
        ]
    }
      />
      <Container fluid className="container-global-styling biodiversity-post-container custom-max-height">
        <Row className="h-100 justify-content-center align-items-center">
          {
            carouselOrSummary === 'carousel'
              ? (
                <ChapterCarouselContent
                  chapterTitle={title}
                  acfChaptersFields={acfChaptersFields}
                  handleCarouselOrSummary={handleCarouselOrSummary}
                  eboti={<Eboti index={activeIndex} />}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  slideAnimations={slideAnimations}
                  slideNoAnimations={slideNoAnimations}
                  slideBg={slideBg}
                />
              )

              : (
                <SummaryContent
                  summaryTitle={acfChaptersFields.summaryTitle}
                  summaryText={acfChaptersFields.summaryText}
                  exercisesLinkLabelText={acfChaptersFields.exercisesLinkLabelText ? acfChaptersFields.exercisesLinkLabelText : ''}
                  exercisesPageLink={acfChaptersFields.exercisesPageLink ? acfChaptersFields.exercisesPageLink.uri : ''}
                  handleCarouselOrSummary={handleCarouselOrSummary}
                  lastSlideImage={<LastSlideImage />}
                />
              )
              }
        </Row>
      </Container>
    </Layout>
  );
};
export default wpBiodiversityPost;

export const query = graphql`
  query($id: String) {
    wpBiodiversityPost(id: { eq: $id }) {
      id  
      title
      content
      translations {
        language {
          locale
          name
        }
        uri
      }
      language {
          locale
          name
      }
      acfChaptersFields {
        fieldGroupName
        readMoreButtonLabel
        slidesContent {
        fieldGroupName
        text
        title
        introductionText
        additionalFieldsContent{
          title
          text
        }
        }
        summaryTitle
        summaryText
        exercisesLinkLabelText
        exercisesPageLink {
          ... on WpBiodiversityExercisesPost {
            uri
          }
        }
      }
      seo {
          opengraphUrl
          opengraphSiteName
          opengraphType
          opengraphTitle
          opengraphImage {
            link
            mediaDetails {
              height
              width
            }
          }
         }
    }
  }
`;

import * as React from 'react';
import { motion } from 'framer-motion';

function AnimationSlide4(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <filter id="shadow-slide-4" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="6" floodOpacity="0.4" />
        </filter>
        <clipPath id="biodiversity_slide_4__clip-path">
          <path
            className="biodiversity_slide_4__cls-1"
            d="M384.22 657.9l-30.18-5.2.98 15.93 27.04 3.97 2.16-14.7z"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_4__clip-path-2">
          <path
            className="biodiversity_slide_4__cls-1"
            d="M336.21 650.86l-30.18-5.19.99 15.92 27.04 3.97 2.15-14.7z"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_4__clip-path-3">
          <path
            className="biodiversity_slide_4__cls-1"
            d="M782.78 609.88l-39.96 7.3 8.49 19.39 35.49-7.39-4.02-19.3z"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_4__clip-path-4">
          <path
            className="biodiversity_slide_4__cls-1"
            d="M669.17 633.57l-39.96 7.3 8.49 19.39 35.5-7.4-4.03-19.29z"
          />
        </clipPath>
        <style>
          {
            '.biodiversity_slide_4__cls-1,.biodiversity_slide_4__cls-13{fill:#88b8b6}.biodiversity_slide_4__cls-3{opacity:.5;mix-blend-mode:multiply}.biodiversity_slide_4__cls-4{fill:#c6edf5}.biodiversity_slide_4__cls-5{fill:#e1f4ec}.biodiversity_slide_4__cls-6{fill:#dda862}.biodiversity_slide_4__cls-7{fill:#56b071}.biodiversity_slide_4__cls-10{fill:#74aaaa}.biodiversity_slide_4__cls-13{stroke:#88b8b6;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.81px}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="biodiversity_slide_4__Layer_1" data-name="Layer 1">
          <motion.g
            // filter="url(#shadow-slide-4)"
            animate={{
              x: [-700, -0, -0, 700],
              y: [-300, -120, -120, -300],
              rotate: ['0deg', '-10deg', '-10deg', '0deg'],
            }}
            transition={{ duration: 20, repeat: Infinity }}
            style={{
              transformBox: 'fill-box',
            }}
            id="biodiversity_slide_4__Helicopter"
          >
            <motion.g
              animate={{
                scale: [1, 0.5, 1],
              }}
              transition={{ duration: 0.1, repeat: Infinity }}
              style={{
                transformBox: 'fill-box',
                originX: 'center',
                originY: 'center',
                padding: '30px',
              }}
            >
              <ellipse
                className="biodiversity_slide_4__cls-5"
                cx={974.71}
                cy={159.32}
                rx={4.55}
                ry={43.96}
                transform="rotate(-80.55 974.756 159.285)"
              />
            </motion.g>
            <path
              className="biodiversity_slide_4__cls-5"
              d="M998.82 182.4a16.17 16.17 0 00-10.5-6.55l-28.07-4.67-1.83 11a8.73 8.73 0 007.18 10l31.74 5.28a3.72 3.72 0 004.28-3.06 16.11 16.11 0 00-2.8-12zm-18.08 1.6a3.45 3.45 0 01-4 2.83l-1.72-.29a3.43 3.43 0 01-2.82-4l.72-4.34a3.43 3.43 0 013.95-2.83l1.72.29a3.44 3.44 0 012.83 3.95zm18.2 6.51L990 189a7.39 7.39 0 01-6.08-8.52l.33-2a1.84 1.84 0 012.13-1.52l3.61.6a5.68 5.68 0 012.24.86 19.92 19.92 0 014.09 3.51 16 16 0 013.78 7.42 1 1 0 01-1.16 1.17z"
            />
            <path
              className="biodiversity_slide_4__cls-5"
              d="M919.58 164.42l42.17 7.01-1.89 11.38-41.35-11.96 1.07-6.43z"
            />
            <path
              className="biodiversity_slide_4__cls-5"
              d="M915.17 160.3a6.64 6.64 0 105.46 7.64 6.64 6.64 0 00-5.46-7.64zm3.5 7.31a4.49 4.49 0 01-.7 1.78l-2.59-1.72a1.7 1.7 0 00.1-.32 2.09 2.09 0 000-.25l3.23-.65a4.54 4.54 0 01-.04 1.16zm0-1.77l-3.25.65a1.61 1.61 0 00-.32-.48l1.88-2.84a4.65 4.65 0 011.64 2.67zm-3.77-3.58a4.62 4.62 0 011.56.56l-1.88 2.84a2.07 2.07 0 00-.33-.1H914l-.66-3.29a4.8 4.8 0 011.51-.01zm-2.17.15l.66 3.26a1.58 1.58 0 00-.48.33l-2.63-1.75a4.6 4.6 0 012.4-1.84zm-.28 4.68l-2.9.58a4.71 4.71 0 010-1.59 4.65 4.65 0 01.42-1.29l2.6 1.73a1.5 1.5 0 00-.1.32 2 2 0 00-.07.25zm-2.74 1.18l2.86-.57a1.58 1.58 0 00.33.48l-1.58 2.37a4.59 4.59 0 01-1.66-2.28zm3.66 3.17a4.66 4.66 0 01-1.53-.55l1.58-2.36a1.68 1.68 0 00.32.09 1.06 1.06 0 00.25 0l.57 2.84a4.76 4.76 0 01-1.24-.02zm1.8-.06l-.57-2.86a1.58 1.58 0 00.48-.33l2.56 1.71a4.67 4.67 0 01-2.52 1.48z"
            />
            <path
              className="biodiversity_slide_4__cls-5"
              d="M918.91 162.94l-4.36-1.23-4.8.94 1.25-13.59a1.92 1.92 0 012.15-1.79l2.69.45a1.84 1.84 0 011.5 1.66z"
            />
            <path
              className="biodiversity_slide_4__cls-5"
              d="M968.24 167.94h16.23a6.26 6.26 0 016.26 6.26v.51h-27.4v-1.87a4.9 4.9 0 014.91-4.9z"
              transform="rotate(9.45 976.59 171.189)"
            />
            <circle
              className="biodiversity_slide_4__cls-5"
              cx={914.08}
              cy={166.85}
              r={4.65}
            />
            <path
              className="biodiversity_slide_4__cls-5"
              d="M915.38 167.67l2.59 1.72a3.75 3.75 0 01-.38.51l-2.59-1.71a1.58 1.58 0 01-.48.33l.57 2.86a3.88 3.88 0 01-.61.1l-.57-2.84a1.06 1.06 0 01-.25 0 1.68 1.68 0 01-.32-.09l-1.58 2.36a3.58 3.58 0 01-.52-.34l1.58-2.37a1.58 1.58 0 01-.33-.48l-2.86.57a4.08 4.08 0 01-.16-.6l2.9-.58a2 2 0 010-.25 1.5 1.5 0 01.1-.32l-2.6-1.73a3.65 3.65 0 01.32-.54l2.63 1.75a1.58 1.58 0 01.48-.33l-.66-3.26a6 6 0 01.61-.15l.66 3.29h.25a2.07 2.07 0 01.33.1l1.88-2.84a3.93 3.93 0 01.52.35l-1.84 2.82a1.61 1.61 0 01.32.48l3.25-.65a4.09 4.09 0 01.1.61l-3.23.65a2.09 2.09 0 010 .25 1.7 1.7 0 01-.11.33z"
            />
            <path
              className="biodiversity_slide_4__cls-5"
              d="M918 169.39l.37.25-.35.52-.4-.26-2.62-1.71a1.58 1.58 0 01-.48.33l.57 2.86.13.62-.61.12-.13-.64-.57-2.84a1.06 1.06 0 01-.25 0 1.68 1.68 0 01-.32-.09l-1.58 2.36-.39.6-.52-.35.39-.59 1.58-2.37a1.58 1.58 0 01-.33-.48l-2.86.57-.62.13-.12-.62.58-.11 2.9-.58a2 2 0 010-.25 1.5 1.5 0 01.1-.32l-2.6-1.73-.36-.24.35-.52.33.22 2.63 1.75a1.58 1.58 0 01.48-.33l-.66-3.26v-.22l.61-.12v.19l.66 3.29h.25a2.07 2.07 0 01.33.1l1.88-2.84.08-.12.52.35-.08.12-1.84 2.82a1.61 1.61 0 01.32.48l3.25-.65.23-.05.12.61h-.25l-3.23.65a2.09 2.09 0 010 .25 1.7 1.7 0 01-.1.32z"
            />
            <rect
              className="biodiversity_slide_4__cls-5"
              x={906.11}
              y={156.69}
              width={6.94}
              height={2.16}
              rx={1.08}
              transform="rotate(9.45 909.143 157.639)"
            />
            <rect
              className="biodiversity_slide_4__cls-5"
              x={957.07}
              y={198.37}
              width={31.53}
              height={2.39}
              rx={1.2}
              transform="rotate(9.45 972.407 199.43)"
            />
            <path
              className="biodiversity_slide_4__cls-5"
              transform="rotate(-12.16 983.242 197.78)"
              d="M982.45 193.84h1.37v7.81h-1.37z"
            />
            <path
              className="biodiversity_slide_4__cls-5"
              transform="rotate(-51.08 964.473 194.657)"
              d="M960.59 193.96h7.81v1.37h-7.81z"
            />
            <rect
              className="biodiversity_slide_4__cls-5"
              x={972.95}
              y={156.53}
              width={2.31}
              height={12.81}
              rx={1.16}
              transform="rotate(9.45 973.646 162.844)"
            />
          </motion.g>
          <g
            filter="url(#shadow-slide-4)"
            id="biodiversity_slide_4___1_FG"
            data-name="1 FG"
            transform="translate(0, 40)"
          >
            <g id="biodiversity_slide_4__tree_B" data-name="tree B">
              <path
                className="biodiversity_slide_4__cls-6"
                d="M211.87 603.74h5.88v61.92h-5.88z"
              />
              <path
                className="biodiversity_slide_4__cls-6"
                d="M200.73 608.58v-5.48h-3.87v5.48c0 11.65 9.88 17.54 19.86 17.66v-3.38c-8.05-.12-15.99-4.86-15.99-14.28z"
              />
              <path
                className="biodiversity_slide_4__cls-6"
                d="M185.05 598.79v-5.48h-3.86v5.48c0 11.64 9.87 17.53 19.85 17.65v-3.38c-8.04-.12-15.99-4.88-15.99-14.27zM231 600.76v-5.84h3.45v5.84c0 12.42-8.8 18.7-17.7 18.83V616c7.14-.15 14.25-5.23 14.25-15.24z"
              />
              <path
                className="biodiversity_slide_4__cls-6"
                d="M236.71 608.74h-5.84v3.44h5.84c12.42 0 18.7-8.8 18.83-17.7h-3.6c-.14 7.18-5.21 14.26-15.23 14.26z"
              />
              <rect
                className="biodiversity_slide_4__cls-7"
                x={159.3}
                y={574.76}
                width={111.01}
                height={28.97}
                rx={14.49}
              />
              <rect
                className="biodiversity_slide_4__cls-7"
                x={178.22}
                y={564.06}
                width={73.16}
                height={28.97}
                rx={14.49}
              />
            </g>
            <g
              transform="translate(-400, -615)"
              id="biodiversity_slide_4__small_car-2"
              data-name="small car"
              style={{
                transformBox: 'fill-box',
              }}
            >
              <animateMotion
                dur="10s"
                rotate="auto"
                repeatCount="indefinite"
                path="M-2.69,606.44s328.28,84.91,504.26,81.7c105.33-1.92,347.85-68,564.78-101.4,145.49-22.38,382.53-12.67,382.53-12.67"
              />
              <g
                transform="rotate(-9 0 0)"
              >
                <path
                  className="biodiversity_slide_4__cls-1"
                  d="M387.42 670.12a4.59 4.59 0 002.77-3.57l1-6.8a12.17 12.17 0 00-10.28-13.81l-15.59-2.29-1.26-2.16-.71-1.22L353 622.43l-.44-.78a3.76 3.76 0 00-2.68-1.79l-33.86-5a3.63 3.63 0 00-3.92 2.2l-7.1 17.75-2.76 18.85a4.59 4.59 0 001.53 4.14 4.59 4.59 0 01-1.53-4.14l-.37 2.53a4.6 4.6 0 003.88 5.22L384.59 673a4.61 4.61 0 005.23-3.89l.37-2.53a4.59 4.59 0 01-2.77 3.54zm-48.37-45.77a2.32 2.32 0 012.64-2l7.49 1.1 10.56 18.83-20.53-3a2.33 2.33 0 01-2-2.64zm-28.74 8.27l4.82-12.53A1.74 1.74 0 01317 619l15.49 2.27a1.86 1.86 0 011.57 2.11l-1.88 12.85a1.86 1.86 0 01-2.12 1.57l-18.42-2.7a1.78 1.78 0 01-1.33-2.48z"
                />
                <path
                  className="biodiversity_slide_4__cls-1"
                  d="M384.22 657.9l-30.18-5.2.98 15.93 27.04 3.97 2.16-14.7z"
                />
                <g clipPath="url(#biodiversity_slide_4__clip-path)">
                  <ellipse
                    className="biodiversity_slide_4__cls-1"
                    cx={368.13}
                    cy={669.13}
                    rx={12.44}
                    ry={12.85}
                    transform="rotate(-81.66 368.117 669.12)"
                  />
                </g>
                <g id="biodiversity_slide_4__hjul">
                  <ellipse
                    className="biodiversity_slide_4__cls-1"
                    cx={368.14}
                    cy={669.04}
                    rx={10.92}
                    ry={11.28}
                    transform="rotate(-81.4 371.034 669.302)"
                  />
                  <ellipse
                    className="biodiversity_slide_4__cls-1"
                    cx={368.13}
                    cy={669.13}
                    rx={6.72}
                    ry={6.94}
                    transform="rotate(-81.66 368.117 669.12)"
                  />
                </g>
                <path
                  className="biodiversity_slide_4__cls-1"
                  d="M336.21 650.86l-30.18-5.19.99 15.92 27.04 3.97 2.15-14.7z"
                />
                <g clipPath="url(#biodiversity_slide_4__clip-path-2)">
                  <ellipse
                    className="biodiversity_slide_4__cls-1"
                    cx={320.13}
                    cy={662.09}
                    rx={12.44}
                    ry={12.85}
                    transform="rotate(-81.66 320.114 662.084)"
                  />
                </g>
                <g id="biodiversity_slide_4__hjul-2" data-name="hjul">
                  <ellipse
                    className="biodiversity_slide_4__cls-1"
                    cx={320.14}
                    cy={662.01}
                    rx={10.92}
                    ry={11.28}
                    transform="rotate(-81.66 320.125 662.002)"
                  />
                  <ellipse
                    className="biodiversity_slide_4__cls-1"
                    cx={320.13}
                    cy={662.09}
                    rx={6.72}
                    ry={6.94}
                    transform="rotate(-81.66 320.114 662.084)"
                  />
                </g>
                <path
                  className="biodiversity_slide_4__cls-1"
                  d="M377.58 648.88l6.26.92a6 6 0 015.06 6.8l-10.57-1.55a1.63 1.63 0 01-1.33-1.85zM365.15 644.82l-1.76-.26-.72-1.3-11.86-21.15-.64-1.14.82-.29.93 1.59 12.46 21.24.77 1.31z"
                />
              </g>
            </g>
            <path
              className="biodiversity_slide_4__cls-1"
              d="M1126.21 385.19h5.4v-9.78a3.44 3.44 0 013.32-3.56h55.58a3.45 3.45 0 013.32 3.56v9.78h27.72a3.68 3.68 0 013.55 3.8v5.07a3.69 3.69 0 01-3.55 3.81h-.55V692h-94.27V397.87h-.53a3.69 3.69 0 01-3.56-3.81V389a3.68 3.68 0 013.57-3.81zm69.35 99.36a3 3 0 002.93 3h4.82a3 3 0 002.93-3V467.2a3 3 0 00-2.93-3h-4.82a3 3 0 00-2.93 3zm0-47.12a3 3 0 002.93 3h4.82a3 3 0 002.93-3v-17.34a3 3 0 00-2.93-3h-4.82a3 3 0 00-2.93 3zm-50.07 23.41a3 3 0 002.93 3h4.82a3 3 0 002.93-3V443.5a3 3 0 00-2.93-3h-4.82a3 3 0 00-2.93 3z"
            />
            <rect
              className="biodiversity_slide_4__cls-10"
              x={1119.24}
              y={383.69}
              width={109.06}
              height={15.46}
              rx={4.45}
            />
            <g
              transform="translate(-580, -800)"
              id="biodiversity_slide_4__Bus-2"
              data-name="Bus"
            >
              <animateMotion
                dur="20s"
                rotate="auto"
                repeatCount="indefinite"
                path="M-2.69,606.44s328.28,84.91,504.26,81.7c105.33-1.92,347.85-68,564.78-101.4,145.49-22.38,382.53-12.67,382.53-12.67"
              />
              <g
                transform="rotate(13)"
              >
                <path
                  className="biodiversity_slide_4__cls-1"
                  d="M782.78 609.88l-39.96 7.3 8.49 19.39 35.49-7.39-4.02-19.3z"
                />
                <g clipPath="url(#biodiversity_slide_4__clip-path-3)">
                  <ellipse
                    className="biodiversity_slide_4__cls-1"
                    cx={767.87}
                    cy={631.21}
                    rx={17.05}
                    ry={16.5}
                    transform="rotate(-11.78 767.74 631.138)"
                  />
                </g>
                <g id="biodiversity_slide_4__hjul-3" data-name="hjul">
                  <ellipse
                    className="biodiversity_slide_4__cls-1"
                    cx={767.84}
                    cy={631.1}
                    rx={14.96}
                    ry={14.48}
                    transform="rotate(-11.78 767.706 630.988)"
                  />
                  <ellipse
                    className="biodiversity_slide_4__cls-1"
                    cx={767.87}
                    cy={631.21}
                    rx={9.21}
                    ry={8.91}
                    transform="rotate(-11.78 767.74 631.138)"
                  />
                </g>
                <path
                  className="biodiversity_slide_4__cls-1"
                  d="M669.17 633.57l-39.96 7.3 8.49 19.39 35.5-7.4-4.03-19.29z"
                />
                <g clipPath="url(#biodiversity_slide_4__clip-path-4)">
                  <ellipse
                    className="biodiversity_slide_4__cls-1"
                    cx={654.26}
                    cy={654.9}
                    rx={17.05}
                    ry={16.5}
                    transform="rotate(-11.78 654.159 654.786)"
                  />
                </g>
                <g id="biodiversity_slide_4__hjul-4" data-name="hjul">
                  <ellipse
                    className="biodiversity_slide_4__cls-1"
                    cx={654.24}
                    cy={654.79}
                    rx={14.96}
                    ry={14.48}
                    transform="rotate(-11.78 654.12 654.684)"
                  />
                  <ellipse
                    className="biodiversity_slide_4__cls-1"
                    cx={654.26}
                    cy={654.9}
                    rx={9.21}
                    ry={8.91}
                    transform="rotate(-11.78 654.159 654.786)"
                  />
                </g>
                <path
                  className="biodiversity_slide_4__cls-1"
                  d="M785.06 594l-7.06-1.86a4.1 4.1 0 01-2.71-2.31l-9.65-21.69a4.09 4.09 0 00-4.59-2.35l-143.52 29.92a4.11 4.11 0 00-3.18 4.86l11.49 55.12A6.89 6.89 0 00634 661l156.36-32.6a4.1 4.1 0 003.19-4.86L788 597.13a4.1 4.1 0 00-2.94-3.13zM644 617.29a2.09 2.09 0 01-1.61 2.47l-13.81 2.87a3.09 3.09 0 01-3.66-2.4l-3.68-17.65a3.1 3.1 0 012.4-3.67l13.8-2.87a2.07 2.07 0 012.46 1.61zm23.73-5a2.09 2.09 0 01-1.62 2.49l-15 3.12a2.09 2.09 0 01-2.48-1.63l-4.09-19.6a2.1 2.1 0 011.63-2.49l15-3.12a2.1 2.1 0 012.49 1.63zm23.74-5a2.09 2.09 0 01-1.62 2.49l-15 3.12a2.09 2.09 0 01-2.48-1.63l-4.09-19.6a2.1 2.1 0 011.63-2.49l15-3.12a2.1 2.1 0 012.49 1.63zm23.74-5a2.11 2.11 0 01-1.63 2.49l-15 3.12a2.1 2.1 0 01-2.49-1.63L692 586.8a2.11 2.11 0 011.63-2.49l15-3.12a2.1 2.1 0 012.48 1.64zm27.73-6.79a3.1 3.1 0 01-2.4 3.66l-18.18 3.79a2.12 2.12 0 01-2.5-1.64l-4.08-19.58a2.12 2.12 0 011.64-2.5l18.17-3.79a3.09 3.09 0 013.66 2.4zm27.41-2.56l-20.76 4.33a1.9 1.9 0 01-2.26-1.47l-4.17-20a1.92 1.92 0 011.48-2.26l17-3.53a1.9 1.9 0 012.15 1.13l8 19.2a1.9 1.9 0 01-1.45 2.73z"
                />
                <rect
                  className="biodiversity_slide_4__cls-1"
                  x={627.48}
                  y={585.55}
                  width={38.21}
                  height={7.14}
                  rx={3.15}
                  transform="rotate(-11.78 646.443 589.04)"
                />
                <path
                  className="biodiversity_slide_4__cls-1"
                  transform="rotate(-11.78 710.257 642.793)"
                  d="M631.33 640.7H789.5v4.36H631.33z"
                />
                <path
                  className="biodiversity_slide_4__cls-1"
                  transform="rotate(-11.78 632.85 659.543)"
                  d="M629.3 658.04h7.38v3.17h-7.38z"
                />
                <path
                  className="biodiversity_slide_4__cls-1"
                  transform="rotate(-11.78 644.161 606.878)"
                  d="M641.89 594.87h4.76v24.23h-4.76z"
                />
                <path
                  className="biodiversity_slide_4__cls-1"
                  transform="rotate(-11.78 667.89 601.93)"
                  d="M665.63 589.92h4.76v24.23h-4.76z"
                />
                <path
                  className="biodiversity_slide_4__cls-1"
                  transform="rotate(-11.5 708.114 607.361)"
                  d="M689.37 584.97h4.75v24.23h-4.75z"
                />
                <path
                  className="biodiversity_slide_4__cls-1"
                  transform="rotate(-11.78 715.346 592.033)"
                  d="M713.1 580.02h4.76v24.23h-4.76z"
                />
                <path
                  className="biodiversity_slide_4__cls-1"
                  transform="rotate(-11.78 644.85 610.182)"
                  d="M642.57 592.6h4.76v35.31h-4.76z"
                />
                <path
                  className="biodiversity_slide_4__cls-1"
                  transform="rotate(-11.78 668.577 605.234)"
                  d="M666.31 587.65h4.76v35.31h-4.76z"
                />
                <path
                  className="biodiversity_slide_4__cls-1"
                  transform="rotate(-11.78 692.306 600.285)"
                  d="M690.05 582.7h4.75v35.31h-4.75z"
                />
                <path
                  className="biodiversity_slide_4__cls-1"
                  transform="rotate(-11.78 716.034 595.337)"
                  d="M713.79 577.75h4.76v35.31h-4.76z"
                />
              </g>
            </g>
            <path
              id="biodiversity_slide_4__lygtep\xE6l"
              className="biodiversity_slide_4__cls-13"
              d="M1102.64 570.49h-.4v-65.58a5.85 5.85 0 00-5.85-5.84h-45.26a1.32 1.32 0 00-1.32 1.32v.76a1.32 1.32 0 001.32 1.32H1065a1.32 1.32 0 001.32-1.32v-.7h27.08a4.34 4.34 0 014.34 4.35v65.69h-.4a1.25 1.25 0 00-1.25 1.25V585a1.25 1.25 0 001.25 1.25h5.33a1.25 1.25 0 001.25-1.25v-13.26a1.25 1.25 0 00-1.28-1.25z"
            />
            <path
              id="biodiversity_slide_4__lygtep\xE6l-2"
              data-name="lygtep\xE6l"
              className="biodiversity_slide_4__cls-13"
              d="M1394.44 562.67h-.4v-65.58a5.84 5.84 0 00-5.85-5.84h-45.26a1.33 1.33 0 00-1.33 1.32v.76a1.33 1.33 0 001.33 1.32h13.84a1.32 1.32 0 001.32-1.32v-.71h27.07a4.35 4.35 0 014.35 4.35v65.7h-.4a1.25 1.25 0 00-1.25 1.25v13.26a1.25 1.25 0 001.25 1.25h5.33a1.25 1.25 0 001.25-1.25v-13.26a1.25 1.25 0 00-1.25-1.25z"
            />
            <path
              id="biodiversity_slide_4__lygtep\xE6l-3"
              data-name="lygtep\xE6l"
              className="biodiversity_slide_4__cls-13"
              d="M471 675.94h-.4v-65.58a5.85 5.85 0 00-5.84-5.85H419.49a1.33 1.33 0 00-1.32 1.33v.76a1.32 1.32 0 001.32 1.32h13.84a1.32 1.32 0 001.32-1.32v-.71h27.08a4.35 4.35 0 014.35 4.35v65.7h-.41a1.24 1.24 0 00-1.24 1.25v13.26a1.24 1.24 0 001.24 1.25H471a1.25 1.25 0 001.25-1.25v-13.26a1.25 1.25 0 00-1.25-1.25z"
            />
            <path
              className="biodiversity_slide_4__cls-1"
              d="M44.74 481.88h5.41v-9.78a3.44 3.44 0 013.31-3.55h55.59a3.44 3.44 0 013.32 3.55v9.78h27.71a3.69 3.69 0 013.56 3.81v5.07a3.68 3.68 0 01-3.56 3.8h-.53v294.09H45.27V494.56h-.53a3.68 3.68 0 01-3.55-3.8v-5.07a3.69 3.69 0 013.55-3.81zm69.36 99.36a3 3 0 002.93 3h4.82a3 3 0 002.93-3V563.9a3 3 0 00-2.93-3H117a3 3 0 00-2.93 3zm0-47.11a3 3 0 002.93 3h4.82a3 3 0 002.93-3v-17.35a3 3 0 00-2.93-3H117a3 3 0 00-2.93 3zM64 557.54a3 3 0 002.93 3h4.82a3 3 0 002.93-3v-17.35a3 3 0 00-2.93-3H67a3 3 0 00-2.93 3z"
            />
            <rect
              className="biodiversity_slide_4__cls-1"
              x={37.77}
              y={480.39}
              width={109.06}
              height={15.46}
              rx={4.45}
            />
            <g id="biodiversity_slide_4__chinese_house" data-name="chinese house">
              <path
                className="biodiversity_slide_4__cls-1"
                d="M1044.9 542.26h-76.67a2 2 0 00-2 2v52.88a2 2 0 002 2h76.67a2 2 0 002-2v-52.87a2 2 0 00-2-2.01zm-56.79 39.82a2.82 2.82 0 01-2.81 2.83h-10.63a2.83 2.83 0 01-2.82-2.83v-17.94a2.82 2.82 0 012.82-2.82h10.63a2.81 2.81 0 012.81 2.82zm50.9 0a2.83 2.83 0 01-2.83 2.83h-10.61a2.83 2.83 0 01-2.83-2.83v-17.94a2.83 2.83 0 012.83-2.82h10.61a2.83 2.83 0 012.83 2.82z"
              />
              <rect
                className="biodiversity_slide_4__cls-10"
                x={973.96}
                y={504.9}
                width={65.58}
                height={7.63}
                rx={2.02}
              />
              <rect
                className="biodiversity_slide_4__cls-1"
                x={961.95}
                y={543.85}
                width={91.3}
                height={9.07}
                rx={2.02}
              />
              <path
                className="biodiversity_slide_4__cls-10"
                d="M1060.76 537.69l-17.09-12.55-9.08-14.69a2 2 0 00-1.71-.95h-51.43a2 2 0 00-1.72 1l-8.89 14.5-17.09 12.7a2 2 0 001.2 3.63h104.61a2 2 0 001.2-3.64zM993.81 599.17h23.07v-37.31a5.08 5.08 0 00-5.09-5.08h-12.9a5.07 5.07 0 00-5.08 5.08z"
              />
              <rect
                className="biodiversity_slide_4__cls-10"
                x={948.68}
                y={534.84}
                width={120.33}
                height={12.99}
                rx={2.02}
              />
            </g>
            <rect
              className="biodiversity_slide_4__cls-1"
              x={941.29}
              y={593.39}
              width={141.37}
              height={37.71}
              rx={6.32}
            />
            <path
              id="biodiversity_slide_4__Ground"
              className="biodiversity_slide_4__cls-1"
              d="M1511.43 845.71L-.93 818.39v-212s328.28 84.92 504.26 81.7c105.33-1.92 347.85-68 564.78-101.4 145.49-22.37 382.53-12.66 382.53-12.66z"
            />
          </g>
          <g id="prefix__Clouds">
            <motion.g
              id="prefix__Cloud"
              // filter="url(#shadow-slide-4)"
              animate={{
                x: ['-500px', '1480px'],
              }}
              transition={{ duration: 250, ease: 'linear', repeat: Infinity }}
            >
              <path
                className="biodiversity_slide_4__cls-4"
                d="M204.72 89.36A19.39 19.39 0 01222.39 70a19.4 19.4 0 0118.75-14.48h2.55A19.4 19.4 0 01262.43 70H268a19.42 19.42 0 0119.4 19.4v2a18.93 18.93 0 01-1 6 3 3 0 01-2.89 2.07h-75a3 3 0 01-2.88-2.07 19.21 19.21 0 01-1-6v-1c0-.33.09-.7.09-1.04z"
              />
            </motion.g>
            <motion.g
              id="prefix__Cloud-2"
              data-name="Cloud"
              // filter="url(#shadow-slide-4)"
              animate={{
                x: ['-800px', '1080px'],
              }}
              transition={{ duration: 180, ease: 'linear', repeat: Infinity }}
            >
              <path
                className="biodiversity_slide_4__cls-4"
                d="M502.14 244.07a12.67 12.67 0 0111.53-12.61 12.66 12.66 0 0112.24-9.46h1.67a12.65 12.65 0 0112.22 9.4h3.63a12.68 12.68 0 0112.66 12.66v1.32a12.58 12.58 0 01-.62 3.93 2 2 0 01-1.89 1.36h-48.93a2 2 0 01-1.88-1.36 12.3 12.3 0 01-.63-3.93v-.65-.66z"
              />
            </motion.g>
            <motion.g
              id="prefix__Cloud-3"
              data-name="Cloud"
              // filter="url(#shadow-slide-4)"
              animate={{
                x: ['-800px', '1080px'],
              }}
              transition={{ duration: 305, ease: 'linear', repeat: Infinity }}
              style={{
                transformBox: 'fill-box',
              }}
            >
              <path
                className="biodiversity_slide_4__cls-4"
                d="M763.45 99.26a25.08 25.08 0 0122.84-25 25.07 25.07 0 0124.25-18.72h3.29a25.07 25.07 0 0124.22 18.62h7.18a25.11 25.11 0 0125.08 25.08V101.85a24.66 24.66 0 01-1.24 7.78 3.94 3.94 0 01-3.73 2.68h-96.92a3.94 3.94 0 01-3.73-2.68 24.66 24.66 0 01-1.24-7.78v-1.3-1.29z"
              />
            </motion.g>
            <motion.g
              // filter="url(#shadow-slide-4)"
              animate={{
                x: ['-1300px', '180px'],
              }}
              transition={{ duration: 105, ease: 'linear', repeat: Infinity }}
              style={{
                transformBox: 'fill-box',
              }}
              id="prefix__Cloud-4"
              data-name="Cloud"
            >
              <path
                className="biodiversity_slide_4__cls-4"
                d="M1296.47 76a11.31 11.31 0 0110.31-11.28 11.34 11.34 0 0111-8.46h1.49a11.33 11.33 0 0110.94 8.42h3.24A11.34 11.34 0 011344.74 76v1.18a11.18 11.18 0 01-.56 3.52 1.78 1.78 0 01-1.69 1.21h-43.78a1.78 1.78 0 01-1.68-1.21 11.18 11.18 0 01-.56-3.52v-.59V76z"
              />
            </motion.g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AnimationSlide4;

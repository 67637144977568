import * as React from 'react';
import { motion } from 'framer-motion';

function AnimationSlide1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      preserveAspectRatio="xMinYMin slice"
      style={{
        width: '100%',
        height: '100%',
        minWidth: '100%',
        top: 0,
        left: 0,
      }}
      {...props}
    >
      <defs>
        <filter id="shadow" colorInterpolationFilters="sRGB">
          <feDropShadow dx="2" dy="2" stdDeviation="3" floodOpacity="0.5" />
        </filter>
        <clipPath id="biodiversity_slide_1__clip-path">
          <circle
            className="biodiversity_slide_1__cls-1"
            cx={1027.53}
            cy={491.81}
            r={10.91}
          />
        </clipPath>
        <clipPath id="biodiversity_slide_1__clip-path-2">
          <circle
            className="biodiversity_slide_1__cls-1"
            cx={1075.75}
            cy={488.34}
            r={10.91}
          />
        </clipPath>
        <clipPath id="biodiversity_slide_1__clip-path-3">
          <rect
            className="biodiversity_slide_1__cls-1"
            x={315.32}
            y={460.99}
            width={54.35}
            height={24.8}
            rx={10.08}
            transform="rotate(-14 353.103 478.812)"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_1__clip-path-4">
          <path
            className="biodiversity_slide_1__cls-1"
            d="M351.92 465.61a6.54 6.54 0 114.9 7.92 6.59 6.59 0 01-4.9-7.92"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_1__clip-path-5">
          <circle className="biodiversity_slide_1__cls-1" cx={605.94} cy={169.7} r={4.54} />
        </clipPath>
        <clipPath id="biodiversity_slide_1__clip-path-6">
          <ellipse
            className="biodiversity_slide_1__cls-1"
            cx={356.82}
            cy={169.7}
            rx={4.67}
            ry={4.41}
            transform="rotate(-44.19 356.852 169.7)"
          />
        </clipPath>
        <style>
          {
            '.biodiversity_slide_1__cls-1{fill:none}.biodiversity_slide_1__cls-4{fill:#e9fcff}.biodiversity_slide_1__cls-5{fill:#eb826a}.biodiversity_slide_1__cls-7{fill:#fff}.biodiversity_slide_1__cls-12{fill:#414042}.biodiversity_slide_1__cls-15{fill:#f99c43}.biodiversity_slide_1__cls-17{fill:#edbe69}.biodiversity_slide_1__cls-19{fill:#e1f4ec}.biodiversity_slide_1__cls-22{fill:#fced6f}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="biodiversity_slide_1__Layer_1" data-name="Layer 1">
          <g
            // filter="url(#shadow)"
            className="crab"
          >
            <path
              className="biodiversity_slide_1__cls-5"
              transform="rotate(-80.45 1073.315 500.317)"
              d="M1061.67 498.84h23.3v2.94h-23.3z"
            />
            <path
              className="biodiversity_slide_1__cls-5"
              transform="rotate(-17.79 1030.832 503.394)"
              d="M1029.35 491.72h2.94v23.3h-2.94z"
            />
            <path
              className="biodiversity_slide_1__cls-5"
              d="M1035.17 529.75s-1.11 0-10.26.68c-10.57.76-14.11-7.45-14.15-7.53l2.71-1.14c.12.26 2.82 6.35 11.23 5.74 9.24-.66 10.37-.69 10.51-.69zM1074.72 524.57l-.43-2.9c.13 0 1.25-.16 10.49-.83 8.41-.6 10.22-7 10.29-7.28l2.85.73c0 .09-2.35 8.72-12.93 9.48-9.14.66-10.23.8-10.27.8z"
            />
            <path
              className="biodiversity_slide_1__cls-5"
              d="M1036.11 534.55l36.9-2.66a4.62 4.62 0 004.16-3.52l3.64-15a4.61 4.61 0 00-4.82-5.69l-46.31 3.32a4.62 4.62 0 00-4 6.33l5.77 14.37a4.6 4.6 0 004.66 2.85z"
            />
            <circle
              className="biodiversity_slide_1__cls-5"
              cx={1027.53}
              cy={491.81}
              r={13.79}
            />
            <g clipPath="url(#biodiversity_slide_1__clip-path)" id="biodiversity_slide_1__eye">
              <circle
                className="biodiversity_slide_1__cls-7"
                cx={1027.53}
                cy={491.81}
                r={10.91}
              />
              <circle cx={1036.19} cy={488.12} r={10.91} />
            </g>
            <circle
              className="biodiversity_slide_1__cls-5"
              cx={1075.75}
              cy={488.34}
              r={13.79}
            />
            <g
              clipPath="url(#biodiversity_slide_1__clip-path-2)"
              id="biodiversity_slide_1__eye-2"
              data-name="eye"
            >
              <circle
                className="biodiversity_slide_1__cls-7"
                cx={1075.75}
                cy={488.34}
                r={10.91}
              />
              <circle cx={1084.42} cy={484.65} r={10.91} />
            </g>
            <motion.g
              className="hand-right"
              animate={{ rotate: ['-20deg', '20deg', '-20deg'] }}
              transition={{
                duration: 2, ease: 'linear', repeat: Infinity,
              }}
              style={{
                transformBox: 'fill-box',
                originX: 'left',
                originY: 'bottom',
              }}
            >
              <path
                className="biodiversity_slide_1__cls-5"
                d="M1094.12 502h21.72v17.38h-21.72a5.92 5.92 0 01-5.92-5.92v-5.56a5.92 5.92 0 015.92-5.9z"
                transform="rotate(-42.29 1101.884 510.663)"
              />
              <motion.g
                animate={{ rotate: ['-40deg', '20deg', '-40deg'] }}
                transition={{
                  duration: 2, ease: 'linear', repeat: Infinity,
                }}
                style={{
                  transformBox: 'fill-box',
                  originX: 'left',
                  originY: 'bottom',
                }}
              >
                <path
                  className="biodiversity_slide_1__cls-5"
                  d="M1102.68 489.75h9.32a6.48 6.48 0 016.48 6.48v3h-15.84v-9.52l.04.04z"
                  transform="rotate(-67.1 1110.55 494.514)"
                />
              </motion.g>
              <motion.g
                animate={{ rotate: ['40deg', '-20deg', '40deg'] }}
                transition={{
                  duration: 2, ease: 'linear', repeat: Infinity,
                }}
                style={{
                  transformBox: 'fill-box',
                  originX: 'left',
                  originY: 'center',
                }}
              >
                <path
                  className="biodiversity_slide_1__cls-5"
                  d="M1111.68 498.59h13.22v2.89a6.17 6.17 0 01-6.17 6.17h-7v-9.06h-.05z"
                  transform="rotate(-21.19 1118.062 503.056)"
                />
              </motion.g>
            </motion.g>
            <motion.g
              className="hand-left"
              animate={{ rotate: ['20deg', '0deg', '20deg'] }}
              transition={{
                duration: 2, ease: 'linear', repeat: Infinity,
              }}
              style={{
                transformBox: 'fill-box',
                originX: 'right',
                originY: 'bottom',
              }}
            >
              <path
                className="biodiversity_slide_1__cls-5"
                d="M998.58 508h21.72v17.38h-21.72a5.92 5.92 0 01-5.92-5.92v-5.56a5.92 5.92 0 015.92-5.92z"
                transform="rotate(-131.29 1006.506 516.65)"
              />
              <motion.g
                animate={{ rotate: ['40deg', '0deg', '40deg'] }}
                transition={{
                  duration: 1, ease: 'linear', repeat: Infinity,
                }}
                style={{
                  transformBox: 'fill-box',
                  originX: 'right',
                  originY: 'bottom',
                }}
              >
                <path
                  className="biodiversity_slide_1__cls-5"
                  d="M991.85 494.89h15.84v3a6.48 6.48 0 01-6.48 6.48h-9.36v-9.52.04z"
                  transform="rotate(-106.48 999.77 499.651)"
                />
              </motion.g>
              <motion.g
                animate={{ rotate: ['-10deg', '20deg', '-10deg'] }}
                transition={{
                  duration: 1, ease: 'linear', repeat: Infinity,
                }}
                style={{
                  transformBox: 'fill-box',
                  originX: 'center',
                  originY: 'bottom',
                }}
              >
                <path
                  className="biodiversity_slide_1__cls-5"
                  d="M984.55 502.82h7a6.17 6.17 0 016.17 6.17v2.89h-13.17v-9.06z"
                  transform="rotate(-152.39 991.174 507.337)"
                />
              </motion.g>
            </motion.g>
            <path
              d="M1061.24 513.23l-12.4.9a1.16 1.16 0 00-.9 1.78c1 1.63 3.19 3.84 7.6 3.56s6.24-2.92 6.89-4.71a1.16 1.16 0 00-1.19-1.53z"
              fill="#cf6d5a"
            />
            <path id="bee_path" data-name="bee path" stroke="none" fill="none" d="M0,370.53C60.24,486,151.14,615,272,623.81c180,13.08,245.27-253.72,452.15-268.29,240.43-16.93,382.18,327.27,555.33,277.67,87.51-25.08,134.18-136.75,160.57-236.39" />
          </g>
          <g
            // filter="url(#shadow)"
            transform="translate(-300, -450)"
            id="biodiversity_slide_1__bee"
          >
            <animateMotion
              dur="20s"
              repeatCount="indefinite"
              path="M0,370.53C60.24,486,151.14,615,272,623.81c180,13.08,245.27-253.72,452.15-268.29,240.43-16.93,382.18,327.27,555.33,277.67,87.51-25.08,134.18-136.75,160.57-236.39"
            />
            <path
              className="biodiversity_slide_1__cls-5"
              d="M351.69 490.26l2.27-.56.02-6.63-2.27.56-.02 6.63zM347.37 491.32l2.27-.56.02-6.63-2.27.56-.02 6.63zM337.38 493.77l2.27-.56.02-6.63-2.28.56-.01 6.63zM333.06 494.83l2.27-.56.02-6.63-2.27.56-.02 6.63z"
            />
            <rect
              x={315.32}
              y={460.99}
              width={54.35}
              height={24.8}
              rx={10.08}
              transform="rotate(-14 353.103 478.812)"
              fill="#fbec6d"
            />
            <g clipPath="url(#biodiversity_slide_1__clip-path-3)">
              <path
                className="biodiversity_slide_1__cls-12"
                d="M377.64 475.17a10.6 10.6 0 01-17.9 0l-1.16.74a12 12 0 0020.22 0z"
              />
            </g>
            <g clipPath="url(#biodiversity_slide_1__clip-path-4)">
              <path
                d="M351.92 465.61a6.54 6.54 0 114.9 7.92 6.59 6.59 0 01-4.9-7.92"
                fill="#f1f2f2"
              />
              <path
                className="biodiversity_slide_1__cls-12"
                d="M358.29 467.06a6.54 6.54 0 114.91 7.94 6.6 6.6 0 01-4.91-7.92"
              />
            </g>
            <path
              className="biodiversity_slide_1__cls-15"
              transform="rotate(-14.73 339.924 474.04)"
              d="M330.93 461.66h18.03v24.8h-18.03z"
            />
            <motion.path
              animate={{
                scaleY: [0.5, 1, 0.5],
              }}
              transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
              style={{
                originY: 'bottom',
                transformBox: 'fill-box',
                x: 5,
              }}
              className="biodiversity_slide_1__cls-5 wing"
              d="M305 440.79h7a20.76 20.76 0 0120.76 20.76v4.79h-7A20.76 20.76 0 01305 445.57v-4.79.01z"
              transform="rotate(-14.73 318.897 453.546)"
            />
            <motion.path
              animate={{
                scaleY: [0.5, 0, 0.5],
              }}
              transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
              style={{
                originY: 'bottom',
                originX: 'left',
                transformBox: 'fill-box',
                x: -10,
                y: 5,
                rotate: '90deg',
              }}
              className="wing"
              d="M327.35 433.74h7a20.76 20.76 0 0120.76 20.76v4.79h-7a20.76 20.76 0 01-20.76-20.76v-4.79z"
              transform="rotate(75.27 341.252 446.521)"
              fill="#f5b1a6"
            />
          </g>
          <motion.g
            className="head"
            animate={{
              rotate: ['0deg', '20deg', '0deg', '0deg', '20deg', '0deg'],
            }}
            transition={{
              duration: 3, ease: 'easeOut', repeatDelay: 10, repeat: Infinity,
            }}
            style={{
              originY: 'center',
              originX: 'center',
              transformBox: 'fill-box',
            }}
          >
            <path
              className="biodiversity_slide_1__cls-17"
              d="M581.34 142.24v-6a2.51 2.51 0 00-2.51-2.51 2.51 2.51 0 00-2.51 2.51v6c0 16.79 11.06 25.87 23 27.24a2.51 2.51 0 002.76-2.51v-.24a2.48 2.48 0 00-2.2-2.47c-9.64-1.13-18.54-8.47-18.54-22.02z"
            />
            <path
              className="biodiversity_slide_1__cls-17"
              d="M574.86 153.85h6a2.51 2.51 0 012.5 2.51 2.5 2.5 0 01-2.5 2.51h-6c-16.8 0-25.88-11.05-27.24-23a2.51 2.51 0 012.5-2.76h.24a2.5 2.5 0 012.48 2.2c1.16 9.69 8.46 18.54 22.02 18.54z"
            />
            <path
              d="M598.3 154.53h1.43a6.21 6.21 0 016.21 6.21v13.17h-1.43a6.21 6.21 0 01-6.21-6.21v-13.17z"
              fill="#a5ccc6"
            />
            <path
              className="biodiversity_slide_1__cls-19"
              d="M584.63 169.7h25.2v11.87H588.2a6.61 6.61 0 01-6.61-6.61v-2.22a3 3 0 013.04-3.04z"
            />
            <path
              d="M595.66 148.41l5.1 14.89 14.25.83 2.06-10.13a.73.73 0 00-1.36-.49l-2.17 4.05-5.71-10.26a.73.73 0 00-1.37.35V155l-9.67-7.36a.73.73 0 00-1.13.77z"
              stroke="#eb826a"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.23}
              fill="#eb826a"
            />
            <path
              className="biodiversity_slide_1__cls-19"
              d="M600.08 163.14h9.56a5.94 5.94 0 015.94 5.94v7.19h-22v-6.65a6.48 6.48 0 016.5-6.48z"
            />
            <path
              className="biodiversity_slide_1__cls-19"
              d="M607.94 154.53h1.43a6.21 6.21 0 016.21 6.21v13.17h-1.43a6.21 6.21 0 01-6.21-6.21v-13.17z"
            />
            <path
              className="biodiversity_slide_1__cls-17"
              d="M632.83 142.24v-6a2.51 2.51 0 012.51-2.51 2.51 2.51 0 012.51 2.51v6c0 16.79-11.05 25.87-23 27.24a2.51 2.51 0 01-2.76-2.51v-.24a2.49 2.49 0 012.2-2.47c9.64-1.13 18.54-8.47 18.54-22.02z"
            />
            <path
              className="biodiversity_slide_1__cls-17"
              d="M639.32 153.85h-6a2.51 2.51 0 00-2.51 2.51 2.51 2.51 0 002.51 2.51h6c16.79 0 25.87-11.05 27.24-23a2.51 2.51 0 00-2.51-2.76h-.24a2.49 2.49 0 00-2.47 2.2c-1.13 9.69-8.47 18.54-22.02 18.54z"
            />
            <g
              clipPath="url(#biodiversity_slide_1__clip-path-5)"
              id="biodiversity_slide_1__eye-3"
              data-name="eye"
            >
              <circle
                className="biodiversity_slide_1__cls-7"
                cx={605.94}
                cy={169.7}
                r={4.54}
              />
              <circle cx={602.12} cy={168.85} r={4.54} />
            </g>
          </motion.g>
          <motion.g
            className="head"
            animate={{
              rotate: ['0deg', '-20deg', '0deg', '0deg', '-20deg', '0deg'],
            }}
            transition={{
              duration: 3, ease: 'easeOut', delay: 3, repeatDelay: 10, repeat: Infinity,
            }}
            style={{
              originY: 'center',
              originX: 'center',
              transformBox: 'fill-box',
            }}
          >
            <path
              className="biodiversity_slide_1__cls-22"
              d="M382.88 143.88l.33-6a2.69 2.69 0 012.65-2.51 2.34 2.34 0 012.37 2.51l-.34 6c-.95 16.79-12.51 25.87-24.54 27.24a2.35 2.35 0 01-2.62-2.51v-.24a2.68 2.68 0 012.35-2.47c9.71-1.13 19.03-8.47 19.8-22.02z"
            />
            <path
              className="biodiversity_slide_1__cls-22"
              d="M388.7 155.49h-6a2.68 2.68 0 00-2.64 2.51 2.34 2.34 0 002.36 2.51h6c16.79 0 26.5-11 28.54-23a2.36 2.36 0 00-2.35-2.77h-.24a2.67 2.67 0 00-2.6 2.2c-1.67 9.65-9.51 18.55-23.07 18.55z"
            />
            <path
              d="M358 167.65l-1-1.78c-2.89-5-1.06-11.56 4.08-14.73l1.82-1.14 1 1.79c2.89 5 1.06 11.56-4.08 14.72z"
              fill="#dd6f3e"
            />
            <path
              className="biodiversity_slide_1__cls-15"
              d="M350.62 168.85l-1.7-1.17a10.42 10.42 0 01-2.18-14.78l1.27-1.77 1.7 1.17a10.43 10.43 0 012.18 14.78z"
            />
            <path
              className="biodiversity_slide_1__cls-15"
              d="M353.88 163.14h7.68a7.43 7.43 0 016.83 4.31 3.72 3.72 0 003.38 2.25h5.66a3.48 3.48 0 013.54 3.56 8.71 8.71 0 01-8.6 8.31h-19.94a5.51 5.51 0 01-5.57-5.3h-.06v-.63l.33-5.94v-.23a6.79 6.79 0 016.75-6.33z"
            />
            <path
              className="biodiversity_slide_1__cls-22"
              d="M331.38 143.88l.34-6a2.34 2.34 0 00-2.37-2.51 2.67 2.67 0 00-2.64 2.51l-.34 6c-.95 16.79 9.59 25.87 21.47 27.24a2.68 2.68 0 002.9-2.51v-.24a2.32 2.32 0 00-2.07-2.47c-9.56-1.13-18.05-8.47-17.29-22.02z"
            />
            <path
              className="biodiversity_slide_1__cls-22"
              d="M324.25 155.49h6a2.35 2.35 0 012.36 2.51 2.68 2.68 0 01-2.65 2.51h-6c-16.79 0-25.25-11-25.94-23a2.68 2.68 0 012.66-2.77h.24a2.33 2.33 0 012.35 2.2c.58 9.65 7.42 18.55 20.98 18.55z"
            />
            <g
              clipPath="url(#biodiversity_slide_1__clip-path-6)"
              id="biodiversity_slide_1__eye-4"
              data-name="eye"
            >
              <ellipse
                className="biodiversity_slide_1__cls-7"
                cx={356.82}
                cy={169.7}
                rx={4.67}
                ry={4.41}
                transform="rotate(-44.19 356.852 169.7)"
              />
              <ellipse
                cx={360.69}
                cy={168.85}
                rx={4.67}
                ry={4.41}
                transform="rotate(-44.19 360.727 168.849)"
              />
            </g>
          </motion.g>
          <g filter="url(#shadow)" id="biodiversity_slide_1__FLOAT">
            <motion.g
              // animate={{
              //   x: ['-1000px', '180px'],
              // }}
              // transition={{ duration: 384, repeat: Infinity }}
              // style={{
              //   transformBox: 'fill-box',
              // }}
              id="biodiversity_slide_1__cloud"
            >
              <path className="biodiversity_slide_1__cls-4" d="M1197,217.92A29,29,0,0,1,1223.45,189a29,29,0,0,1,28.06-21.67h3.82a29,29,0,0,1,28,21.56h8.31a29,29,0,0,1,29,29c0,.5,0,1,0,1.49s0,1,0,1.53a28.52,28.52,0,0,1-1.43,9,4.55,4.55,0,0,1-4.32,3.1H1202.76a4.55,4.55,0,0,1-4.31-3.1,28.52,28.52,0,0,1-1.43-9c0-.51,0-1,0-1.51S1197,218.42,1197,217.92Z" />
            </motion.g>
            <motion.g
              // animate={{
              //   x: ['-700px', '180px'],
              // }}
              // transition={{ duration: 384, repeat: Infinity }}
              // style={{
              //   transformBox: 'fill-box',
              // }}
              id="biodiversity_slide_1__cloud-2"
              data-name="cloud"
            >
              <path className="biodiversity_slide_1__cls-4" d="M921.58,142.11a20.75,20.75,0,0,1,18.88-20.66A20.75,20.75,0,0,1,960.52,106h2.72a20.74,20.74,0,0,1,20,15.41h5.94A20.76,20.76,0,0,1,1010,142.11c0,.36,0,.71,0,1.06s0,.73,0,1.1a20.39,20.39,0,0,1-1,6.44,3.24,3.24,0,0,1-3.08,2.21H925.68a3.24,3.24,0,0,1-3.07-2.21,20.11,20.11,0,0,1-1-6.44q0-.54,0-1.08C921.59,142.84,921.58,142.47,921.58,142.11Z" />
            </motion.g>
            <motion.g
              // animate={{
              //   x: ['-1300px', '180px'],
              // }}
              // transition={{ duration: 384, repeat: Infinity }}
              // style={{
              //   transformBox: 'fill-box',
              // }}
              id="biodiversity_slide_1__cloud-3"
              data-name="cloud"
            >
              <path className="biodiversity_slide_1__cls-4" d="M641.12,494.31A20.75,20.75,0,0,1,660,473.65a20.75,20.75,0,0,1,20.06-15.48h2.72a20.74,20.74,0,0,1,20,15.41h5.94a20.76,20.76,0,0,1,20.74,20.73c0,.36,0,.72,0,1.07s0,.72,0,1.09a20.39,20.39,0,0,1-1,6.44,3.24,3.24,0,0,1-3.08,2.21H645.22a3.24,3.24,0,0,1-3.07-2.21,20.11,20.11,0,0,1-1-6.44c0-.36,0-.72,0-1.07S641.12,494.67,641.12,494.31Z" />
            </motion.g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AnimationSlide1;

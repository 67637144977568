import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const LastSlideImage = () => (
  <StaticImage
    style={{
      width: '100%',
      height: '100%',
      objectFit: 'cover',

      // You can set a maximum height for the image, if you wish.
    // maxHeight: 600,
    }}
    layout="fullWidth"
  // You can optionally force an aspect ratio for the generated image
    // aspectRatio={3 / 1}
   // This is a presentational image, so the alt should be an empty string
    alt=""
    src="../../images/biodiversity_last_slide_image.png"
    formats={['auto']}
  />
);

export default LastSlideImage;

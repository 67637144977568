import * as React from 'react';
import { motion } from 'framer-motion';

function AnimationSlide3(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <filter id="shadow-slide-3" y="-40%" height="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="4" floodOpacity="0.5" />
        </filter>
        <clipPath id="biodiversity_slide_3_prefix__clip-path">
          <path
            id="biodiversity_slide_3_prefix__eyes"
            className="biodiversity_slide_3_prefix__cls-1"
            d="M1080.27 219.67a4.14 4.14 0 01-4.22 3.4 3.19 3.19 0 01-2.93-3.85 4.14 4.14 0 014.18-3.44 3.21 3.21 0 012.97 3.89z"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_3_prefix__clip-path-2">
          <path
            id="biodiversity_slide_3_prefix__eyes-2"
            data-name="eyes"
            className="biodiversity_slide_3_prefix__cls-1"
            d="M1170.81 240.7a4.15 4.15 0 01-4.22 3.41 3.2 3.2 0 01-2.93-3.86 4.15 4.15 0 014.18-3.44 3.21 3.21 0 012.97 3.89z"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_3_prefix__clip-path-3">
          <path
            id="biodiversity_slide_3_prefix__eyes-3"
            data-name="eyes"
            className="biodiversity_slide_3_prefix__cls-1"
            d="M1033.4 295.71a2.32 2.32 0 01-2.37 1.91 1.8 1.8 0 01-1.65-2.16 2.32 2.32 0 012.35-1.93 1.79 1.79 0 011.67 2.18z"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_3_prefix__clip-path-4">
          <path
            d="M373.89 312.28c0-.2 0-.41-.07-.62l-1.54-9.66a1.92 1.92 0 00-2.36-1.56l-14 3.41a22.3 22.3 0 00-16.58 19.45 21.15 21.15 0 00.2 5.51.58.58 0 00.25.4l.14.07a.5.5 0 00.32 0l24.76-5.11a11.34 11.34 0 008.88-11.89z"
            fill="none"
          />
        </clipPath>
        <style>
          {
            '.biodiversity_slide_3_prefix__cls-1{fill:#fff}.biodiversity_slide_3_prefix__cls-10,.biodiversity_slide_3_prefix__cls-22{fill:none}.biodiversity_slide_3_prefix__cls-4{fill:#fbf292}.biodiversity_slide_3_prefix__cls-6{fill:#cf6d5a}.biodiversity_slide_3_prefix__cls-7{fill:#dd6f3e}.biodiversity_slide_3_prefix__cls-9{fill:#eefff7}.biodiversity_slide_3_prefix__cls-10{stroke:#fbf292;stroke-width:5.47px}.biodiversity_slide_3_prefix__cls-10,.biodiversity_slide_3_prefix__cls-22{stroke-linecap:round;stroke-linejoin:round}.biodiversity_slide_3_prefix__cls-11{fill:#eb826a}.biodiversity_slide_3_prefix__cls-12{fill:#f5b1a6}.biodiversity_slide_3_prefix__cls-14{fill:#b27449}.biodiversity_slide_3_prefix__cls-17{fill:#47a9c4}.biodiversity_slide_3_prefix__cls-18{fill:#c6edf5}.biodiversity_slide_3_prefix__cls-19{fill:#fcf568}.biodiversity_slide_3_prefix__cls-22{stroke:#333;stroke-width:1.07px}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="biodiversity_slide_3_prefix__Layer_1" data-name="Layer 1">
          <g
            // filter="url(#shadow-slide-3)"
            id="biodiversity_slide_3_prefix__heat_lines_GRP"
            data-name="heat lines GRP"
          >
            <motion.path
              animate={{
                pathLength: [0, 1],
              }}
              transition={{ duration: 5, ease: 'easeOut', repeat: Infinity }}
              id="biodiversity_slide_3_prefix__heat_line"
              data-name="heat line"
              className="biodiversity_slide_3_prefix__cls-4"
              d="M874.2 339.91c1.72-1.43 4.07-3.38 4.07-7.09s-2.35-5.67-4.07-7.1-2.67-2.29-2.67-4.09.91-2.64 2.67-4.09 4.07-3.38 4.07-7.09-2.35-5.67-4.07-7.1-2.67-2.29-2.67-4.09a2 2 0 10-3.9 0c0 3.71 2.36 5.67 4.08 7.1s2.67 2.29 2.67 4.09-.92 2.64-2.67 4.09-4.08 3.38-4.08 7.09 2.36 5.67 4.08 7.1 2.67 2.29 2.67 4.09-.92 2.64-2.67 4.09-4.08 3.38-4.08 7.09 2.36 5.67 4.08 7.1 2.67 2.29 2.67 4.09-.92 2.64-2.67 4.09-4.08 3.38-4.08 7.09 2.36 5.67 4.08 7.09 2.67 2.3 2.67 4.1-.92 2.63-2.67 4.09-4.08 3.38-4.08 7.09a2 2 0 103.9 0c0-1.8.91-2.64 2.67-4.09s4.07-3.38 4.07-7.09-2.35-5.67-4.07-7.1-2.67-2.29-2.67-4.09.91-2.64 2.67-4.09 4.07-3.38 4.07-7.09-2.35-5.67-4.07-7.1-2.67-2.29-2.67-4.09.91-2.64 2.67-4.09z"
            />
            <motion.path
              animate={{
                pathLength: [1, 0],
              }}
              transition={{ duration: 5, ease: 'easeOut', repeat: Infinity }}
              id="biodiversity_slide_3_prefix__heat_line-2"
              data-name="heat line"
              className="biodiversity_slide_3_prefix__cls-4"
              d="M834.28 226c1.72-1.42 4.08-3.38 4.08-7.09s-2.36-5.67-4.08-7.09-2.67-2.3-2.67-4.09a2 2 0 00-3.9 0c0 3.71 2.36 5.66 4.08 7.09s2.67 2.29 2.67 4.09-.91 2.64-2.67 4.1-4.08 3.38-4.08 7.09 2.36 5.66 4.08 7.09 2.67 2.29 2.67 4.09-.91 2.64-2.67 4.09-4.08 3.38-4.08 7.1 2.36 5.66 4.08 7.09 2.67 2.29 2.67 4.09-.91 2.64-2.67 4.09-4.08 3.38-4.08 7.09 2.36 5.67 4.08 7.1 2.67 2.29 2.67 4.09-.91 2.64-2.67 4.09-4.08 3.38-4.08 7.09a1.95 1.95 0 003.9 0c0-1.79.92-2.63 2.67-4.09s4.08-3.38 4.08-7.09-2.36-5.67-4.08-7.09-2.67-2.3-2.67-4.1.92-2.63 2.67-4.09 4.08-3.38 4.08-7.09-2.36-5.67-4.08-7.09-2.67-2.3-2.67-4.09.92-2.64 2.67-4.1 4.08-3.38 4.08-7.09-2.36-5.66-4.08-7.09-2.67-2.3-2.67-4.09.92-2.69 2.67-4.1z"
            />
          </g>

          {/* --------------------------- clouds -------------------------------- */}
          <g id="biodiversity_slide_3_prefix__FLOATS">
            <g id="biodiversity_slide_3_prefix__RAiN_CLOUDS-2" data-name="RAiN CLOUDS">
              <path
                style={{
                  fill: 'white',
                }}
                // filter="url(#shadow-slide-3)"
                className="biodiversity_slide_3_prefix__cls-18"
                d="M958 229.41a31.23 31.23 0 0128.43-31.11 31.23 31.23 0 0130.2-23.31h4.11a31.22 31.22 0 0130.15 23.19h9a31.27 31.27 0 0131.23 31.23v3.25a30.44 30.44 0 01-1.55 9.69 4.87 4.87 0 01-4.64 3.34H964.16a4.88 4.88 0 01-4.64-3.34 30.44 30.44 0 01-1.54-9.69v-1.62c.02-.54.02-1.04.02-1.63z"
              />
              <g id="biodiversity_slide_3_prefix__raincloud_1-2" data-name="raincloud 1">
                <motion.path
                  animate={{
                    x: ['0px', '50px'],
                    y: ['0px', '130px'],
                    opacity: [0, 1, 0],
                  }}
                  transition={{ duration: 3, repeat: Infinity }}
                  style={{
                    transformBox: 'fill-box',
                  }}
                  id="biodiversity_slide_3_prefix__vandr\xE5be-5"
                  data-name="vandr\xE5be"
                  className="biodiversity_slide_3_prefix__cls-17"
                  d="M1041.63 260.61a.49.49 0 00-.71.2c-.77 1.69-3.1 7.38-1.82 12.33 1.36 5.2 5 5.16 7.25 4.49s5.2-3.2 3.57-7.73c-1.72-4.81-6.73-8.3-8.29-9.29z"
                />
                <motion.path
                  animate={{
                    x: ['0px', '50px'],
                    y: ['0px', '130px'],
                    opacity: [0, 1, 0],
                  }}
                  transition={{ duration: 3, delay: 0.5, repeat: Infinity }}
                  style={{
                    transformBox: 'fill-box',
                  }}
                  id="biodiversity_slide_3_prefix__vandr\xE5be-7"
                  data-name="vandr\xE5be"
                  className="biodiversity_slide_3_prefix__cls-17"
                  d="M1013.83 260.61a.49.49 0 00-.71.2c-.77 1.69-3.1 7.38-1.81 12.33 1.35 5.2 5 5.16 7.24 4.49s5.2-3.2 3.58-7.73c-1.73-4.81-6.74-8.3-8.3-9.29z"
                />
                <motion.path
                  animate={{
                    x: ['0px', '50px'],
                    y: ['0px', '130px'],
                    opacity: [0, 1, 0],
                  }}
                  transition={{ duration: 3, delay: 1.5, repeat: Infinity }}
                  style={{
                    transformBox: 'fill-box',
                  }}
                  id="biodiversity_slide_3_prefix__vandr\xE5be-7"
                  data-name="vandr\xE5be"
                  className="biodiversity_slide_3_prefix__cls-17"
                  d="M1013.83 260.61a.49.49 0 00-.71.2c-.77 1.69-3.1 7.38-1.81 12.33 1.35 5.2 5 5.16 7.24 4.49s5.2-3.2 3.58-7.73c-1.73-4.81-6.74-8.3-8.3-9.29z"
                />
                <motion.path
                  animate={{
                    x: ['0px', '50px'],
                    y: ['0px', '130px'],
                    opacity: [0, 1, 0],
                  }}
                  transition={{ duration: 3, delay: 1, repeat: Infinity }}
                  style={{
                    transformBox: 'fill-box',
                  }}
                  id="biodiversity_slide_3_prefix__vandr\xE5be-5"
                  data-name="vandr\xE5be"
                  className="biodiversity_slide_3_prefix__cls-17"
                  d="M1041.63 260.61a.49.49 0 00-.71.2c-.77 1.69-3.1 7.38-1.82 12.33 1.36 5.2 5 5.16 7.25 4.49s5.2-3.2 3.57-7.73c-1.72-4.81-6.73-8.3-8.29-9.29z"
                />
              </g>
            </g>
            <motion.g
              id="biodiversity_slide_3_prefix__RAiN_CLOUDS"
              data-name="RAiN CLOUDS"
            >
              <g
                id="biodiversity_slide_3_prefix__raincloud_1"
                data-name="raincloud 1"
              >
                <motion.path
                  animate={{
                    x: ['20px', '50px'],
                    y: ['0px', '130px'],
                    opacity: [0, 1, 0],
                  }}
                  transition={{ duration: 3, repeat: Infinity }}
                  style={{
                    transformBox: 'fill-box',
                  }}
                  id="biodiversity_slide_3_prefix__vandr\xE5be"
                  className="biodiversity_slide_3_prefix__cls-17"
                  d="M966.32 380.43a.48.48 0 00-.7.21c-.78 1.69-3.1 7.38-1.82 12.32 1.35 5.2 5 5.16 7.24 4.49s5.21-3.2 3.58-7.73c-1.72-4.81-6.74-8.3-8.3-9.29z"
                />
                <motion.path
                  animate={{
                    x: ['20px', '50px'],
                    y: ['0px', '130px'],
                    opacity: [0, 1, 0],
                  }}
                  transition={{ duration: 3, delay: 2, repeat: Infinity }}
                  style={{
                    transformBox: 'fill-box',
                  }}
                  id="biodiversity_slide_3_prefix__vandr\xE5be"
                  className="biodiversity_slide_3_prefix__cls-17"
                  d="M966.32 380.43a.48.48 0 00-.7.21c-.78 1.69-3.1 7.38-1.82 12.32 1.35 5.2 5 5.16 7.24 4.49s5.21-3.2 3.58-7.73c-1.72-4.81-6.74-8.3-8.3-9.29z"
                />
                <motion.path
                  animate={{
                    x: ['20px', '50px'],
                    y: ['0px', '130px'],
                    opacity: [0, 1, 0],
                  }}
                  transition={{ duration: 3, delay: 2.5, repeat: Infinity }}
                  style={{
                    transformBox: 'fill-box',
                  }}
                  id="biodiversity_slide_3_prefix__vandr\xE5be-3"
                  data-name="vandr\xE5be"
                  className="biodiversity_slide_3_prefix__cls-17"
                  d="M938.53 380.43a.49.49 0 00-.71.21c-.77 1.69-3.1 7.38-1.82 12.32 1.36 5.2 5 5.16 7.25 4.49s5.2-3.2 3.57-7.73c-1.72-4.81-6.73-8.3-8.29-9.29z"
                />
                <motion.path
                  animate={{
                    x: ['20px', '50px'],
                    y: ['0px', '130px'],
                    opacity: [0, 1, 0],
                  }}
                  transition={{ duration: 3, delay: 1.5, repeat: Infinity }}
                  style={{
                    transformBox: 'fill-box',
                  }}
                  id="biodiversity_slide_3_prefix__vandr\xE5be-3"
                  data-name="vandr\xE5be"
                  className="biodiversity_slide_3_prefix__cls-17"
                  d="M938.53 380.43a.49.49 0 00-.71.21c-.77 1.69-3.1 7.38-1.82 12.32 1.36 5.2 5 5.16 7.25 4.49s5.2-3.2 3.57-7.73c-1.72-4.81-6.73-8.3-8.29-9.29z"
                />
                <g
                  id
                  // filter="url(#shadow-slide-3)"
                >
                  <path
                    style={{
                      fill: 'white',
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="biodiversity_slide_3_prefix__cls-18"
                    d="M927,353.65A20.73,20.73,0,0,1,945.88,333a20.75,20.75,0,0,1,20-15.48h2.73a20.74,20.74,0,0,1,20,15.4h5.94a20.76,20.76,0,0,1,20.73,20.74c0,.35,0,.71,0,1.06s0,.73,0,1.1a20.27,20.27,0,0,1-1,6.43,3.24,3.24,0,0,1-3.09,2.22H931.1a3.26,3.26,0,0,1-3.08-2.22,20.27,20.27,0,0,1-1-6.43c0-.36,0-.72,0-1.08S927,354,927,353.65Z"
                  />
                </g>
              </g>
            </motion.g>
            <g
              // filter="url(#shadow-slide-3)"
              id="biodiversity_slide_3_prefix__Helicopter"
            >
              <rect
                className="biodiversity_slide_3_prefix__cls-6"
                x={507.26}
                y={213.52}
                width={14.89}
                height={1.59}
                rx={0.52}
                transform="rotate(-75.03 514.705 214.313)"
              />
              <rect
                className="biodiversity_slide_3_prefix__cls-6"
                x={534.7}
                y={204.61}
                width={1.59}
                height={14.89}
                rx={0.52}
                transform="rotate(-27.32 535.592 212.07)"
              />
              <path
                className="biodiversity_slide_3_prefix__cls-6"
                transform="rotate(-6.18 526.35 169.888)"
                d="M525.1 163.9h3.05v12.15h-3.05z"
              />
              <path
                className="biodiversity_slide_3_prefix__cls-7"
                d="M513.62 213.45l23-2.5a10.34 10.34 0 009.18-11.41l-2.26-20.87-33.34 3.61a10.34 10.34 0 00-9.18 11.41l1.14 10.57a10.37 10.37 0 0011.46 9.19z"
              />
              <path
                className="biodiversity_slide_3_prefix__cls-7"
                d="M603.85 153.41a7.06 7.06 0 00-4.87 7.65l1.28 11.81a3.52 3.52 0 01-3.12 3.88l-54.3 5.87.65 6.06 64.73-7a6.65 6.65 0 005.9-7.32l-1.64-15.12a6.81 6.81 0 00-8.63-5.83z"
              />
              <rect
                className="biodiversity_slide_3_prefix__cls-7"
                x={517.59}
                y={170.47}
                width={34.4}
                height={14.06}
                rx={4.65}
                transform="rotate(-6.18 534.583 177.392)"
              />
              <rect
                className="biodiversity_slide_3_prefix__cls-6"
                x={502.06}
                y={217.05}
                width={56.53}
                height={2.6}
                rx={0.86}
                transform="rotate(-6.18 530.052 218.299)"
              />
              <path
                d="M515.46 183.45a13.76 13.76 0 00-12.2 15.17.74.74 0 00.82.66l8.89-1a9.11 9.11 0 008.08-10l-.49-4.46a.92.92 0 00-1-.82z"
                fill="#f4cb5e"
              />
              <motion.g
                animate={{
                  scale: [-0.5, 1, -0.5],
                }}
                transition={{ duration: 0.1, repeat: Infinity }}
                style={{
                  transformBox: 'fill-box',
                  originX: 'center',
                  originY: 'center',
                }}
              >
                <rect
                  className="biodiversity_slide_3_prefix__cls-9"
                  x={475.18}
                  y={163.09}
                  width={101.76}
                  height={3.47}
                  rx={1.74}
                />
              </motion.g>
              <g id="biodiversity_slide_3_prefix__Noise_lines" data-name="Noise lines">
                <motion.path
                  animate={{
                    pathLength: [0, 1],
                  }}
                  transition={{ duration: 2, repeat: Infinity }}
                  className="biodiversity_slide_3_prefix__cls-10"
                  d="M457.18 180.73l-32.5 16.76M456.33 170.63l-34.83-11.14M462.88 162.89l-16.76-32.51"
                />

              </g>
            </g>

            {/* ----------------------------- birds ------------------------------- */}
            <motion.g
              // filter="url(#shadow-slide-3)"
              animate={{
                y: ['-10px', '-40px', '-10px'],
              }}
              transition={{ duration: 2, ease: 'easeOut', repeat: Infinity }}
              style={{
                originY: 'bottom',
                transformBox: 'fill-box',
                x: -20,
                y: -40,
              }}
              id="biodiversity_slide_3_prefix__bird-8"
              data-name="bird"
            >
              <path
                className="biodiversity_slide_3_prefix__cls-6"
                d="M345.48 331.17l4.06-6.52a.58.58 0 01.81-.19l.35.21a.58.58 0 01.19.82l-4.06 6.51a.57.57 0 01-.37.27.59.59 0 01-.32 0 .38.38 0 01-.13-.06l-.34-.21a.61.61 0 01-.19-.83zM352.88 324.37a.6.6 0 01.82-.19l.34.21a.6.6 0 01.27.37.58.58 0 01-.07.45l-4.13 6.57a.61.61 0 01-.69.25l-.13-.06-.34-.21a.62.62 0 01-.19-.83z"
              />
              <rect
                x={373.28}
                y={301.37}
                width={2.94}
                height={7.88}
                rx={1.24}
                transform="rotate(-74.16 374.744 305.31)"
                fill="#f4cb5d"
              />
              <rect
                className="biodiversity_slide_3_prefix__cls-19"
                x={370.28}
                y={300.69}
                width={7.88}
                height={2.94}
                rx={1.24}
                transform="rotate(-35.22 374.153 302.143)"
              />
              <motion.path
                animate={{
                  scaleY: [0.7, 1, 0.7],
                }}
                transition={{ duration: 2, ease: 'easeOut', repeat: Infinity }}
                style={{
                  originY: 'bottom',
                  transformBox: 'fill-box',
                }}
                className="biodiversity_slide_3_prefix__cls-6 wing"
                d="M345.09 305.33a29.35 29.35 0 017.91-20.91.6.6 0 01.51-.17.59.59 0 01.44.32l14.21 28a.58.58 0 010 .48.6.6 0 01-.34.34l-17.59 6.85a.42.42 0 01-.16 0 .59.59 0 01-.56-.28l-.58-.92a27.09 27.09 0 01-3.84-13.71z"
              />
              <g clipPath="url(#biodiversity_slide_3_prefix__clip-path-4)">
                <path
                  className="biodiversity_slide_3_prefix__cls-11"
                  d="M373.89 312.28c0-.2 0-.41-.07-.62l-1.54-9.66a1.92 1.92 0 00-2.36-1.56l-14 3.41a22.3 22.3 0 00-16.58 19.45 21.15 21.15 0 00.2 5.51.58.58 0 00.25.4l.14.07a.5.5 0 00.32 0l24.76-5.11a11.34 11.34 0 008.88-11.89z"
                />
                <path
                  className="biodiversity_slide_3_prefix__cls-12"
                  d="M378.72 310.16a.8.8 0 00-.27-.53.6.6 0 00-.53-.13l-18.62 4.59c-5.54 1.37-9.3 7.54-8.4 13.77a.74.74 0 00.28.49.51.51 0 00.16.09.66.66 0 00.34 0l17.39-4c5.93-1.28 10.16-7.59 9.65-14.28z"
                />
              </g>
              <motion.path
                animate={{
                  scaleY: [0.7, 1, 0.7],
                }}
                transition={{ duration: 2, ease: 'easeOut', repeat: Infinity }}
                style={{
                  originY: 'bottom',
                  transformBox: 'fill-box',
                }}
                className="biodiversity_slide_3_prefix__cls-11 wing"
                d="M336.83 290.37a.56.56 0 01.4-.35.6.6 0 01.53.12l23.05 19.17a.62.62 0 01.22.43.61.61 0 01-.18.45l-13.79 13.42a.52.52 0 01-.31.16.54.54 0 01-.44-.1l-.86-.61a24.76 24.76 0 01-8.45-10.84 28.9 28.9 0 01-.17-21.85z"
              />
              <path
                className="biodiversity_slide_3_prefix__cls-22"
                d="M369.84 302.59l-3.13 4.28M370.41 306.29l-4.27-3.12"
              />
              <motion.g
                animate={{
                  opacity: [0, 1, 0],
                  // scaleX: [0, 1, 0],
                }}
                transition={{ duration: 2, ease: 'easeOut', repeat: Infinity }}
                style={{
                  transformBox: 'fill-box',
                }}
                id="biodiversity_slide_3_prefix__host"
              >
                <path
                  className="biodiversity_slide_3_prefix__cls-9"
                  d="M380 304.29l8.83.73a.45.45 0 00.53-.39v-5.44c0-.31-.42-.5-.74-.33l-8.83 4.7c-.42.2-.28.69.21.73z"
                />
                <circle
                  className="biodiversity_slide_3_prefix__cls-9"
                  cx={393.86}
                  cy={297.09}
                  r={7.09}
                />
                <circle
                  className="biodiversity_slide_3_prefix__cls-9"
                  cx={399.09}
                  cy={303.17}
                  r={7.09}
                />
                <circle
                  className="biodiversity_slide_3_prefix__cls-9"
                  cx={392}
                  cy={305.88}
                  r={7.09}
                />
              </motion.g>
            </motion.g>
            <motion.g
              // filter="url(#shadow-slide-3)"
              id="biodiversity_slide_3_prefix__bird-5"
              data-name="bird"
              animate={{
                x: ['-1280px', '380px'],
              }}
              transition={{ duration: 38, ease: 'easeOut', repeat: Infinity }}
              style={{
                transformBox: 'fill-box',
              }}
            >
              <path
                className="biodiversity_slide_3_prefix__cls-6"
                d="M1052.87 244.93l4.52-6.55a.62.62 0 01.86-.15l.34.23a.63.63 0 01.26.4.58.58 0 01-.1.46l-4.53 6.55a.63.63 0 01-.4.25.62.62 0 01-.33 0 .42.42 0 01-.13-.07l-.34-.23a.61.61 0 01-.15-.89z"
              />
              <path
                className="biodiversity_slide_3_prefix__cls-6"
                d="M1060.87 238.27a.61.61 0 01.85-.16l.35.24a.59.59 0 01.26.39.65.65 0 01-.1.47l-4.61 6.58a.62.62 0 01-.72.23l-.13-.07-.34-.24a.61.61 0 01-.26-.4.62.62 0 01.1-.46z"
              />
              <motion.path
                animate={{
                  scaleY: [0.5, 1, 0.5],
                }}
                transition={{ duration: 2, ease: 'easeOut', repeat: Infinity }}
                style={{
                  originY: 'bottom',
                  transformBox: 'fill-box',
                }}
                className="biodiversity_slide_3_prefix__cls-6"
                d="M1058.05 212.35c3.5-7.41 9.85-13.3 17-15.75a.57.57 0 01.55.08.59.59 0 01.26.5l.28 32.54a.6.6 0 01-.67.62L1056 228.6a.47.47 0 01-.16 0 .6.6 0 01-.39-.51l-.11-1.13a28.11 28.11 0 012.71-14.61z"
              />
              <path
                className="biodiversity_slide_3_prefix__cls-11"
                d="M1064.83 217.7l16.51-3.57a.61.61 0 01.49.1.59.59 0 01.25.44l1.1 11.52v.64a12.17 12.17 0 01-9.89 12.14l-26.28 4.7a.62.62 0 01-.33 0l-.14-.08a.6.6 0 01-.25-.42 22.21 22.21 0 01.07-5.7 24 24 0 0118.47-19.77z"
              />
              <path
                className="biodiversity_slide_3_prefix__cls-12"
                d="M1064.38 229.79l18.11-3.54a.6.6 0 01.5.12.64.64 0 01.24.46 12.17 12.17 0 01-9.89 12.14l-16.9 3a.61.61 0 01-.33 0l-.15-.08a.58.58 0 01-.24-.43 10.9 10.9 0 018.66-11.67z"
              />
              <motion.path
                animate={{
                  scaleY: [0.5, 1, 0.5],
                }}
                transition={{ duration: 2, ease: 'easeOut', repeat: Infinity }}
                style={{
                  originY: 'bottom',
                  transformBox: 'fill-box',
                }}
                className="biodiversity_slide_3_prefix__cls-11 wing"
                d="M1061.72 196.17a.64.64 0 01.55-.1.6.6 0 01.4.39l10.33 29.29a.63.63 0 01-.43.81l-19.39 4.63a.61.61 0 01-.71-.35l-.44-1a25.78 25.78 0 01-1.77-14.14 30 30 0 0111.46-19.53z"
              />
              <g id="biodiversity_slide_3_prefix__general_eye_shape-4" data-name="general eye shape">
                <g id="biodiversity_slide_3_prefix__EYE-4" data-name="EYE">
                  <path
                    id="biodiversity_slide_3_prefix__eyes-4"
                    data-name="eyes"
                    className="biodiversity_slide_3_prefix__cls-1"
                    d="M1080.27 219.67a4.14 4.14 0 01-4.22 3.4 3.19 3.19 0 01-2.93-3.85 4.14 4.14 0 014.18-3.44 3.21 3.21 0 012.97 3.89z"
                  />
                  <g clipPath="url(#biodiversity_slide_3_prefix__clip-path)">
                    <path
                      id="biodiversity_slide_3_prefix__eyes-5"
                      data-name="eyes"
                      d="M1082.58 218.77a4.19 4.19 0 01-4.25 3.43 3.22 3.22 0 01-2.95-3.88 4.19 4.19 0 014.22-3.46 3.21 3.21 0 012.98 3.91z"
                    />
                  </g>
                </g>
              </g>
              <path
                id="biodiversity_slide_3_prefix__n\xE6b"
                className="biodiversity_slide_3_prefix__cls-14"
                d="M1088.37 217.88l-5.88 1.23-.26-2.77 5.89-1.25a1.14 1.14 0 011.44 1v.23a1.52 1.52 0 01-1.19 1.56z"
              />
            </motion.g>
            <motion.g
              // filter="url(#shadow-slide-3)"
              animate={{
                x: ['-1280px', '380px'],
              }}
              transition={{ duration: 34, repeat: Infinity }}
              style={{
                transformBox: 'fill-box',
              }}
              id="biodiversity_slide_3_prefix__bird-6"
              data-name="bird"
            >
              <path
                className="biodiversity_slide_3_prefix__cls-6"
                d="M1143.41 266l4.52-6.55a.62.62 0 01.86-.16l.34.24a.62.62 0 01.16.86l-4.53 6.54a.61.61 0 01-.4.26.62.62 0 01-.33 0l-.13-.07-.34-.24a.6.6 0 01-.15-.88z"
              />
              <path
                className="biodiversity_slide_3_prefix__cls-6"
                d="M1151.41 259.3a.62.62 0 01.85-.16l.35.24a.63.63 0 01.26.4.66.66 0 01-.1.47l-4.61 6.58a.6.6 0 01-.72.22l-.13-.07-.34-.23a.63.63 0 01-.26-.4.62.62 0 01.1-.46z"
              />
              <motion.path
                animate={{
                  scaleY: [0.5, 1, 0.5],
                }}
                transition={{ duration: 2, ease: 'easeOut', repeat: Infinity }}
                style={{
                  originY: 'bottom',
                  transformBox: 'fill-box',
                }}
                className="biodiversity_slide_3_prefix__cls-6 wing"
                d="M1148.59 233.39a30.46 30.46 0 0117-15.76.63.63 0 01.55.08.6.6 0 01.26.5l.28 32.55a.62.62 0 01-.19.46.6.6 0 01-.48.16l-19.48-1.74h-.16a.6.6 0 01-.39-.52l-.11-1.12a28.09 28.09 0 012.72-14.61z"
              />
              <path
                className="biodiversity_slide_3_prefix__cls-11"
                d="M1155.37 238.74l16.51-3.57a.61.61 0 01.49.1.56.56 0 01.25.44l1.1 11.52v.64a12.17 12.17 0 01-9.89 12.13l-26.28 4.71a.62.62 0 01-.33 0l-.14-.08a.62.62 0 01-.25-.43 22.14 22.14 0 01.07-5.69 24 24 0 0118.47-19.77z"
              />
              <path
                className="biodiversity_slide_3_prefix__cls-12"
                d="M1154.92 250.82l18.11-3.54a.63.63 0 01.5.12.68.68 0 01.24.47 12.17 12.17 0 01-9.89 12.13l-16.9 3a.61.61 0 01-.33 0l-.15-.08a.59.59 0 01-.24-.43 10.91 10.91 0 018.66-11.67z"
              />
              <motion.path
                animate={{
                  scaleY: [0.5, 1, 0.5],
                }}
                transition={{ duration: 2, ease: 'easeOut', repeat: Infinity }}
                style={{
                  originY: 'bottom',
                  transformBox: 'fill-box',
                }}
                className="biodiversity_slide_3_prefix__cls-11"
                d="M1152.26 217.2a.63.63 0 01.55-.09.61.61 0 01.4.38l10.35 29.3a.59.59 0 010 .5.59.59 0 01-.39.3l-19.39 4.64a.55.55 0 01-.36 0 .61.61 0 01-.35-.32l-.44-1a25.76 25.76 0 01-1.77-14.14 30 30 0 0111.4-19.57z"
              />
              <g id="biodiversity_slide_3_prefix__general_eye_shape-5" data-name="general eye shape">
                <g id="biodiversity_slide_3_prefix__EYE-5" data-name="EYE">
                  <path
                    id="biodiversity_slide_3_prefix__eyes-6"
                    data-name="eyes"
                    className="biodiversity_slide_3_prefix__cls-1"
                    d="M1170.81 240.7a4.15 4.15 0 01-4.22 3.41 3.2 3.2 0 01-2.93-3.86 4.15 4.15 0 014.18-3.44 3.21 3.21 0 012.97 3.89z"
                  />
                  <g clipPath="url(#biodiversity_slide_3_prefix__clip-path-2)">
                    <path
                      id="biodiversity_slide_3_prefix__eyes-7"
                      data-name="eyes"
                      d="M1173.12 239.8a4.19 4.19 0 01-4.25 3.43 3.2 3.2 0 01-2.95-3.87 4.18 4.18 0 014.22-3.46 3.21 3.21 0 012.98 3.9z"
                    />
                  </g>
                </g>
              </g>
              <path
                id="biodiversity_slide_3_prefix__n\xE6b-2"
                data-name="n\xE6b"
                className="biodiversity_slide_3_prefix__cls-14"
                d="M1178.91 238.92l-5.88 1.23-.26-2.77 5.89-1.25a1.14 1.14 0 011.44 1v.23a1.52 1.52 0 01-1.19 1.56z"
              />
            </motion.g>
            <motion.g
              // filter="url(#shadow-slide-3)"
              animate={{
                x: ['-1280px', '380px'],
              }}
              transition={{ duration: 34, repeat: Infinity }}
              style={{
                transformBox: 'fill-box',
              }}
              id="biodiversity_slide_3_prefix__bird-7"
              data-name="bird"
            >
              <path
                className="biodiversity_slide_3_prefix__cls-6"
                d="M1018 309.89l2.54-3.67a.34.34 0 01.48-.09l.2.13a.33.33 0 01.14.23.34.34 0 010 .26l-2.55 3.67a.3.3 0 01-.22.14.31.31 0 01-.19 0h-.07l-.19-.13a.35.35 0 01-.14-.54z"
              />
              <path
                className="biodiversity_slide_3_prefix__cls-6"
                d="M1022.51 306.15a.35.35 0 01.47-.09l.2.14a.34.34 0 01.15.22.39.39 0 01-.06.26l-2.59 3.7a.33.33 0 01-.4.12h-.08l-.19-.13a.33.33 0 01-.14-.22.34.34 0 010-.26z"
              />
              <motion.path
                animate={{
                  scaleY: [0.5, 1, 0.5],
                }}
                transition={{ duration: 2, ease: 'easeOut', repeat: Infinity }}
                style={{
                  originY: 'bottom',
                  transformBox: 'fill-box',
                }}
                className="biodiversity_slide_3_prefix__cls-6"
                d="M1020.92 291.6a17.09 17.09 0 019.54-8.84.34.34 0 01.31 0 .36.36 0 01.15.28l.16 18.28a.32.32 0 01-.12.25.31.31 0 01-.26.09l-10.94-1h-.09a.33.33 0 01-.22-.29l-.06-.63a15.89 15.89 0 011.53-8.14z"
              />
              <path
                className="biodiversity_slide_3_prefix__cls-11"
                d="M1024.73 294.61l9.27-2a.35.35 0 01.42.31l.61 6.46v.36a6.83 6.83 0 01-5.55 6.82l-14.76 2.64a.37.37 0 01-.18 0l-.08-.05a.31.31 0 01-.14-.23 12.25 12.25 0 010-3.2 13.48 13.48 0 0110.41-11.11z"
              />
              <path
                className="biodiversity_slide_3_prefix__cls-12"
                d="M1024.48 301.39l10.16-2a.38.38 0 01.29.07.38.38 0 01.13.26 6.83 6.83 0 01-5.55 6.82l-9.49 1.69a.4.4 0 01-.19 0l-.08-.05a.33.33 0 01-.14-.24 6.12 6.12 0 014.87-6.55z"
              />
              <motion.path
                animate={{
                  scaleY: [0.5, 1, 0.5],
                }}
                transition={{ duration: 2, ease: 'easeOut', repeat: Infinity }}
                style={{
                  originY: 'bottom',
                  transformBox: 'fill-box',
                }}
                className="biodiversity_slide_3_prefix__cls-11"
                d="M1023 282.52a.35.35 0 01.31-.06.39.39 0 01.23.22l5.81 16.45a.39.39 0 010 .28.36.36 0 01-.23.17l-10.88 2.6a.34.34 0 01-.2 0 .37.37 0 01-.2-.19l-.25-.56a14.44 14.44 0 01-1-7.93 16.82 16.82 0 016.41-10.98z"
              />
              <g id="biodiversity_slide_3_prefix__general_eye_shape-6" data-name="general eye shape">
                <g id="biodiversity_slide_3_prefix__EYE-6" data-name="EYE">
                  <path
                    id="biodiversity_slide_3_prefix__eyes-8"
                    data-name="eyes"
                    className="biodiversity_slide_3_prefix__cls-1"
                    d="M1033.4 295.71a2.32 2.32 0 01-2.37 1.91 1.8 1.8 0 01-1.65-2.16 2.32 2.32 0 012.35-1.93 1.79 1.79 0 011.67 2.18z"
                  />
                  <g clipPath="url(#biodiversity_slide_3_prefix__clip-path-3)">
                    <path
                      id="biodiversity_slide_3_prefix__eyes-9"
                      data-name="eyes"
                      d="M1034.69 295.2a2.35 2.35 0 01-2.38 1.93 1.81 1.81 0 01-1.66-2.18A2.35 2.35 0 011033 293a1.79 1.79 0 011.69 2.2z"
                    />
                  </g>
                </g>
              </g>
              <path
                id="biodiversity_slide_3_prefix__n\xE6b-3"
                data-name="n\xE6b"
                className="biodiversity_slide_3_prefix__cls-14"
                d="M1038 294.71l-3.3.69-.15-1.56 3.31-.7a.64.64 0 01.8.56v.13a.85.85 0 01-.66.88z"
              />
            </motion.g>

          </g>
        </g>
      </g>
    </svg>
  );
}

export default AnimationSlide3;

import * as React from 'react';

function NoAnimationSlide6(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <filter id="shadow-slide-6" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="6" floodOpacity="0.4" />
        </filter>
        <style>
          {
            '.biodiversity_slide_6__cls-3{fill:#c6baff}.biodiversity_slide_6__cls-4{fill:#eed7ff}.biodiversity_slide_6__cls-5{fill:#f7dd41}.biodiversity_slide_6__cls-6{fill:#fff}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="biodiversity_slide_6__Layer_1" data-name="Layer 1">
          <g id="biodiversity_slide_6__FLOAT">
            <g id="purple-flies">
              <g
                className="fly"
                transform="translate(-1000, -150)"
              >
                <animateMotion
                  dur="3s"
                  repeatCount="indefinite"
                  path="M956.4,126c-3,19.1,11.67,38.06,30.89,39.07,17.29.9,32.44-13,34.53-30,1.94-15.76-7.68-31.76-22.72-36.35C980.74,93.18,959.56,106.21,956.4,126Z"
                />
                <g>
                  <path
                    className="biodiversity_slide_6__cls-3"
                    d="M574.66 141.24l-4.78-16.6a4.65 4.65 0 00-5.8-3.24 4.81 4.81 0 00-3.16 5.95L565.7 144a4.67 4.67 0 005.81 3.24 4.81 4.81 0 003.15-6z"
                  />
                </g>
                <g>
                  <path
                    className="biodiversity_slide_6__cls-4"
                    d="M574.32 145.18l8.45-15a4.85 4.85 0 00-1.71-6.54 4.62 4.62 0 00-6.38 1.75l-8.45 15a4.86 4.86 0 001.71 6.54 4.61 4.61 0 006.38-1.75z"
                  />
                </g>
                <path
                  className="biodiversity_slide_6__cls-3"
                  d="M562.84 148.23l11.64 1.1a3.24 3.24 0 003.5-3 3.28 3.28 0 00-2.91-3.58l-11.64-1.09a3.24 3.24 0 00-3.5 3 3.28 3.28 0 002.91 3.57z"
                />
              </g>
              <g
                className="fly"
              >
                <g>
                  <path
                    className="biodiversity_slide_6__cls-3"
                    d="M633.45 101.9l-4.78-16.61a4.65 4.65 0 00-5.8-3.23 4.8 4.8 0 00-3.16 5.94l4.78 16.61a4.66 4.66 0 005.81 3.23 4.8 4.8 0 003.15-5.94z"
                  />
                </g>
                <g>
                  <path
                    className="biodiversity_slide_6__cls-4"
                    d="M633.11 105.84l8.45-15a4.84 4.84 0 00-1.71-6.53 4.61 4.61 0 00-6.38 1.75l-8.45 15a4.87 4.87 0 001.71 6.54 4.61 4.61 0 006.38-1.76z"
                  />
                </g>
                <path
                  className="biodiversity_slide_6__cls-3"
                  d="M621.63 108.89l11.64 1.11a3.24 3.24 0 003.5-3 3.27 3.27 0 00-2.91-3.58l-11.64-1.1a3.24 3.24 0 00-3.5 3 3.28 3.28 0 002.91 3.57z"
                />
              </g>
            </g>
            <g id="biodiversity_slide_6__flies">
              <g
                id="biodiversity_slide_6__fly"
              >
                <g>
                  <path
                    className="biodiversity_slide_6__cls-5"
                    d="M1015.28 124.54l-3.72-12.9a3.62 3.62 0 00-4.51-2.52 3.73 3.73 0 00-2.45 4.62l3.71 12.9a3.62 3.62 0 004.51 2.52 3.75 3.75 0 002.46-4.62z"
                  />
                </g>
                <g>
                  <path
                    className="biodiversity_slide_6__cls-5"
                    d="M1015 127.6l6.57-11.65a3.78 3.78 0 00-1.33-5.08 3.6 3.6 0 00-5 1.36l-6.56 11.65a3.76 3.76 0 001.32 5.08 3.58 3.58 0 005-1.36z"
                  />
                </g>
                <path
                  className="biodiversity_slide_6__cls-5"
                  d="M1006.09 130l9 .85a2.51 2.51 0 002.71-2.31 2.53 2.53 0 00-2.26-2.78l-9-.85a2.51 2.51 0 00-2.72 2.31 2.53 2.53 0 002.27 2.78z"
                />
              </g>
              <g
                id="biodiversity_slide_6__fly-2"
                data-name="fly"
              >
                <path
                  className="biodiversity_slide_6__cls-5"
                  d="M1061 94l-3.72-12.9a3.62 3.62 0 00-4.51-2.52 3.73 3.73 0 00-2.45 4.62l3.71 12.9a3.62 3.62 0 004.51 2.52A3.75 3.75 0 001061 94z"
                />
              </g>
              <g>
                <path
                  className="biodiversity_slide_6__cls-5"
                  d="M1060.69 97l6.57-11.65a3.78 3.78 0 00-1.33-5.08 3.58 3.58 0 00-5 1.36l-6.56 11.65a3.76 3.76 0 001.32 5.08 3.58 3.58 0 005-1.36z"
                />
              </g>
              <path
                className="biodiversity_slide_6__cls-5"
                d="M1051.77 99.4l9 .85a2.51 2.51 0 002.71-2.31 2.53 2.53 0 00-2.26-2.78l-9-.85a2.51 2.51 0 00-2.72 2.31 2.55 2.55 0 002.27 2.78z"
              />
            </g>
          </g>
          <g id="biodiversity_slide_6__flies-yellow-left">
            <g
              id="biodiversity_slide_6__fly-3"
              data-name="fly"
            >

              <g>
                <path
                  className="biodiversity_slide_6__cls-5"
                  d="M93 441.68l-3.72-12.9a3.6 3.6 0 00-4.5-2.51 3.73 3.73 0 00-2.46 4.61l3.72 12.91a3.62 3.62 0 004.51 2.51 3.73 3.73 0 002.45-4.62z"
                />
              </g>
              <g>
                <path
                  className="biodiversity_slide_6__cls-5"
                  d="M92.78 444.74l6.56-11.65A3.75 3.75 0 0098 428a3.58 3.58 0 00-5 1.36L86.49 441a3.78 3.78 0 001.33 5.08 3.59 3.59 0 004.96-1.34z"
                />
              </g>
              <path
                className="biodiversity_slide_6__cls-5"
                d="M83.85 447.11l9.05.86a2.53 2.53 0 002.72-2.32 2.55 2.55 0 00-2.26-2.78l-9-.85a2.5 2.5 0 00-2.71 2.31 2.53 2.53 0 002.2 2.78z"
              />
            </g>
            <g
              id="biodiversity_slide_6__fly-4"
              data-name="fly"
            >
              <g>
                <path
                  className="biodiversity_slide_6__cls-5"
                  d="M138.72 411.11l-3.72-12.9a3.61 3.61 0 00-4.5-2.52 3.74 3.74 0 00-2.46 4.62l3.72 12.91a3.63 3.63 0 004.51 2.51 3.73 3.73 0 002.45-4.62z"
                />
              </g>
              <g>
                <path
                  className="biodiversity_slide_6__cls-5"
                  d="M138.46 414.17l6.54-11.65a3.77 3.77 0 00-1.32-5.08 3.59 3.59 0 00-5 1.37l-6.57 11.65a3.77 3.77 0 001.33 5.07 3.58 3.58 0 005.02-1.36z"
                />
              </g>
              <path
                className="biodiversity_slide_6__cls-5"
                d="M129.53 416.54l9.05.85a2.52 2.52 0 002.72-2.31 2.55 2.55 0 00-2.3-2.78l-9-.85a2.5 2.5 0 00-2.71 2.31 2.53 2.53 0 002.24 2.78z"
              />
            </g>
          </g>
          <path
            filter="url(#shadow-slide-6)"
            id="biodiversity_slide_6__cloud"
            className="biodiversity_slide_6__cls-6"
            d="M75.32 144.23a24.56 24.56 0 0122.37-24.47 24.56 24.56 0 0123.75-18.33h3.23a24.55 24.55 0 0123.71 18.24h7A24.58 24.58 0 01180 144.23v2.56a23.94 23.94 0 01-1.22 7.62 3.83 3.83 0 01-3.65 2.62H80.18a3.83 3.83 0 01-3.64-2.62 23.94 23.94 0 01-1.22-7.62v-1.28-1.28z"
          />
          <path
            filter="url(#shadow-slide-6)"
            id="biodiversity_slide_6__cloud_copy"
            className="biodiversity_slide_6__cls-6"
            d="M75.32 144.23a24.56 24.56 0 0122.37-24.47 24.56 24.56 0 0123.75-18.33h3.23a24.55 24.55 0 0123.71 18.24h7A24.58 24.58 0 01180 144.23v2.56a23.94 23.94 0 01-1.22 7.62 3.83 3.83 0 01-3.65 2.62H80.18a3.83 3.83 0 01-3.64-2.62 23.94 23.94 0 01-1.22-7.62v-1.28-1.28z"
          />
          <path
            filter="url(#shadow-slide-6)"
            id="biodiversity_slide_6__cloud-1"
            className="biodiversity_slide_6__cls-6"
            d="M500.32 454.23a24.56 24.56 0 0122.37-24.46 24.57 24.57 0 0123.75-18.34h3.23a24.57 24.57 0 0123.72 18.24h7A24.59 24.59 0 01605 454.23v2.56a24.22 24.22 0 01-1.21 7.62 3.86 3.86 0 01-3.66 2.63h-94.95a3.84 3.84 0 01-3.64-2.63 23.94 23.94 0 01-1.22-7.62v-1.27-1.29z"
          />
          <path
            filter="url(#shadow-slide-6)"
            id="biodiversity_slide_6__cloud-1_copy"
            className="biodiversity_slide_6__cls-6"
            d="M500.32 454.23a24.56 24.56 0 0122.37-24.46 24.57 24.57 0 0123.75-18.34h3.23a24.57 24.57 0 0123.72 18.24h7A24.59 24.59 0 01605 454.23v2.56a24.22 24.22 0 01-1.21 7.62 3.86 3.86 0 01-3.66 2.63h-94.95a3.84 3.84 0 01-3.64-2.63 23.94 23.94 0 01-1.22-7.62v-1.27-1.29z"
          />
          <path
            filter="url(#shadow-slide-6)"
            id="biodiversity_slide_6__cloud-2"
            className="biodiversity_slide_6__cls-6"
            d="M1149.27 461.25a24.56 24.56 0 0122.37-24.47 24.56 24.56 0 0123.75-18.33h3.23a24.55 24.55 0 0123.71 18.24h7a24.58 24.58 0 0124.56 24.56v2.56a23.94 23.94 0 01-1.22 7.62 3.83 3.83 0 01-3.65 2.62h-94.93a3.83 3.83 0 01-3.64-2.62 23.94 23.94 0 01-1.22-7.62v-1.28c0-.42.04-.85.04-1.28z"
          />
          <path
            id="biodiversity_slide_6__cloud-2_copy"
            className="biodiversity_slide_6__cls-6"
            d="M1149.27 461.25a24.56 24.56 0 0122.37-24.47 24.56 24.56 0 0123.75-18.33h3.23a24.55 24.55 0 0123.71 18.24h7a24.58 24.58 0 0124.56 24.56v2.56a23.94 23.94 0 01-1.22 7.62 3.83 3.83 0 01-3.65 2.62h-94.93a3.83 3.83 0 01-3.64-2.62 23.94 23.94 0 01-1.22-7.62v-1.28c0-.42.04-.85.04-1.28z"
          />
        </g>
      </g>
    </svg>
  );
}

export default NoAnimationSlide6;

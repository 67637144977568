import * as React from 'react';

function NoAnimationSlide8(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <filter id="shadow-slide-8" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="6" floodOpacity="0.4" />
        </filter>
        <clipPath id="biodiversity_slide_8__clip-path">
          <rect
            className="biodiversity_slide_8__cls-1"
            x={558.87}
            y={418.56}
            width={44.51}
            height={20.31}
            rx={10.08}
            transform="rotate(-14.73 581.088 428.718)"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_8__clip-path-2">
          <path
            className="biodiversity_slide_8__cls-1"
            d="M588.84 422.34a5.36 5.36 0 114 6.49 5.39 5.39 0 01-4-6.49"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_8__clip-path-3">
          <path
            id="biodiversity_slide_8__eyes"
            className="biodiversity_slide_8__cls-2"
            d="M503.71 139.38a6.59 6.59 0 01-8.6-.68 5.1 5.1 0 01.79-7.68 6.59 6.59 0 018.6.6 5.12 5.12 0 01-.79 7.76z"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_8__clip-path-4">
          <path
            className="biodiversity_slide_8__cls-1"
            d="M789.45 372.73l37.22 15.95.07-.17a22.8 22.8 0 00-12-29.93L801.67 353a3.46 3.46 0 00-4.54 1.82z"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_8__clip-path-5">
          <path
            className="biodiversity_slide_8__cls-1"
            d="M797 361a5.28 5.28 0 10-4 6.39 5.32 5.32 0 004-6.39"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_8__clip-path-6">
          <circle className="biodiversity_slide_8__cls-3" cx={905.12} cy={207.55} r={10.44} />
        </clipPath>
        <clipPath id="biodiversity_slide_8__clip-path-7">
          <path
            className="biodiversity_slide_8__cls-1"
            d="M910.1 205.6a5.27 5.27 0 10-4.06 6.31 5.3 5.3 0 004.06-6.31"
          />
        </clipPath>
        <style>
          {
            '.biodiversity_slide_8__cls-1,.biodiversity_slide_8__cls-18{fill:none}.biodiversity_slide_8__cls-2{fill:#fff}.biodiversity_slide_8__cls-3{fill:#f99c43}.biodiversity_slide_8__cls-6{fill:#f7dd41}.biodiversity_slide_8__cls-7{fill:#eb826a}.biodiversity_slide_8__cls-8{fill:#fbec6d}.biodiversity_slide_8__cls-10{fill:#414042}.biodiversity_slide_8__cls-12{fill:#f1f2f2}.biodiversity_slide_8__cls-13{fill:#f5b1a6}.biodiversity_slide_8__cls-14{fill:#cf6d5a}.biodiversity_slide_8__cls-16{fill:#b54848}.biodiversity_slide_8__cls-18{stroke:#f5b1a6;stroke-miterlimit:10;stroke-width:.58px}.biodiversity_slide_8__cls-21{fill:#db5d5d}.biodiversity_slide_8__cls-22{fill:#f4cb5e}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g
          id="biodiversity_slide_8__Layer_1"
          data-name="Layer 1"
          filter="url(#shadow-slide-8)"
        >
          <g id="biodiversity_slide_8__FLOAT">
            <g
              id="biodiversity_slide_8__micro_bird"
              data-name="micro bird"
            >
              <path
                className="biodiversity_slide_8__cls-3"
                d="M513.83 352.22l-3.75-13a3.63 3.63 0 00-4.54-2.53 3.75 3.75 0 00-2.47 4.65l3.74 13a3.65 3.65 0 004.55 2.54 3.77 3.77 0 002.47-4.66z"
              />
              <path
                className="biodiversity_slide_8__cls-6"
                d="M513.56 355.31l6.62-11.74a3.8 3.8 0 00-1.34-5.12 3.62 3.62 0 00-5 1.37l-6.61 11.74a3.8 3.8 0 001.34 5.12 3.6 3.6 0 004.99-1.37z"
              />
              <path
                className="biodiversity_slide_8__cls-6"
                d="M504.57 357.7l9.12.86a2.53 2.53 0 002.73-2.34 2.56 2.56 0 00-2.27-2.8l-9.12-.86a2.55 2.55 0 00-2.74 2.33 2.58 2.58 0 002.28 2.81z"
              />
            </g>
            <g
              id="biodiversity_slide_8__micro_bird-2"
              data-name="micro bird"
            >
              <path
                className="biodiversity_slide_8__cls-3"
                d="M747.75 244.15l-3.75-13a3.65 3.65 0 00-4.54-2.53 3.77 3.77 0 00-2.47 4.66l3.74 13a3.65 3.65 0 004.55 2.53 3.76 3.76 0 002.47-4.66z"
              />
              <path
                className="biodiversity_slide_8__cls-6"
                d="M747.48 247.23l6.62-11.74a3.8 3.8 0 00-1.34-5.12 3.6 3.6 0 00-5 1.38l-6.62 11.74a3.8 3.8 0 001.34 5.12 3.6 3.6 0 005-1.38z"
              />
              <path
                className="biodiversity_slide_8__cls-6"
                d="M738.49 249.62l9.12.86a2.52 2.52 0 002.73-2.33 2.55 2.55 0 00-2.27-2.8l-9.12-.86a2.52 2.52 0 00-2.73 2.33 2.56 2.56 0 002.27 2.8z"
              />
            </g>
            <g
              id="biodiversity_slide_8__bee-2"
              data-name="bee"
            >
              <path
                className="biodiversity_slide_8__cls-7"
                d="M588.65 442.53l1.86-.46.02-5.43-1.86.46-.02 5.43zM585.12 443.4l1.86-.46.02-5.43-1.87.46-.01 5.43zM576.93 445.41l1.86-.46.02-5.43-1.86.46-.02 5.43zM573.4 446.27l1.86-.45.01-5.43-1.86.45-.01 5.43z"
              />
              <rect
                className="biodiversity_slide_8__cls-8"
                x={558.87}
                y={418.56}
                width={44.51}
                height={20.31}
                rx={10.08}
                transform="rotate(-14.73 581.088 428.718)"
              />
              <g clipPath="url(#biodiversity_slide_8__clip-path)">
                <path
                  className="biodiversity_slide_8__cls-10"
                  d="M609.91 430.17a8.68 8.68 0 01-14.66 0l-1 .61a9.81 9.81 0 0016.56 0z"
                />
              </g>
              <g
                clipPath="url(#biodiversity_slide_8__clip-path-2)"
                id="biodiversity_slide_8__eye-4"
                data-name="eye"
              >
                <path
                  className="biodiversity_slide_8__cls-12"
                  d="M588.84 422.34a5.36 5.36 0 114 6.49 5.39 5.39 0 01-4-6.49"
                />
                <path
                  className="biodiversity_slide_8__cls-10"
                  d="M594.06 423.53a5.36 5.36 0 114 6.49 5.41 5.41 0 01-4-6.49"
                />
              </g>
              <path
                className="biodiversity_slide_8__cls-3"
                transform="rotate(-14.73 579.016 429.236)"
                d="M571.65 419.1h14.77v20.31h-14.77z"
              />
              <path
                className="biodiversity_slide_8__cls-7"
                d="M548.12 405.25l2-.51a20.75 20.75 0 0125.35 14.8v.16l-1.94.51a20.75 20.75 0 01-25.35-14.8z"
              />
              <path
                className="biodiversity_slide_8__cls-13"
                d="M587.33 393l.51 1.94a20.76 20.76 0 01-14.84 25.4h-.15l-.51-1.95a20.75 20.75 0 0114.8-25.35z"
              />
            </g>
            <g id="biodiversity_slide_8__bird-2" data-name="bird">
              <path
                className="biodiversity_slide_8__cls-14"
                d="M456.82 185.77l7.88-12.7a1.17 1.17 0 011.59-.38l.67.42a1.12 1.12 0 01.52.72 1.15 1.15 0 01-.14.87l-7.9 12.7a1.16 1.16 0 01-.72.52 1.09 1.09 0 01-.62 0 1.15 1.15 0 01-.25-.12l-.66-.41a1.15 1.15 0 01-.37-1.62z"
              />
              <path
                className="biodiversity_slide_8__cls-14"
                d="M471.21 172.52a1.17 1.17 0 011.59-.37l.67.41a1.17 1.17 0 01.52.73 1.13 1.13 0 01-.14.88l-8 12.77a1.16 1.16 0 01-1.34.49 1.42 1.42 0 01-.25-.12l-.66-.41a1.16 1.16 0 01-.52-.72 1.14 1.14 0 01.15-.88z"
              />
              <g>
                <path
                  className="biodiversity_slide_8__cls-14"
                  d="M463.53 124.18c5.87-14.22 17.24-25.85 30.4-31.12a1.15 1.15 0 011.58 1l3.54 61a1.14 1.14 0 01-.33.88 1.13 1.13 0 01-.87.34l-36.7-1.46a1.12 1.12 0 01-.31 0 1.19 1.19 0 01-.79-.94l-.3-2.1a52.68 52.68 0 013.78-27.6z"
                />
              </g>
              <path
                className="biodiversity_slide_8__cls-7"
                d="M476.73 133.59l30.64-8.23a1.16 1.16 0 01.94.15 1.18 1.18 0 01.51.8l3.13 21.5c.06.4.1.8.13 1.19.94 10.8-6.72 21.2-17.42 23.68l-48.84 11.26a1.18 1.18 0 01-.62 0 1.55 1.55 0 01-.29-.14 1.22 1.22 0 01-.5-.77 41.57 41.57 0 01-.39-10.7 45.11 45.11 0 0132.71-38.74z"
              />
              <path
                className="biodiversity_slide_8__cls-13"
                d="M477 156.3l33.65-8.3a1.14 1.14 0 011 .18 1.13 1.13 0 01.48.84c.94 10.8-6.72 21.2-17.42 23.68l-31.42 7.24a1.19 1.19 0 01-.62 0 1.47 1.47 0 01-.28-.14 1.15 1.15 0 01-.5-.78C460.2 169 467 158.78 477 156.3z"
              />
              <g>
                <path
                  className="biodiversity_slide_8__cls-7"
                  d="M468.91 93.48a1.18 1.18 0 011-.22 1.15 1.15 0 01.79.68l22.13 54a1.1 1.1 0 010 .94 1.13 1.13 0 01-.72.61L456.17 160a1.16 1.16 0 01-1.36-.59l-.92-1.83a48.41 48.41 0 01-4.64-26.35c1.38-14.7 8.75-28.79 19.66-37.75z"
                />
              </g>
              <g id="biodiversity_slide_8__general_eye_shape-2" data-name="general eye shape">
                <g id="biodiversity_slide_8__EYE-5" data-name="EYE">
                  <path
                    id="biodiversity_slide_8__eyes-2"
                    data-name="eyes"
                    className="biodiversity_slide_8__cls-2"
                    d="M503.71 139.38a6.59 6.59 0 01-8.6-.68 5.1 5.1 0 01.79-7.68 6.59 6.59 0 018.6.6 5.12 5.12 0 01-.79 7.76z"
                  />
                  <g clipPath="url(#biodiversity_slide_8__clip-path-3)">
                    <path
                      id="biodiversity_slide_8__eyes-3"
                      data-name="eyes"
                      d="M507.38 140.87a6.68 6.68 0 01-8.68-.7 5.11 5.11 0 01.8-7.71 6.66 6.66 0 018.66.62 5.11 5.11 0 01-.78 7.79z"
                    />
                  </g>
                </g>
              </g>
              <path
                id="biodiversity_slide_8__n\xE6b"
                className="biodiversity_slide_8__cls-8"
                d="M520.91 131.75L510 134.59l-.75-5.16 10.93-2.9a2.15 2.15 0 012.79 1.75l.06.43a2.85 2.85 0 01-2.12 3.04z"
              />
            </g>
            <g
              id="biodiversity_slide_8__cut_bug-2"
              data-name="cut bug"
            >
              <path
                className="biodiversity_slide_8__cls-16"
                d="M819 391.92l-1.25-6.06a1 1 0 00-1.24-.81 1 1 0 00-.81 1.24l1.25 6a1 1 0 001.24.81 1 1 0 00.66-.45 1 1 0 00.15-.73zM796 376.64a1.06 1.06 0 00-1.48-.12l-4.71 4a1 1 0 00-.37.72 1.05 1.05 0 00.24.76 1 1 0 00.39.28 1 1 0 001.09-.16l4.72-4a1 1 0 00.12-1.48zM807.34 382.5a1 1 0 00-2.09 0l.12 6.18a1 1 0 00.32.74 1.06 1.06 0 00.31.21 1.22 1.22 0 00.43.08 1.09 1.09 0 00.74-.32 1 1 0 00.29-.75z"
              />
              <path
                className="biodiversity_slide_8__cls-7"
                d="M789.45 372.73l37.22 15.95a23 23 0 00-12.08-30.17l-12.9-5.51a3.48 3.48 0 00-4.57 1.83z"
              />
              <g clipPath="url(#biodiversity_slide_8__clip-path-4)">
                <path
                  className="biodiversity_slide_8__cls-18"
                  d="M825.14 388.07a24.43 24.43 0 00-5.06-21.66c-5-6-16.73-10-16.73-10"
                />
                <path
                  className="biodiversity_slide_8__cls-18"
                  d="M818.16 385.08s1.9-8-4.73-15.71c-3.88-4.51-12.11-8.24-12.11-8.24M810.55 381.82s.69-4-4-8.42c-2.78-2.57-8.29-5-8.29-5"
                />
              </g>
              <path
                className="biodiversity_slide_8__cls-3"
                transform="rotate(-66.81 793.945 369.096)"
                d="M788.75 365.45h10.36v7.31h-10.36z"
              />
              <path
                d="M799.86 352.2a15.31 15.31 0 00-17.79 4.27 9.6 9.6 0 01-7.93 3.24c-4-.11-11.49-5.54-12.56-6-2.14-.92-2.7 1.2-1.73 2.4 2.7 3.34 10.91 8.61 19.22 12.17l10.62 4.55a6.08 6.08 0 008-3.2l6.66-15.53z"
                fill="#f4cb5d"
              />
              <path
                className="biodiversity_slide_8__cls-14"
                d="M785.53 371.06c-.54 1.26 1.83 3.49 9.43 6.75l21.72 9.3c6.79 2.91 9.45 2.83 10 1.57z"
              />
              <g id="biodiversity_slide_8__eye-6" data-name="eye">
                <g clipPath="url(#biodiversity_slide_8__clip-path-5)">
                  <path
                    className="biodiversity_slide_8__cls-12"
                    d="M797 361a5.28 5.28 0 10-4 6.39 5.32 5.32 0 004-6.39"
                  />
                  <path
                    className="biodiversity_slide_8__cls-10"
                    d="M791.83 362.14a5.27 5.27 0 10-4 6.39 5.32 5.32 0 004-6.39"
                  />
                </g>
              </g>
            </g>
            <g
              id="dragonfly"
            >
              <path
                className="biodiversity_slide_8__cls-21"
                d="M917.59 229.52a1 1 0 001.32-1.25l-2.29-10a1.06 1.06 0 00-2.07.47l2.3 10a1 1 0 00.74.78zM921.3 230.58a1.18 1.18 0 00.53 0 1.06 1.06 0 00.79-1.27l-2.29-10a1.06 1.06 0 00-2.07.47l2.3 10a1.08 1.08 0 00.74.8z"
              />
              <g>
                <rect
                  className="biodiversity_slide_8__cls-7"
                  x={910.14}
                  y={161.23}
                  width={12.05}
                  height={51.69}
                  rx={5.98}
                  transform="rotate(-21.58 916.251 187.071)"
                />
              </g>
              <rect
                className="biodiversity_slide_8__cls-22"
                x={913.49}
                y={201.58}
                width={12.39}
                height={25.97}
                rx={6.15}
                transform="rotate(-74.16 919.635 214.587)"
              />
              <rect
                className="biodiversity_slide_8__cls-22"
                x={942.69}
                y={193.74}
                width={7.34}
                height={51.53}
                rx={3.64}
                transform="rotate(-74.16 946.302 219.533)"
              />
              <g>
                <rect
                  x={916.87}
                  y={189.33}
                  width={51.69}
                  height={12.05}
                  rx={5.98}
                  transform="rotate(-40.87 942.617 195.376)"
                  fill="#fbf292"
                />
              </g>
              <circle
                className="biodiversity_slide_8__cls-3"
                cx={905.12}
                cy={207.55}
                r={10.44}
              />
              <g clipPath="url(#biodiversity_slide_8__clip-path-6)">
                <circle
                  className="biodiversity_slide_8__cls-22"
                  cx={905.12}
                  cy={207.55}
                  r={10.44}
                />
                <path
                  d="M892.8 212.73a5 5 0 004.11 1.85 5.2 5.2 0 004.27-1.65"
                  stroke="#414042"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={0.98}
                  fill="none"
                />
              </g>
              <g clipPath="url(#biodiversity_slide_8__clip-path-7)">
                <path
                  className="biodiversity_slide_8__cls-12"
                  d="M910.1 205.6a5.27 5.27 0 10-4.06 6.31 5.3 5.3 0 004.06-6.31"
                />
                <path
                  className="biodiversity_slide_8__cls-10"
                  d="M904.94 206.68a5.27 5.27 0 10-4.05 6.31 5.3 5.3 0 004.05-6.31"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default NoAnimationSlide8;

import React from 'react';
import Eboti1 from '../../../../components/eBotiStates/Eboti1/Eboti1';
import Eboti3 from '../../../../components/eBotiStates/Eboti3/Eboti3-2';
import Eboti6 from '../../../../components/eBotiStates/Eboti6/Eboti6';
import Eboti9 from '../../../../components/eBotiStates/Eboti9/Eboti9';
import Eboti12 from '../../../../components/eBotiStates/Eboti12/Eboti12-2';
import Eboti14 from '../../../../components/eBotiStates/Eboti14/Eboti14';
import Eboti18 from '../../../../components/eBotiStates/Eboti18/Eboti18';

const Eboti = (index) => {
  if (index.index === 1) {
    return <Eboti1 />;
  } if (index.index === 0 || index.index === 5) {
    return <Eboti9 />;
  } if (index.index === 2) {
    return <Eboti6 />;
  } if (index.index === 3) {
    return <Eboti3 />;
  } if (index.index === 4) {
    return <Eboti12 />;
  } if (index.index === 6) {
    return <Eboti14 />;
  } if (index.index === 7) {
    return <Eboti18 />;
  }
  return '';
};

export default Eboti;

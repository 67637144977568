import * as React from 'react';
import { motion } from 'framer-motion';

function AnimationSlide5(props) {
  return (
    <svg
      id="biodiversity_slide_5__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <filter id="shadow-slide-5" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="6" floodOpacity="0.4" />
        </filter>
        <clipPath id="biodiversity_slide_5__clip-path">
          <path
            className="biodiversity_slide_5__cls-1"
            d="M916.68 508.9H921a17 17 0 0117 17v5.85h-4.32a17 17 0 01-17-17v-5.85z"
            transform="rotate(-106.63 927.378 520.329)"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_5__clip-path-2">
          <path
            className="biodiversity_slide_5__cls-1"
            d="M957 499.82h4.32v5.85a17 17 0 01-17 17H940v-5.85a17 17 0 0117-17z"
            transform="rotate(-106.63 950.666 511.252)"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_5__clip-path-3">
          <path
            className="biodiversity_slide_5__cls-1"
            d="M1076.62 428.78h3.44a13.56 13.56 0 0113.56 13.56V447h-3.44a13.56 13.56 0 01-13.56-13.56v-4.65-.01z"
            transform="rotate(-97.79 1085.12 437.882)"
          />
        </clipPath>
        <clipPath id="biodiversity_slide_5__clip-path-4">
          <path
            className="biodiversity_slide_5__cls-1"
            d="M1109.61 424.48h3.44v4.65a13.56 13.56 0 01-13.56 13.56h-3.44V438a13.56 13.56 0 0113.56-13.52z"
            transform="rotate(-98 1101.455 435.333)"
          />
        </clipPath>
        <style>
          {
            '.biodiversity_slide_5__cls-1{fill:#eed7ff}.biodiversity_slide_5__cls-2{fill:#f99c43}.biodiversity_slide_5__cls-3{fill:#f4cb5d}.biodiversity_slide_5__cls-4{fill:#fced6f}.biodiversity_slide_5__cls-5{fill:#e07070}.biodiversity_slide_5__cls-6{fill:#ec987e}.biodiversity_slide_5__cls-7{fill:#efc4b9}.biodiversity_slide_5__cls-8{fill:#e5a25e}.biodiversity_slide_5__cls-9{fill:#f1e1b0}.biodiversity_slide_5__cls-10{fill:#f7cb6b}.biodiversity_slide_5__cls-11{fill:#efc1b3}.biodiversity_slide_5__cls-12{fill:#f68b61}.biodiversity_slide_5__cls-13{fill:#db5b4d}.biodiversity_slide_5__cls-14{fill:#f7e1dc}.biodiversity_slide_5__cls-17{fill:#c6baff}.biodiversity_slide_5__cls-20{fill:#fff}'
          }
        </style>
      </defs>
      <g id="biodiversity_slide_5__little_flies_orange">
        <g>
          <rect
            className="biodiversity_slide_5__cls-2"
            x={648.9}
            y={504.26}
            width={18.49}
            height={6.58}
            rx={3.29}
            transform="rotate(-132.93 658.153 507.553)"
          />
          <rect
            className="biodiversity_slide_5__cls-3"
            x={653.45}
            y={502.77}
            width={18.49}
            height={6.58}
            rx={3.29}
            transform="rotate(-86.48 662.723 506.058)"
          />
          <rect
            className="biodiversity_slide_5__cls-4"
            x={655.94}
            y={511.83}
            width={12.77}
            height={4.53}
            rx={2.26}
            transform="rotate(-21.23 662.16 514.006)"
          />
        </g>
        <g>
          <rect
            className="biodiversity_slide_5__cls-2"
            x={669.24}
            y={445.29}
            width={18.49}
            height={6.58}
            rx={3.29}
            transform="rotate(-132.93 678.49 448.58)"
          />
          <rect
            className="biodiversity_slide_5__cls-3"
            x={673.78}
            y={443.8}
            width={18.49}
            height={6.58}
            rx={3.29}
            transform="rotate(-86.48 683.056 447.082)"
          />
          <rect
            className="biodiversity_slide_5__cls-4"
            x={676.27}
            y={452.85}
            width={12.77}
            height={4.53}
            rx={2.26}
            transform="rotate(-21.23 682.49 455.045)"
          />
        </g>
      </g>
      <g id="biodiversity_slide_5__little_flies_red">
        <rect
          className="biodiversity_slide_5__cls-5"
          x={455.1}
          y={189.58}
          width={18.49}
          height={6.58}
          rx={3.29}
          transform="rotate(-101.19 464.345 192.873)"
        />
        <rect
          className="biodiversity_slide_5__cls-6"
          x={459.75}
          y={190.7}
          width={18.49}
          height={6.58}
          rx={3.29}
          transform="rotate(-54.74 469.014 193.987)"
        />
        <rect
          className="biodiversity_slide_5__cls-7"
          x={458.07}
          y={198.36}
          width={12.77}
          height={4.53}
          rx={2.26}
          transform="rotate(10.52 464.239 200.498)"
        />
        <g>
          <rect
            className="biodiversity_slide_5__cls-5"
            x={461.19}
            y={263.51}
            width={18.49}
            height={6.58}
            rx={3.29}
            transform="rotate(-101.19 470.431 266.797)"
          />
          <rect
            className="biodiversity_slide_5__cls-6"
            x={465.84}
            y={264.62}
            width={18.49}
            height={6.58}
            rx={3.29}
            transform="rotate(-54.74 475.112 267.917)"
          />
          <rect
            className="biodiversity_slide_5__cls-7"
            x={464.16}
            y={272.29}
            width={12.77}
            height={4.53}
            rx={2.26}
            transform="rotate(10.52 470.333 274.426)"
          />
        </g>
        <g>
          <rect
            className="biodiversity_slide_5__cls-5"
            x={435.53}
            y={231.33}
            width={18.49}
            height={6.58}
            rx={3.29}
            transform="rotate(-101.19 444.78 234.616)"
          />
          <rect
            className="biodiversity_slide_5__cls-6"
            x={440.18}
            y={232.45}
            width={18.49}
            height={6.58}
            rx={3.29}
            transform="rotate(-54.74 449.447 235.735)"
          />
          <rect
            className="biodiversity_slide_5__cls-7"
            x={438.51}
            y={240.11}
            width={12.77}
            height={4.53}
            rx={2.26}
            transform="rotate(10.52 444.693 242.226)"
          />
        </g>
      </g>
      <g
        // filter="url(#shadow-slide-5)"
        id="biodiversity_slide_5__big_flies_orange"
      >
        <motion.g
          animate={{
            y: ['300px', '0px', '300px'],
            x: ['-300px', '0px', '200px'],
          }}
          transition={{
            duration: 10, ease: 'linear', delay: 10, repeatDelay: 20, repeat: Infinity,
          }}
          style={{
            originY: 'bottom',
            transformBox: 'fill-box',
          }}
          className="fly"
        >
          <motion.g
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
            style={{
              originY: 'bottom',
              transformBox: 'fill-box',
            }}
          >
            <path
              className="biodiversity_slide_5__cls-8"
              d="M186 648l5.92 34.41 24.52-9.48-6-34.78a11.1 11.1 0 00-5.2-7.95 10.66 10.66 0 00-9.51-.46c-6.73 2.71-11 10.96-9.73 18.26z"
            />
          </motion.g>
          <motion.g
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
            style={{
              originY: 'bottom',
              transformBox: 'fill-box',
            }}
          >
            <path
              className="biodiversity_slide_5__cls-9"
              d="M169.53 654.35l21.56 28.37 24.52-9.49-21.79-28.67a18.61 18.61 0 00-9.44-6.31 17.29 17.29 0 00-11.27.23c-6.7 2.7-8.11 9.86-3.58 15.87z"
            />
          </motion.g>
          <path
            className="biodiversity_slide_5__cls-10"
            d="M187.38 691.41c1.73 4.45 7 6.57 11.75 4.73l19.08-7.37a9.55 9.55 0 005.18-4.73 8.34 8.34 0 00.32-6.68c-1.72-4.46-7-6.58-11.75-4.74L192.89 680a9.53 9.53 0 00-5.18 4.72 8.36 8.36 0 00-.33 6.69z"
          />
        </motion.g>
        <motion.g
          animate={{
            y: ['100px', '0px', '700px'],
            x: ['-400px', '0px', '300px'],
          }}
          transition={{
            duration: 10, ease: 'linear', delay: 10, repeatDelay: 20, repeat: Infinity,
          }}
          style={{
            originY: 'bottom',
            transformBox: 'fill-box',
          }}
          className="fly"
        >
          <motion.g
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
            style={{
              originY: 'bottom',
              transformBox: 'fill-box',
            }}
          >
            <path
              className="biodiversity_slide_5__cls-8"
              d="M302.56 623.73l3.07 17.86 12.73-4.92-3.11-18a5.76 5.76 0 00-2.7-4.13 5.52 5.52 0 00-4.93-.23 8.94 8.94 0 00-5.06 9.42z"
            />
          </motion.g>
          <motion.g
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
            style={{
              originY: 'bottom',
              transformBox: 'fill-box',
            }}
          >
            <path
              className="biodiversity_slide_5__cls-9"
              d="M294 627l11.18 14.72 12.73-4.92-11.28-14.8a9.69 9.69 0 00-4.9-3.27 8.93 8.93 0 00-5.85.12c-3.47 1.35-4.22 5.06-1.88 8.15z"
            />
          </motion.g>
          <path
            className="biodiversity_slide_5__cls-10"
            d="M303.29 646.26a4.65 4.65 0 006.1 2.45l9.89-3.82a4.93 4.93 0 002.69-2.45 4.32 4.32 0 00.17-3.47 4.67 4.67 0 00-6.1-2.46l-9.89 3.83a4.93 4.93 0 00-2.69 2.45 4.32 4.32 0 00-.17 3.47z"
          />
        </motion.g>
        <motion.g
          animate={{
            y: ['400px', '0px', '200px'],
            x: ['-300px', '0px', '300px'],
          }}
          transition={{
            duration: 10, ease: 'linear', delay: 10, repeatDelay: 20, repeat: Infinity,
          }}
          style={{
            originY: 'bottom',
            transformBox: 'fill-box',
          }}
          className="fly"
        >
          <motion.g
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
            style={{
              originY: 'bottom',
              transformBox: 'fill-box',
            }}
          >
            <path
              className="biodiversity_slide_5__cls-8"
              d="M408.63 615.13l2.44 14.15 10.08-3.9-2.46-14.3a4.53 4.53 0 00-2.14-3.27 4.39 4.39 0 00-3.91-.19 7.1 7.1 0 00-4.01 7.51z"
            />
          </motion.g>
          <motion.g
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
            style={{
              originY: 'bottom',
              transformBox: 'fill-box',
            }}
          >
            <path
              className="biodiversity_slide_5__cls-9"
              d="M401.87 617.75l8.86 11.66 10.09-3.9-9-11.79a7.64 7.64 0 00-3.89-2.59 7 7 0 00-4.63.09c-2.71 1.11-3.3 4.06-1.43 6.53z"
            />
          </motion.g>
          <path
            className="biodiversity_slide_5__cls-10"
            d="M409.21 633a3.68 3.68 0 004.83 1.95l7.85-3A4 4 0 00424 630a3.46 3.46 0 00.13-2.75 3.69 3.69 0 00-4.83-2l-7.84 3a4 4 0 00-2.14 2 3.43 3.43 0 00-.11 2.75z"
          />
        </motion.g>
      </g>
      <motion.g
        // filter="url(#shadow-slide-5)"
        id="biodiversity_slide_5__dragonflies"
        animate={{
          x: ['-300px', '0px', '0px', '300px'],
          y: ['0px', '0px', '0px', '-500px'],
        }}
        transition={{
          duration: 20, ease: 'easeOut', repeatDelay: 10, repeat: Infinity,
        }}
      >
        <g className="dragonfly">
          <animateMotion
            dur="3s"
            repeatCount="indefinite"
            path="M60 60a56.61 56.61 0 11-16.58-40 56.47 56.47 0 0116.58 40z"
          />
          <motion.g
            animate={{
              scaleY: [0.1, 1, 0.1],
            }}
            transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
            style={{
              originY: 'bottom',
              transformBox: 'fill-box',
            }}
          >
            <rect
              className="biodiversity_slide_5__cls-11"
              x={123.43}
              y={106.88}
              width={21.35}
              height={69.14}
              rx={10.68}
              transform="rotate(13.53 134.112 141.472)"
            />
          </motion.g>
          <rect
            className="biodiversity_slide_5__cls-12"
            x={116.31}
            y={167.89}
            width={23.39}
            height={13.22}
            rx={6.61}
          />
          <rect
            className="biodiversity_slide_5__cls-13"
            x={137.66}
            y={167.89}
            width={13.22}
            height={13.22}
            rx={6.61}
          />
          <rect
            className="biodiversity_slide_5__cls-12"
            x={76.66}
            y={167.89}
            width={40.67}
            height={13.22}
            rx={6.61}
          />
          <motion.g
            animate={{
              scaleY: [0.1, 1, 0.1],
            }}
            transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
            style={{
              originY: 'bottom',
              transformBox: 'fill-box',
            }}
          >
            <rect
              className="biodiversity_slide_5__cls-14 wing"
              x={99.02}
              y={109.93}
              width={21.35}
              height={69.14}
              rx={10.68}
              transform="rotate(-33.34 109.697 144.492)"
            />
          </motion.g>
        </g>
        <g className="dragonfly">
          <animateMotion
            dur="2s"
            repeatCount="indefinite"
            path="M60 60a56.61 56.61 0 11-16.58-40 56.47 56.47 0 0116.58 40z"
          />
          <motion.g
            animate={{
              scaleY: [0.1, 1, 0.1],
            }}
            transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
            style={{
              originY: 'bottom',
              transformBox: 'fill-box',
            }}
          >
            <rect
              className="biodiversity_slide_5__cls-11"
              x={229.86}
              y={204.83}
              width={10.53}
              height={34.1}
              rx={5.26}
              transform="rotate(13.53 235.171 221.938)"
            />
          </motion.g>
          <rect
            className="biodiversity_slide_5__cls-12"
            x={226.35}
            y={234.91}
            width={11.53}
            height={6.52}
            rx={3.26}
          />
          <rect
            className="biodiversity_slide_5__cls-13"
            x={236.88}
            y={234.91}
            width={6.52}
            height={6.52}
            rx={3.26}
          />
          <rect
            className="biodiversity_slide_5__cls-12"
            x={206.8}
            y={234.91}
            width={20.06}
            height={6.52}
            rx={3.26}
          />
          <motion.g
            animate={{
              scaleY: [0.1, 1, 0.1],
            }}
            transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
            style={{
              originY: 'bottom',
              transformBox: 'fill-box',
            }}
          >
            <rect
              className="biodiversity_slide_5__cls-14"
              x={217.83}
              y={206.33}
              width={10.53}
              height={34.1}
              rx={5.26}
              transform="matrix(.84 -.55 .55 .84 -86.05 159.36)"
            />
          </motion.g>
        </g>
      </motion.g>
      <g
        // filter="url(#shadow-slide-5)"
        id="biodiversity_slide_5__butterflies"
      >
        <g
          transform="rotate(90) translate(-799, -499)"
          id="prefix__butterfly"
        >
          <animateMotion
            dur="30s"
            repeatCount="indefinite"
            rotate="auto"
            path="M1000.42,22.2c20.78-35.48,68.71-115.14,164.5-173.31,86.65-52.62,236.41-102.19,348.73-48.82C1699.22-111.75,1710,222.9,1658.52,430.88c-81.38,328.83-392.39,639.84-577.1,582C856.24,942.4,823,325.06,1000.42,22.2Z"
          />
          <rect
            className="biodiversity_slide_5__cls-17"
            x={930.59}
            y={499.59}
            width={6.61}
            height={14.23}
            rx={3.3}
          />
          <motion.g
            id="prefix__left-wing"
            animate={{
              scaleX: [1, 0.5, 1],
            }}
            transition={{ duration: 1, ease: 'easeOut', repeat: Infinity }}
            style={{
              originY: 'bottom',
              originX: 'right',
              transformBox: 'fill-box',
            }}
          >
            <path
              className="biodiversity_slide_5__cls-14"
              d="M896.37 471.39h7A28.55 28.55 0 01932 499.94v9.57h-6a29.57 29.57 0 01-29.57-29.57v-8.56l-.06.01z"
              transform="rotate(177.73 914.17 490.442)"
            />
            <path
              className="biodiversity_slide_5__cls-14"
              d="M911 508h4.32a17 17 0 0117 17v5.85H928a17 17 0 01-17-17V508z"
              transform="rotate(-92.27 921.688 519.402)"
            />
          </motion.g>
          <motion.g
            animate={{
              scaleX: [1, 0.5, 1],
            }}
            transition={{ duration: 1, ease: 'easeOut', repeat: Infinity }}
            style={{
              originY: 'bottom',
              originX: 'left',
              transformBox: 'fill-box',
            }}
            id="prefix__right-wing"
          >
            <path
              className="biodiversity_slide_5__cls-14"
              d="M963.28 470.11h6v9.57a28.55 28.55 0 01-28.55 28.55h-7v-8.56a29.57 29.57 0 0129.55-29.56z"
              transform="rotate(-2.27 951.962 489.465)"
            />
            <path
              className="biodiversity_slide_5__cls-14"
              d="M952.77 507h4.32v5.85a17 17 0 01-17 17h-4.32V524a17 17 0 0117-17z"
              transform="rotate(-92.3 945.991 517.941)"
            />
          </motion.g>
        </g>
        <g
          transform="rotate(90) translate(-899, -599)"
          id="prefix__butterfly_2"
        >
          <animateMotion
            dur="30s"
            repeatCount="indefinite"
            rotate="auto"
            path="M1000.42,22.2c20.78-35.48,68.71-115.14,164.5-173.31,86.65-52.62,236.41-102.19,348.73-48.82C1699.22-111.75,1710,222.9,1658.52,430.88c-81.38,328.83-392.39,639.84-577.1,582C856.24,942.4,823,325.06,1000.42,22.2Z"
          />
          <rect
            className="biodiversity_slide_5__cls-17"
            x={930.59}
            y={499.59}
            width={6.61}
            height={14.23}
            rx={3.3}
          />
          <motion.g
            id="prefix__left-wing"
            animate={{
              scaleX: [1, 0.5, 1],
            }}
            transition={{
              duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
            }}
            style={{
              originY: 'bottom',
              originX: 'right',
              transformBox: 'fill-box',
            }}
          >
            <path
              className="biodiversity_slide_5__cls-14"
              d="M896.37 471.39h7A28.55 28.55 0 01932 499.94v9.57h-6a29.57 29.57 0 01-29.57-29.57v-8.56l-.06.01z"
              transform="rotate(177.73 914.17 490.442)"
            />
            <path
              className="biodiversity_slide_5__cls-14"
              d="M911 508h4.32a17 17 0 0117 17v5.85H928a17 17 0 01-17-17V508z"
              transform="rotate(-92.27 921.688 519.402)"
            />
          </motion.g>
          <motion.g
            animate={{
              scaleX: [1, 0.5, 1],
            }}
            transition={{
              duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
            }}
            style={{
              originY: 'bottom',
              originX: 'left',
              transformBox: 'fill-box',
            }}
            id="prefix__right-wing"
          >
            <path
              className="biodiversity_slide_5__cls-14"
              d="M963.28 470.11h6v9.57a28.55 28.55 0 01-28.55 28.55h-7v-8.56a29.57 29.57 0 0129.55-29.56z"
              transform="rotate(-2.27 951.962 489.465)"
            />
            <path
              className="biodiversity_slide_5__cls-14"
              d="M952.77 507h4.32v5.85a17 17 0 01-17 17h-4.32V524a17 17 0 0117-17z"
              transform="rotate(-92.3 945.991 517.941)"
            />
          </motion.g>
        </g>

      </g>
      <motion.path
        // filter="url(#shadow-slide-5)"
        animate={{
          x: ['-1100px', '700px'],
        }}
        transition={{ duration: 205, ease: 'linear', repeat: Infinity }}
        className="biodiversity_slide_5__cls-20"
        d="M979.53 113.12a13.92 13.92 0 0112.69-13.88 13.94 13.94 0 0113.47-10.41h1.84A13.93 13.93 0 011021 99.18h4a14 14 0 0113.93 13.94v1.45a13.53 13.53 0 01-.69 4.32 2.18 2.18 0 01-2.07 1.49h-53.88a2.18 2.18 0 01-2.07-1.49 13.53 13.53 0 01-.69-4.32v-.73-.72z"
        id="biodiversity_slide_5__cloud"
      />
      <motion.path
        // filter="url(#shadow-slide-5)"
        animate={{
          x: ['0px', '700px'],
        }}
        transition={{ duration: 105, ease: 'linear' }}
        className="biodiversity_slide_5__cls-20"
        d="M979.53 113.12a13.92 13.92 0 0112.69-13.88 13.94 13.94 0 0113.47-10.41h1.84A13.93 13.93 0 011021 99.18h4a14 14 0 0113.93 13.94v1.45a13.53 13.53 0 01-.69 4.32 2.18 2.18 0 01-2.07 1.49h-53.88a2.18 2.18 0 01-2.07-1.49 13.53 13.53 0 01-.69-4.32v-.73-.72z"
        id="biodiversity_slide_5__cloud"
      />
      <motion.path
        // filter="url(#shadow-slide-5)"
        animate={{
          x: ['-1100px', '700px'],
        }}
        transition={{ duration: 155, ease: 'linear', repeat: Infinity }}
        className="biodiversity_slide_5__cls-20"
        d="M1074.89 86.16a9.89 9.89 0 019-9.84 9.88 9.88 0 019.55-7.37h1.3a9.86 9.86 0 019.53 7.34h2.83a9.88 9.88 0 019.87 9.87v1.03a9.75 9.75 0 01-.48 3.06 1.55 1.55 0 01-1.47 1.06h-38.16a1.55 1.55 0 01-1.47-1.06 9.76 9.76 0 01-.49-3.06v-.51c0-.17-.01-.35-.01-.52z"
        id="biodiversity_slide_5__cloud-2"
        data-name="cloud"
      />
      <motion.path
        // filter="url(#shadow-slide-5)"
        animate={{
          x: ['0px', '700px'],
        }}
        transition={{ duration: 55, ease: 'linear' }}
        className="biodiversity_slide_5__cls-20"
        d="M1074.89 86.16a9.89 9.89 0 019-9.84 9.88 9.88 0 019.55-7.37h1.3a9.86 9.86 0 019.53 7.34h2.83a9.88 9.88 0 019.87 9.87v1.03a9.75 9.75 0 01-.48 3.06 1.55 1.55 0 01-1.47 1.06h-38.16a1.55 1.55 0 01-1.47-1.06 9.76 9.76 0 01-.49-3.06v-.51c0-.17-.01-.35-.01-.52z"
        id="biodiversity_slide_5__cloud-2"
        data-name="cloud"
      />
      <motion.path
        // filter="url(#shadow-slide-5)"
        animate={{
          x: ['-1100px', '700px'],
        }}
        transition={{ duration: 255, ease: 'linear', repeat: Infinity }}
        className="biodiversity_slide_5__cls-20"
        d="M1000.47 470.14a13.94 13.94 0 0112.68-13.88 14 14 0 0113.48-10.4h1.83a13.94 13.94 0 0113.46 10.35h4a14 14 0 0113.93 13.93v1.45a13.63 13.63 0 01-.69 4.33 2.17 2.17 0 01-2.07 1.48h-53.86a2.18 2.18 0 01-2.07-1.48 13.91 13.91 0 01-.68-4.33v-.72c0-.24-.01-.49-.01-.73z"
        id="biodiversity_slide_5__cloud-3"
        data-name="cloud"
      />
      <motion.path
        // filter="url(#shadow-slide-5)"
        animate={{
          x: ['0px', '700px'],
        }}
        transition={{ duration: 155, ease: 'linear' }}
        className="biodiversity_slide_5__cls-20"
        d="M1000.47 470.14a13.94 13.94 0 0112.68-13.88 14 14 0 0113.48-10.4h1.83a13.94 13.94 0 0113.46 10.35h4a14 14 0 0113.93 13.93v1.45a13.63 13.63 0 01-.69 4.33 2.17 2.17 0 01-2.07 1.48h-53.86a2.18 2.18 0 01-2.07-1.48 13.91 13.91 0 01-.68-4.33v-.72c0-.24-.01-.49-.01-.73z"
        id="biodiversity_slide_5__cloud-3"
        data-name="cloud"
      />
      <motion.path
        // filter="url(#shadow-slide-5)"
        animate={{
          x: ['-1100px', '700px'],
        }}
        transition={{ duration: 285, ease: 'linear', repeat: Infinity }}
        className="biodiversity_slide_5__cls-20"
        d="M1071.41 505.64a8.28 8.28 0 017.55-8.27 8.3 8.3 0 018-6.19h1.09a8.3 8.3 0 018 6.16h2.38a8.31 8.31 0 018.3 8.3v.86a7.93 7.93 0 01-.42 2.58 1.29 1.29 0 01-1.23.89h-32.07a1.29 1.29 0 01-1.23-.89 7.92 7.92 0 01-.41-2.58v-.43c0-.15.04-.28.04-.43z"
        id="biodiversity_slide_5__cloud-4"
        data-name="cloud"
      />
      <motion.path
        // filter="url(#shadow-slide-5)"
        animate={{
          x: ['0px', '700px'],
        }}
        transition={{ duration: 185, ease: 'linear' }}
        className="biodiversity_slide_5__cls-20"
        d="M1071.41 505.64a8.28 8.28 0 017.55-8.27 8.3 8.3 0 018-6.19h1.09a8.3 8.3 0 018 6.16h2.38a8.31 8.31 0 018.3 8.3v.86a7.93 7.93 0 01-.42 2.58 1.29 1.29 0 01-1.23.89h-32.07a1.29 1.29 0 01-1.23-.89 7.92 7.92 0 01-.41-2.58v-.43c0-.15.04-.28.04-.43z"
        id="biodiversity_slide_5__cloud-4"
        data-name="cloud"
      />
      <motion.path
        // filter="url(#shadow-slide-5)"
        animate={{
          x: ['-1500px', '800px'],
        }}
        transition={{ duration: 185, ease: 'linear', repeat: Infinity }}
        className="biodiversity_slide_5__cls-20"
        d="M669 60.72a20.73 20.73 0 0118.88-20.65A20.74 20.74 0 01708 24.59h2.73a20.74 20.74 0 0120 15.4h5.94a20.75 20.75 0 0120.73 20.73v2.17a20.32 20.32 0 01-1 6.43 3.24 3.24 0 01-3.09 2.22h-80.18a3.25 3.25 0 01-3.08-2.22 20.32 20.32 0 01-1-6.43v-1.08c0-.36-.05-.72-.05-1.09z"
        id="biodiversity_slide_5__cloud-5"
        data-name="cloud"
      />
      <motion.path
        // filter="url(#shadow-slide-5)"
        animate={{
          x: ['0px', '800px'],
        }}
        transition={{ duration: 85, ease: 'linear' }}
        className="biodiversity_slide_5__cls-20"
        d="M669 60.72a20.73 20.73 0 0118.88-20.65A20.74 20.74 0 01708 24.59h2.73a20.74 20.74 0 0120 15.4h5.94a20.75 20.75 0 0120.73 20.73v2.17a20.32 20.32 0 01-1 6.43 3.24 3.24 0 01-3.09 2.22h-80.18a3.25 3.25 0 01-3.08-2.22 20.32 20.32 0 01-1-6.43v-1.08c0-.36-.05-.72-.05-1.09z"
        id="biodiversity_slide_5__cloud-5"
        data-name="cloud"
      />
      <motion.path
        // filter="url(#shadow-slide-5)"
        animate={{
          x: ['-500px', '1800px'],
        }}
        transition={{ duration: 235, ease: 'linear', repeat: Infinity }}
        className="biodiversity_slide_5__cls-20"
        d="M312 271.22a20.75 20.75 0 0118.89-20.66 20.74 20.74 0 0120.05-15.48h2.73a20.73 20.73 0 0120 15.4h5.95a20.76 20.76 0 0120.73 20.74v2.16a20.11 20.11 0 01-1 6.44 3.24 3.24 0 01-3.08 2.21h-80.16a3.24 3.24 0 01-3.08-2.21 20.39 20.39 0 01-1-6.44v-1.08q0-.54-.03-1.08z"
        id="biodiversity_slide_5__cloud-6"
        data-name="cloud"
      />
      <motion.path
        // filter="url(#shadow-slide-5)"
        animate={{
          x: ['0px', '1800px'],
        }}
        transition={{ duration: 135, ease: 'linear' }}
        className="biodiversity_slide_5__cls-20"
        d="M312 271.22a20.75 20.75 0 0118.89-20.66 20.74 20.74 0 0120.05-15.48h2.73a20.73 20.73 0 0120 15.4h5.95a20.76 20.76 0 0120.73 20.74v2.16a20.11 20.11 0 01-1 6.44 3.24 3.24 0 01-3.08 2.21h-80.16a3.24 3.24 0 01-3.08-2.21 20.39 20.39 0 01-1-6.44v-1.08q0-.54-.03-1.08z"
        id="biodiversity_slide_5__cloud-6"
        data-name="cloud"
      />
      <motion.path
        // filter="url(#shadow-slide-5)"
        animate={{
          x: ['-1300px', '1400px'],
        }}
        transition={{ duration: 205, ease: 'linear', repeat: Infinity }}
        className="biodiversity_slide_5__cls-20"
        d="M596.92 93.35a13.92 13.92 0 0112.69-13.88 13.94 13.94 0 0113.48-10.41h1.83a14 14 0 0113.46 10.35h4a14 14 0 0113.92 13.94v1.45a13.53 13.53 0 01-.69 4.32 2.18 2.18 0 01-2.07 1.49h-53.86a2.18 2.18 0 01-2.07-1.49 13.81 13.81 0 01-.69-4.32v-.73-.72z"
        id="biodiversity_slide_5__cloud-7"
        data-name="cloud"
      />
      <motion.path
        // filter="url(#shadow-slide-5)"
        animate={{
          x: ['0px', '1400px'],
        }}
        transition={{ duration: 105, ease: 'linear' }}
        className="biodiversity_slide_5__cls-20"
        d="M596.92 93.35a13.92 13.92 0 0112.69-13.88 13.94 13.94 0 0113.48-10.41h1.83a14 14 0 0113.46 10.35h4a14 14 0 0113.92 13.94v1.45a13.53 13.53 0 01-.69 4.32 2.18 2.18 0 01-2.07 1.49h-53.86a2.18 2.18 0 01-2.07-1.49 13.81 13.81 0 01-.69-4.32v-.73-.72z"
        id="biodiversity_slide_5__cloud-7"
        data-name="cloud"
      />
    </svg>
  );
}

export default AnimationSlide5;
